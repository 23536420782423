import { Course } from "../../../models/course";
import AdminPortalHeader from "../../AdminHeader/AdminPortalHeader";
import AdminArchiveItemBox from "../AdminArchiveItemBox";
import { Box, Dialog } from "@mui/material";

const AdminPortalArchiveItemDialog: React.FC<{
  course: Course;
  isFirstCourse: boolean;
  isLastCourse: boolean;
  isOpen: boolean;
  navigate: (left: boolean) => Promise<void>;
  closeDialog: () => void;
}> = ({
  isOpen,
  isFirstCourse,
  isLastCourse,
  course,
  navigate,
  closeDialog,
}) => {
  return (
    <>
      <Dialog fullScreen open={isOpen}>
        <AdminPortalHeader backBtnOnClick={closeDialog} />
        <AdminArchiveItemBox
          course={course}
          navigate={navigate}
          isFirstCourse={isFirstCourse}
          isLastCourse={isLastCourse}
        />
        <Box paddingTop={"50px"}></Box>
      </Dialog>
    </>
  );
};

export default AdminPortalArchiveItemDialog;
