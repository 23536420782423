import { Box, Button, Typography } from "@mui/material";
import { Course } from "../../models/course";
import "../../css/globalVariables.css";
import { CourseStates } from "../../models/course";
import { Trainer } from "../../models/trainer";
import arrow from "../../img/arrowWhite.png";

const AdminArchiveItemBoxHeader: React.FC<{
  course: Course;
  isFirstCourse: boolean;
  isLastCourse: boolean;
  navigate: (left: boolean) => Promise<void>;
}> = ({ course, isFirstCourse, isLastCourse, navigate }) => {
  const getCourseStartEndTimeJSX = () => {
    const getTimeFromDateString = (dateString: string) => {
      return (
        dateString.split(",")[1].trim().split(":")[0] +
        ":" +
        dateString.split(",")[1].trim().split(":")[1]
      );
    };
    const startTime = getTimeFromDateString(course.startDate);
    const endTime = getTimeFromDateString(course.endDate);

    return (
      <Typography
        sx={{
          fontSize: "var(--h3)",
          color: "var(--white)",
          whiteSpace: "nowrap",
        }}
        paddingBottom={"5px"}
      >{`${startTime} - ${endTime}`}</Typography>
    );
  };

  const getCourseTrainersJSX = () => {
    const getTrainerString = (trainer: Trainer) => {
      return `${trainer.firstName} ${trainer.lastName}`;
    };
    if (!course.trainers || !course.documentingTrainerUUID) {
      return;
    }
    const docuTrainerUUID = course.documentingTrainerUUID;
    const docuTrainer = course.trainers.find(
      (trainer) => trainer.uuid === docuTrainerUUID
    );

    const allOtherTrainers = course.trainers.filter(
      (trainer) => trainer.uuid !== docuTrainerUUID
    );
    if (allOtherTrainers.length === 0) {
      return (
        <Typography
          sx={{
            fontSize: "var(--h3)",
            color: "var(--orange)",
          }}
        >
          {getTrainerString(docuTrainer)}
        </Typography>
      );
    } else {
      const docuTrainerJSX = (
        <span
          style={{
            fontSize: "var(--h3)",
            color: "var(--orange)",
          }}
        >
          {getTrainerString(docuTrainer)}
        </span>
      );
      let returnString = ` | `;
      let i = 0;
      let endIndex = allOtherTrainers.length - 1;
      while (true) {
        returnString = returnString + getTrainerString(allOtherTrainers[i]);
        if (i === endIndex) {
          break;
        }
        returnString = returnString + " | ";
        i = i + 1;
      }
      return (
        <Typography sx={typographyStyle}>
          {docuTrainerJSX}
          {returnString}
        </Typography>
      );
    }
  };

  const getCheckInOutString = (dateString: string) => {
    const [datePart, timePart] = dateString.split(", ");
    const [time, seconds] = timePart.split(":");
    return `${datePart} - ${time}:${seconds}`;
  };

  const getCheckInJSX = () => {
    return (
      <Typography sx={typographyStyle} paddingBottom={"5px"}>
        {`CheckIn: ${getCheckInOutString(course.checkInDate)}`}
      </Typography>
    );
  };

  const getCheckOutJSX = () => {
    return (
      <Typography sx={typographyStyle}>
        {`CheckOut: ${getCheckInOutString(course.checkOutDate)}`}
      </Typography>
    );
  };

  const typographyStyle = {
    fontSize: "var(--h3)",
    color: "var(--white)",
    overflow: "hidden",
    whiteSpace: "nowrap",
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "var(--grey)",
          borderRadius: "var(--borderRadiusLarge) var(--borderRadiusLarge) 0 0",
          borderBottom: "var(--border)",
          paddingTop: " 10px",
          paddingBottom: "10px",
          justifyContent: "space-between",
        }}
      >
        <Button
          disabled={isFirstCourse}
          onClick={async () => {
            if (!isFirstCourse) {
              await navigate(true);
            }
          }}
          sx={{
            opacity: isFirstCourse ? "0.5" : "1",
            backgroundColor: "var(--orange)",
            height: "30px",
            maxWidth: "30px",
            minWidth: "30px",
            alignSelf: "center",
            margin: "auto 1%",
          }}
        >
          <img
            src={arrow}
            alt="arrow for navigation left"
            style={{
              height: "30px",
              marginRight: "1%",
              transform: "rotate(180deg)",
            }}
          ></img>
        </Button>
        {/* Left Part */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontSize: "var(--h1)",
              color:
                course.state === CourseStates.CANCELED
                  ? "var(--red)"
                  : "var(--orange)",
              fontWeight: "bold",
            }}
          >
            {course.courseName}
          </Typography>
          <Typography sx={typographyStyle}>{course.location}</Typography>
        </Box>
        {/* Center Part */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "left",
          }}
        >
          {getCourseStartEndTimeJSX()}
          {getCourseTrainersJSX()}
        </Box>
        {/* Right Part */}

        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            textAlign: "end",
          }}
        >
          {course.checkInDate && course.checkOutDate && (
            <>
              {getCheckInJSX()}
              {getCheckOutJSX()}
            </>
          )}
        </Box>
        <Button
          disabled={isLastCourse}
          onClick={async () => {
            if (!isLastCourse) {
              await navigate(false);
            }
          }}
          sx={{
            backgroundColor: "var(--orange)",
            opacity: isLastCourse ? "0.5" : "1",
            height: "30px",
            maxWidth: "30px",
            minWidth: "30px",
            alignSelf: "center",
            margin: "auto 1%",
          }}
        >
          <img
            src={arrow}
            alt="arrow for navigation right"
            style={{
              height: "30px",
              marginLeft: "1%",
            }}
          ></img>
        </Button>
      </Box>
    </>
  );
};

export default AdminArchiveItemBoxHeader;
