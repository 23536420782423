import { AdminManagementDialogTypes } from "../components/AdminManagement/Dialogs/AdminManagementDialog";

export interface KursBelegung {
  ID_Belegung: number;
  Kursname: string;
  Kurzbezeichnung: string;
  Displayname: string;
  Mitgliedschaftsbeginn: Date;
  Mitgliedschaftsende: Date;
  IsAud: boolean;
}

export interface Mitglied {
  ID: number;
  Nachname: string;
  Vorname: string;
  Geschlecht: string;
  Geburtsdatum: Date;
  Strasse: string;
  PLZ: string;
  Stadt: string;
  Telefonnummer: string;
  Emailadresse: string;
  Kontoinhaber: string;
  IBAN: string;
  Hinzugefuegt: Date;
  Mitgliedschaftsbeginn: Date;
  Mitgliedschaftsende: Date;
  Belegungen: KursBelegung[];
}

export interface Standort {
  ID: number;
  Standortname: string;
  Strasse: string;
  PLZ: string;
  Ort: string;
  Ansprechpartner: string;
  Kontakt: string;
  Kurse: Kurs[];
}

export interface Kurs {
  ID: number;
  Kursname: string;
  Displayname: string;
  Bereich: string;
  Kurzbezeichnung: string;
  Wochentag: string;
  Anfangsuhrzeit: string;
  Enduhrzeit: string;
  Alter: string;
  Eltern: boolean;
  Bemerkung: string;
  ID_Ort: number;
  ID_Trainer: number;
  max_Teilnehmer: number;
  Status: boolean;
  Mitgliedsbeitrag: number;
  Aufnahmegebuehr: number;
  Kontingentierbar: boolean;
}

export interface Warteliste {
  ID: number;
  Kursname: string;
  Kurzbezeichnung: string;
  MaxTeilnehmer: string;
  WartelistenKundenData: WartelistenKundenData[];
}

export interface WartelistenKundenData {
  ID_Kurs: number;
  ID: number;
  ID_KUNDE_NOT_VERIFIED: number;
  aktiv: boolean;
  created: Date;
  issued: Date;
  verifiedAt: Date;
  version: number;
  Nachname: string;
  Vorname: string;
  Geschlecht: string;
  Geburtsdatum: Date;
  Strasse: string;
  PLZ: string;
  Stadt: string;
  Telefonnummer: string;
  Emailadresse: string;
  Kontoinhaber: string;
  IBAN: string;
  Hinzugefuegt: Date;
}

export interface BelegbarerKurs {
  ID: number;
  KursName: string;
}

export interface BelegbareKurseResponse {
  volleKurse: BelegbarerKurs[];
  verfugbareKurse: BelegbarerKurs[];
}

export enum SearchTypes {
  STANDORT = "STANDORT",
  WARTELISTE = "WARTELISTE",
  MITGLIED = "MITGLIED",
}

export interface SearchObject {
  type: SearchTypes;
  query: string;
}

export interface IdAndDataType {
  ID: number;
  data: string;
}

export interface FieldConfig {
  attribute: string;
  data: string;
  type: AdminManagementDialogTypes;
  validatorFct?: ValidatorFunction;
  isRequired?: boolean;
}

export interface InputData {
  [categoryKey: string]: {
    [key: string]: FieldConfig;
  };
}

export type ValidatorFunction = (
  value: string,
  isRequired: boolean
) => boolean | Promise<boolean>;

export enum AdminManagementDialogKey {
  MITGLIED,
  STANDORT,
  KURS,
  WARTELISTE,
  BELEGUNG,
}
