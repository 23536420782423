import { AxiosInstance } from "axios";
import authAxios from "../auth/auth.interceptor.service";
import adminAxios from "../auth/admin.interceptor.service";
import trainerAxios from "../auth/trainer.interceptor.service";
import syncAxios from "../auth/sync.interceptor.service";

export function getAuthAxiosInstance(): AxiosInstance {
  return authAxios;
}

export function getAdminAxiosInstance(): AxiosInstance {
  return adminAxios;
}

export function getTrainerAxiosInstance(): AxiosInstance {
  return trainerAxios;
}

export function getSyncAxiosInstance(): AxiosInstance {
  return syncAxios;
}
