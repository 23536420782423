import { Box, InputAdornment, TextField } from "@mui/material";
import React, { ReactNode } from "react";
import searchIcon from "../../img/searchIcon.svg";
import CancelIcon from "@mui/icons-material/Cancel";

export const managementSearchPanelBtnStyle = {
  width: "50%",
  maxWidth: "300px",
  minWidth: "135px",
  backgroundColor: "var(--green)",
  height: "30px",
  margin: "15px 15px 15px 0",
  color: "white",
  fontWeight: "bold",
  fontSize: "var(--h4)",
  padding: "0",
  "&:hover": {
    backgroundColor: "var(--green)",
    opacity: 0.75,
    transition: "opacity 0.3s",
  },
};

export const AdminManagmentSearchPanel: React.FC<{
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
  onExecuteSearch: (searchQuery: string) => Promise<void>;
  onResetSearch: () => Promise<void>;
  children?: ReactNode;
}> = ({
  searchQuery,
  setSearchQuery,
  onExecuteSearch,
  onResetSearch,
  children,
}) => {
  const onChangeQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const onKeyDown = async (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      await onExecuteSearch(searchQuery);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "85%",
        margin: "auto",
        justifyContent: "space-between",
        marginTop: "10px",
      }}
    >
      <TextField
        variant="outlined"
        placeholder="SUCHE"
        sx={{
          minWidth: "35%",
          margin: "15px",
          ".MuiOutlinedInput-root": {
            height: "30px",
          },
          ".MuiOutlinedInput-input": {
            padding: "0 14px",
            height: "30px",
          },
        }}
        size="small"
        value={searchQuery}
        onChange={onChangeQuery}
        onKeyDown={onKeyDown}
        InputProps={{
          startAdornment: (
            <InputAdornment onClick={() => {}} position="start">
              <button style={{ border: "0px", background: "none" }}>
                <img
                  src={searchIcon}
                  alt="searchIcon"
                  style={{ width: "15px", height: "15px" }}
                ></img>
              </button>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              onClick={async () => {
                await onResetSearch();
              }}
              position="end"
            >
              <button
                style={{ border: "0px", background: "none", cursor: "pointer" }}
              >
                <CancelIcon htmlColor="#607783"></CancelIcon>
              </button>
            </InputAdornment>
          ),
        }}
      />
      <Box
        sx={{
          display: "flex",
          whiteSpace: "nowrap",
          width: "100%",
          justifyContent: "end",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
