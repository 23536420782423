import React from "react";
import {
  AdminManagementDialogKey,
  InputData,
  WartelistenKundenData,
} from "../../../models/admin-management-entities";
import {
  AdminManagementDialog,
  AdminManagementDialogTypes,
} from "./AdminManagementDialog";
import { dateToString } from "../../../util/DateFormatHelper";

export const AdminManagementWartelisteKundeDialog: React.FC<{
  wartelisteKunde: WartelistenKundenData;
  isOpen: boolean;
  closeDialog: () => void;
}> = ({ wartelisteKunde, isOpen, closeDialog }) => {
  if (!wartelisteKunde) {
    return null;
  }
  const wartelisteKundeInputData: InputData = {
    Personendaten: {
      Nachname: {
        attribute: "Nachname",
        data: wartelisteKunde.Nachname,
        type: AdminManagementDialogTypes.DISABLED,
      },
      Vorname: {
        attribute: "Vorname",
        data: wartelisteKunde.Vorname,
        type: AdminManagementDialogTypes.DISABLED,
      },
      Geschlecht: {
        attribute: "Geschlecht",
        data: wartelisteKunde.Geschlecht,
        type: AdminManagementDialogTypes.DISABLED,
      },
      Geburtsdatum: {
        attribute: "Geburtsdatum",
        data: dateToString(wartelisteKunde.Geburtsdatum),
        type: AdminManagementDialogTypes.DISABLED,
      },
    },
    Kontaktdaten: {
      Strasse: {
        attribute: "Strasse",
        data: wartelisteKunde.Strasse,
        type: AdminManagementDialogTypes.DISABLED,
      },
      PLZ: {
        attribute: "PLZ",
        data: wartelisteKunde.PLZ,
        type: AdminManagementDialogTypes.DISABLED,
      },
      Stadt: {
        attribute: "Stadt",
        data: wartelisteKunde.Stadt,
        type: AdminManagementDialogTypes.DISABLED,
      },
      Telefonnummer: {
        attribute: "Telefonnummer",
        data: wartelisteKunde.Telefonnummer,
        type: AdminManagementDialogTypes.DISABLED,
      },
      "E-Mail": {
        attribute: "Emailadresse",
        data: wartelisteKunde.Emailadresse,
        type: AdminManagementDialogTypes.DISABLED,
      },
    },
    Kontodaten: {
      Kontoinhaber: {
        attribute: "Kontoinhaber",
        data: wartelisteKunde.Kontoinhaber,
        type: AdminManagementDialogTypes.DISABLED,
      },
      IBAN: {
        attribute: "IBAN",
        data: wartelisteKunde.IBAN,
        type: AdminManagementDialogTypes.DISABLED,
      },
    },
    Warteliste: {
      "Datum Eintrag": {
        attribute: "created",
        data: dateToString(wartelisteKunde.created),
        type: AdminManagementDialogTypes.DISABLED,
      },
      "Datum Angebot": {
        attribute: "issued",
        data: dateToString(wartelisteKunde.issued),
        type: AdminManagementDialogTypes.DISABLED,
      },
      Version: {
        attribute: "version",
        data: String(wartelisteKunde.version),
        type: AdminManagementDialogTypes.DISABLED,
      },
      "Verifiziert am": {
        attribute: "verifiedAt",
        data: dateToString(wartelisteKunde.verifiedAt),
        type: AdminManagementDialogTypes.DISABLED,
      },
    },
  };

  return (
    <AdminManagementDialog
      dialogKey={AdminManagementDialogKey.WARTELISTE}
      title={`${wartelisteKunde.Nachname} ${wartelisteKunde.Vorname}`}
      isOpen={isOpen}
      closeDialog={closeDialog}
      inputData={wartelisteKundeInputData}
      entity={wartelisteKunde}
    />
  );
};
