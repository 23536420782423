import { HttpStatusCode } from "axios";
import { SnackBarContextObj } from "../context/snackbar-context";
import {
  IdAndDataType,
  Mitglied,
  SearchTypes,
  Standort,
  Warteliste,
  BelegbareKurseResponse,
} from "../models/admin-management-entities";
import { getAdminAxiosInstance } from "./api-config";
import {
  AddBelegungDTO,
  AddKundenstammDTO,
  AddStandortDTO,
  AddStandortKursDTO,
  DeleteBelegungDTO,
  UpdateBelegungDTO,
  UpdateKundenstammDTO,
  UpdateKursstammDTO,
  UpdateStandortDTO,
} from "./dtos/admin-management-dtos";
import { formatSnackBarErrMsg } from "../util/SnackBarErrorMsgFormatter";

function formatStandortData(standortData: any) {
  return standortData.map((standort: any) => {
    const { ID_Standort, Kurse, ...otherStandortData } = standort;
    return {
      ID: ID_Standort,
      ...otherStandortData,
      Kurse: Kurse.map((kurs: any) => {
        const { ID_Kurs, ...otherKursData } = kurs;
        return { ID: ID_Kurs, ...otherKursData };
      }),
    };
  });
}

function formatWartelistenData(wartelistenData: any) {
  return wartelistenData.map((warteliste: any) => {
    warteliste.WartelistenKundenData = warteliste.WartelistenKundenData.map(
      (kunde: any) => {
        const { ID_Kunde, ...data } = kunde;
        return { ID: ID_Kunde, ...data };
      }
    );
    const { ID_Kurs, ...data } = warteliste;
    return { ID: ID_Kurs, ...data };
  });
}

export async function checkIban(iban: string): Promise<boolean> {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.get("/verein/check-iban", {
    params: { iban },
  });
  return response.data;
}

export async function getStandorteWithKurse(
  offset: number,
  searchQuery?: string | undefined
): Promise<Standort[]> {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.get("/verein/standort-kurse", {
    params: { offset, searchQuery },
  });
  const standortData = response.data;
  return formatStandortData(standortData);
}

export async function addStandort(addStandortDTO: AddStandortDTO) {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.post("/verein/standort", addStandortDTO);
  const standortData = response.data;
  const { ID_Standort, ...otherStandortData } = standortData;
  return {
    status: response.status,
    ID: ID_Standort,
    ...otherStandortData,
  };
}

export async function updateStandort(standort: UpdateStandortDTO) {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.put("/verein/standort", standort);
  const standortData = response.data;
  return { status: response.status, standortData };
}

export async function deleteStandort(
  idStandort: number,
  snackBarCtx: SnackBarContextObj
) {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.delete(`/verein/standort/${idStandort}`);
  if (response.data.status !== HttpStatusCode.Ok) {
    snackBarCtx.openSnackbar(
      formatSnackBarErrMsg(response.data.status, response.data.message)
    );
  }
  return { status: response.data.status };
}

export async function addStandortKurs(addStandortKursDTO: AddStandortKursDTO) {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.post(
    "/verein/kursstamm",
    addStandortKursDTO
  );
  const standortKursData = response.data;
  return { status: response.status, standortKursData };
}

export async function updateKurs(updateKursstammDTO: UpdateKursstammDTO) {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.put(
    "/verein/kursstamm",
    updateKursstammDTO
  );
  const standortKursData = response.data;
  return { status: response.status, standortKursData };
}

export async function deleteKurs(idKurs: number) {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.delete(
    `/verein/kursstamm?idKurs=${idKurs}`
  );
  return { status: response.status };
}

export async function getMitgliedByID(idKunde: number) {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.get("/verein/kundenstamm-single", {
    params: { idKunde },
  });
  const kundenData: Mitglied = response.data;
  return kundenData;
}

export async function getMitglieder(
  offset: number,
  searchQuery?: string | undefined
): Promise<Mitglied[]> {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.get("/verein/kundenstamm", {
    params: { offset, searchQuery },
  });
  const kundenData: Mitglied[] = response.data;
  return kundenData;
}

export async function addKunde(addKundenstammDTO: AddKundenstammDTO) {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.post(
    "/verein/kundenstamm",
    addKundenstammDTO
  );
  const kundeData = response.data;
  const { ID_Kunde, ...otherKundeData } = kundeData;
  return {
    status: response.status,
    ID: ID_Kunde,
    ...otherKundeData,
  };
}

export async function updateKunde(mitglied: UpdateKundenstammDTO) {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.put("/verein/kundenstamm", mitglied);
  const mitgliedData = response.data;
  return { status: response.status, mitgliedData };
}

export async function deleteKunde(idKunde: number) {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.delete(
    `/verein/kundenstamm?idKunde=${idKunde}`
  );
  return { status: response.status };
}

export async function getWarteliste(
  offset: number,
  searchQuery?: string | undefined
): Promise<Warteliste[]> {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.get("/verein/warteliste", {
    params: { offset, searchQuery },
  });
  const wartelistenData = response.data;
  return formatWartelistenData(wartelistenData);
}

export async function getAllKursstamNamen() {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.get("/verein/kursstamm");
  const kursstammData = response.data;
  return kursstammData.map((data: any) => {
    return { ID: data.ID_Kurs, data: data.Kursname };
  });
}

export async function getAllStandortNamen(): Promise<IdAndDataType[]> {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.get("/verein/all-standorte");
  const standortData = response.data;
  return standortData.map((data: any) => {
    return { ID: data.ID_Standort, data: data.Standortname };
  });
}

export async function getStandortByID(idStandort: number) {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.get(
    `/verein/standort?idStandort=${idStandort}`
  );
  const standortData = response.data;

  const { ID_Standort, Kurse, ...otherStandortData } = standortData;
  return {
    ID: ID_Standort,
    ...otherStandortData,
    Kurse: Kurse.map((kurs: any) => {
      const { ID_Kurs, ...otherKursData } = kurs;
      return { ID: ID_Kurs, ...otherKursData };
    }),
  };
}

export async function deleteBelegung(belegung: DeleteBelegungDTO) {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.delete(`/verein/belegung`, {
    data: belegung,
  });
  return { status: response.status };
}

export async function addBelegung(addBelegungBody: AddBelegungDTO) {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.post(
    "/verein/belegung",
    addBelegungBody
  );
  const belegungData = response.data;
  return { status: response.status, belegungData };
}

export async function updateBelegung(updateBelegung: UpdateBelegungDTO) {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.put("/verein/belegung", updateBelegung);
  const belegungData = response.data;
  return { status: response.status, belegungData };
}

export async function getBelegbareKurse(): Promise<BelegbareKurseResponse> {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.get("/verein/belegbare-kurse");
  const belegbareKurse = response.data;
  return belegbareKurse;
}

export async function executeSearch(
  searchType: SearchTypes,
  searchQuery: string,
  offset: number
) {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.get(`/verein/search`, {
    params: {
      type: searchType,
      query: searchQuery,
      offset,
    },
  });

  if (searchType === SearchTypes.STANDORT) {
    const standortData = response.data;
    return formatStandortData(standortData);
  }
  if (searchType === SearchTypes.WARTELISTE) {
    const wartelistenData = response.data;
    return formatWartelistenData(wartelistenData);
  }
  return response.data;
}

export async function getBereichAndKurzbezeichnungValues() {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.get(`/verein/bereich-kurz-bezeichnung`);
  return {
    bereichValues: response.data.bereichValues,
    kurzBezeichnungValues: response.data.kurzBezeichnungValues,
  };
}
