export const getDate = (inputDateString: string) => {
  if (!inputDateString) {
    return;
  }
  return inputDateString.split(",")[0].trim();
};

export const getTimeFromDates = (startDate: string, endDate: string) => {
  if (!startDate || !endDate) {
    return;
  }
  const startTime = localStringToDate(startDate);
  const endTime = localStringToDate(endDate);

  const startFormatted = startTime.toLocaleTimeString("de-DE", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const endFormatted = endTime.toLocaleTimeString("de-DE", {
    hour: "2-digit",
    minute: "2-digit",
  });

  return `${startFormatted} - ${endFormatted}`;
};

export function localStringToDate(localDateString: string) {
  if (!localDateString) {
    return;
  }
  const [day, month, yearTime] = localDateString.split(".");
  const [year, time] = yearTime.split(",");

  // Split the time into its components
  const [hour, minute, second] = time.split(":");

  // Create a new Date object using components
  // Months in JavaScript's Date are 0-indexed, so subtract 1 from the month
  return new Date(
    Number(year),
    Number(month) - 1,
    Number(day),
    Number(hour),
    Number(minute),
    Number(second)
  );
}

export function dateStringToDate(dateString: string) {
  if (!dateString) {
    return;
  }
  const [day, month, year] = dateString.split(".");
  return new Date(Number(year), Number(month) - 1, Number(day), Number(12));
}

export function dateToString(inputDate: Date) {
  if (!inputDate) {
    return "";
  }
  const date = new Date(inputDate);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
}

// ISO 8601 (YYYY-MM-DDTHH:mm:ss.sssZ)
export function convertDateString(dateString: string) {
  const parts = dateString.split(".");
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1;
  const year = parseInt(parts[2], 10);
  const date = new Date(Date.UTC(year, month, day));
  return date.toISOString();
}

//09.09.2000
export function formatDateWithLeadingZeros(date: Date) {
  return `${date.getDate().toString().padStart(2, "0")}.${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}.${date.getFullYear()}`;
}
