import { useContext, useEffect, useState } from "react";
import {
  getWarteliste,
  getMitglieder,
  getStandorteWithKurse,
  getBelegbareKurse,
  getAllStandortNamen,
  getMitgliedByID,
  deleteKunde,
  deleteStandort,
} from "../../api/admin-management-api";
import {
  AdminManagementTabTypes,
  MITGLIEDER_TAB,
  STANDORTE_TAB,
  WARTELISTE_TAB,
} from "../../constants/admin-management-constants";
import { dateToString } from "../../util/DateFormatHelper";
import { localStorageKeys } from "../../constants/storage-keys";
import {
  IdAndDataType,
  Kurs,
  Mitglied,
  SearchObject,
  SearchTypes,
  Standort,
  Warteliste,
  BelegbareKurseResponse,
  AdminManagementDialogKey,
} from "../../models/admin-management-entities";
import AdminPortalHeader from "../../components/AdminHeader/AdminPortalHeader";
import AdminManagementSwitcher from "../../components/AdminManagement/AdminManagementSwitcher";
import LoadingScreen from "../../components/LoadingScreen";
import { AdminManagmentList } from "../../components/AdminManagement/AdminManagementList";
import { Box, Button, ThemeProvider } from "@mui/material";
import { AdminManagementMitgliedDialog } from "../../components/AdminManagement/Dialogs/AdminManagementMitgliedDialog";
import { AdminManagementStandortDialog } from "../../components/AdminManagement/Dialogs/AdminManagementStandortDialog";
import {
  AdminManagmentSearchPanel,
  managementSearchPanelBtnStyle,
} from "../../components/AdminManagement/AdminManagmentSearchPanel";
import { AdminManagmentWartelisteDialog } from "../../components/AdminManagement/Dialogs/AdminManagementWartelisteDialog";
import { setLocalStorageManagementActiveTab } from "../../util/StorageUtil";
import { AdminManagementKursDialog } from "../../components/AdminManagement/Dialogs/AdminManagementKursDialog";
import { customTheme } from "../../components/customTheme";
import SnackbarMessage from "../../components/Snackbar/SnackbarMessage";
import { SnackBarContext } from "../../context/snackbar-context";
import { HttpStatusCode } from "axios";

const AdminManagementPage: React.FC = () => {
  const checkLocalStorageActiveManagementTab = () => {
    const managementActiveTab = localStorage.getItem(
      localStorageKeys.adminManagementActiveTab
    );
    if (!managementActiveTab) {
      return MITGLIEDER_TAB;
    }
    const activeTabNum = Number(managementActiveTab);
    switch (activeTabNum) {
      case 0:
        return STANDORTE_TAB;
      case 1:
        return MITGLIEDER_TAB;
      case 2:
        return WARTELISTE_TAB;
      default:
        return MITGLIEDER_TAB;
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const [activeTab, setActiveTab] = useState<AdminManagementTabTypes>(
    checkLocalStorageActiveManagementTab()
  );
  const [searchObject, setSearchObject] = useState<SearchObject>(undefined);

  const [standorteOffset, setStandorteOffset] = useState(0);
  const [standortData, setStandortData] = useState<Standort[]>([]);
  const [allStandortNamen, setAllStandortNamen] = useState<IdAndDataType[]>();

  const [mitgliederOffset, setMitgliederOffset] = useState(0);
  const [mitgliederData, setMitgliederData] = useState<Mitglied[]>([]);

  const [wartelisteOffset, setWartelisteOffset] = useState(0);
  const [wartelisteData, setWartelisteData] = useState<Warteliste[]>([]);

  const [mitgliedDialogEntity, setMitgliedDialogEntity] =
    useState<Mitglied>(undefined);
  const [isMitgliedDialogOpen, setIsMitgliedDialogOpen] =
    useState<boolean>(false);
  const [standortDialogEntity, setStandortDialogEntity] =
    useState<Standort>(undefined);
  const [isStandortDialogOpen, setIsStandortDialogOpen] =
    useState<boolean>(false);
  const [kursDialogEntity, setKursDialogEntity] = useState<Kurs>(undefined);
  const [wartelisteDialogEntity, setWartelisteDialogEntity] =
    useState<Warteliste>(undefined);

  const [isAddingNewItem, setIsAddingNewItem] = useState<boolean>(false);

  const [belegbareKurse, setBelegbareKurse] =
    useState<BelegbareKurseResponse>();

  const snackBarCtx = useContext(SnackBarContext);

  const startLoading = () => {
    setIsLoading(true);
    setShowContent(false);
  };

  const stopLoading = () => {
    setIsLoading(false);
    setShowContent(true);
  };

  /* eslint-disable */

  useEffect(() => {
    startLoading();
    Promise.all([
      loadMitgliederData(mitgliederOffset),
      loadStandortData(standorteOffset),
      loadWartelisteData(wartelisteOffset),
      loadVerfugbareKurse(),
      loadAllStandortNamen(),
    ]).then(() => {
      stopLoading();
    });
  }, []);

  /* eslint-enable */

  const loadVerfugbareKurse = async () => {
    await Promise.all([
      getBelegbareKurse().then((returnValue: BelegbareKurseResponse) => {
        setBelegbareKurse(returnValue);
      }),
    ]);
  };

  const loadAllStandortNamen = async () => {
    await Promise.all([
      getAllStandortNamen().then((data: IdAndDataType[]) => {
        setAllStandortNamen(data);
      }),
    ]);
  };

  const loadMitgliederData = async (
    offset: number,
    searchQuery: string = ""
  ) => {
    setMitgliederOffset(offset);
    await Promise.all([
      getMitglieder(mitgliederOffset, searchQuery).then((mData: Mitglied[]) => {
        setMitgliederData(mData);
      }),
    ]);
  };

  const loadMoreMitglieder = async () => {
    setIsLoading(true);
    const newOffset = mitgliederOffset + 50;
    const { query } = searchObject || { query: "" };
    const mitgliedData: Mitglied[] = await getMitglieder(newOffset, query);
    setMitgliederData((prevMitglieder: Mitglied[]) => {
      const existingIDs = new Set(
        prevMitglieder.map((mitglied) => mitglied.ID)
      );
      const newUniqueMitglieder = mitgliedData.filter(
        (mitglied) => !existingIDs.has(mitglied.ID)
      );
      return [...prevMitglieder, ...newUniqueMitglieder];
    });
    setMitgliederOffset(newOffset);
    setIsLoading(false);
  };

  const loadStandortData = async (offset: number, searchQuery: string = "") => {
    setStandorteOffset(offset);
    await Promise.all([
      getStandorteWithKurse(offset, searchQuery).then((data: Standort[]) => {
        setStandortData(data);
        loadAllStandortNamen();
      }),
    ]);
  };

  const loadMoreStandorte = async () => {
    setIsLoading(true);
    const { query } = searchObject || { query: "" };
    const newOffset = standorteOffset + 50;
    const newStandorte = await getStandorteWithKurse(newOffset, query);
    setStandortData((prevMitglieder: any) => {
      return [...prevMitglieder, ...newStandorte];
    });
    setStandorteOffset(newOffset);
    setIsLoading(false);
  };

  const loadWartelisteData = async (
    offset: number,
    searchQuery: string = ""
  ) => {
    setWartelisteOffset(offset);
    await Promise.all([
      getWarteliste(offset, searchQuery).then((data: Warteliste[]) => {
        setWartelisteData(data);
      }),
    ]);
  };

  const executeSearchHandler = async (
    searchType: SearchTypes,
    searchQuery: string
  ) => {
    const resetOffset = 0;
    setSearchObject({
      type: searchType,
      query: searchQuery,
    });
    startLoading();
    if (searchType === SearchTypes.MITGLIED) {
      await loadMitgliederData(resetOffset, searchQuery);
      stopLoading();
    }
    if (searchType === SearchTypes.STANDORT) {
      await loadStandortData(resetOffset, searchQuery);
      stopLoading();
    }
    if (searchType === SearchTypes.WARTELISTE) {
      await loadWartelisteData(resetOffset, searchQuery);
      stopLoading();
    }
  };

  function getSearchAndFilterPanel() {
    if (activeTab === MITGLIEDER_TAB) {
      return (
        <AdminManagmentSearchPanel
          searchQuery={searchObject ? searchObject.query : ""}
          setSearchQuery={(searchQuery: string) => {
            setSearchObject({
              type: SearchTypes.MITGLIED,
              query: searchQuery,
            });
          }}
          onExecuteSearch={async (searchQuery: string) => {
            await executeSearchHandler(SearchTypes.MITGLIED, searchQuery);
          }}
          onResetSearch={async () => {
            setSearchObject(undefined);
            startLoading();
            await loadMitgliederData(0);
            stopLoading();
          }}
          children={
            <>
              <Button
                onClick={() => {
                  setIsAddingNewItem(true);
                  setMitgliedDialogEntity({} as Mitglied);
                  setIsMitgliedDialogOpen(true);
                }}
                sx={managementSearchPanelBtnStyle}
              >
                Mitglied hinzufügen
              </Button>
            </>
          }
        />
      );
    } else if (activeTab === STANDORTE_TAB) {
      return (
        <AdminManagmentSearchPanel
          searchQuery={searchObject ? searchObject.query : ""}
          setSearchQuery={(searchQuery: string) => {
            setSearchObject({
              type: SearchTypes.STANDORT,
              query: searchQuery,
            });
          }}
          onExecuteSearch={async (searchQuery: string) => {
            await executeSearchHandler(SearchTypes.STANDORT, searchQuery);
          }}
          onResetSearch={async () => {
            setSearchObject(undefined);
            startLoading();
            await loadStandortData(0);
            stopLoading();
          }}
          children={
            <>
              <Button
                onClick={() => {
                  setIsAddingNewItem(true);
                  setStandortDialogEntity({} as Standort);
                  setIsStandortDialogOpen(true);
                }}
                sx={managementSearchPanelBtnStyle}
              >
                Standort hinzufügen
              </Button>
              <Button
                onClick={() => {
                  setIsAddingNewItem(true);
                  setKursDialogEntity({} as Kurs);
                }}
                sx={managementSearchPanelBtnStyle}
              >
                Kurs hinzufügen
              </Button>
            </>
          }
        />
      );
    } else {
      return (
        <AdminManagmentSearchPanel
          searchQuery={searchObject ? searchObject.query : ""}
          setSearchQuery={(searchQuery: string) => {
            setSearchObject({
              type: SearchTypes.WARTELISTE,
              query: searchQuery,
            });
          }}
          onExecuteSearch={async (searchQuery: string) => {
            await executeSearchHandler(SearchTypes.WARTELISTE, searchQuery);
          }}
          onResetSearch={async () => {
            setSearchObject(undefined);
            startLoading();
            await loadWartelisteData(0);
            stopLoading();
          }}
        />
      );
    }
  }

  return (
    <ThemeProvider theme={customTheme}>
      <AdminPortalHeader
        headline="Verwaltung"
        setAdminManagementActiveTab={(activeTab: AdminManagementTabTypes) => {
          setActiveTab(activeTab);
        }}
      ></AdminPortalHeader>
      <AdminManagementSwitcher
        activeTab={activeTab}
        onChangeTab={(newTab: AdminManagementTabTypes) => {
          setLocalStorageManagementActiveTab(newTab);
          setActiveTab(newTab);
          setSearchObject(undefined);
        }}
      ></AdminManagementSwitcher>
      <LoadingScreen isLoading={isLoading}></LoadingScreen>
      {getSearchAndFilterPanel()}
      {showContent && (
        <>
          {activeTab === STANDORTE_TAB && (
            <Box sx={{ width: "90%", margin: "auto" }}>
              <AdminManagmentList
                dialogKey={AdminManagementDialogKey.STANDORT}
                setDialogEntityData={setStandortDialogEntity}
                isLoading={isLoading}
                data={standortData}
                loadMoreDataFct={async () => {
                  await loadMoreStandorte();
                }}
                columns={[
                  { title: "Standortname", flex: 3 },
                  { title: "Straße", flex: 2 },
                  { title: "PLZ", flex: 2 },
                  { title: "Ort", flex: 2 },
                  { title: "Ansprechpartner", flex: 2 },
                  { title: "Kontakt", flex: 2 },
                  { title: "", flex: 1 },
                ]}
                listItemOnClick={(ID: number) => {
                  setStandortDialogEntity(
                    standortData.find((standort) => standort.ID === ID)
                  );
                }}
                listItemColumns={[
                  { attrName: "Standortname", flex: 3 },
                  { attrName: "Strasse", flex: 2 },
                  { attrName: "PLZ", flex: 2 },
                  { attrName: "Ort", flex: 2 },
                  { attrName: "Ansprechpartner", flex: 2 },
                  { attrName: "Kontakt", flex: 2 },
                ]}
                noDataText={"Keine Standorte gefunden."}
                deleteDialogTextContent={
                  <>
                    Möchtest du den Standort{" "}
                    <b>
                      {standortDialogEntity &&
                        standortDialogEntity.Standortname}
                    </b>
                    <br /> wirklich unwiderruflich löschen ?
                  </>
                }
                currentItem={standortDialogEntity}
                deleteFnct={async (idStandort: number) => {
                  await deleteStandort(idStandort, snackBarCtx)
                    .then((response) => {
                      if (response.status === HttpStatusCode.Ok) {
                        loadStandortData(0);
                        snackBarCtx.openSnackbar(
                          "Standort wurde erfolgreich gelöscht!",
                          "success"
                        );
                      }
                    })
                    .catch((response) => {
                      snackBarCtx.openSnackbar(
                        "Standort kann nicht gelöscht werden: " +
                          response.message,
                        "error"
                      );
                    });
                }}
                setIsEntityDialogOpen={setIsStandortDialogOpen}
              />
            </Box>
          )}
          {activeTab === MITGLIEDER_TAB && (
            <Box sx={{ width: "90%", margin: "auto" }}>
              <AdminManagmentList
                dialogKey={AdminManagementDialogKey.MITGLIED}
                setDialogEntityData={setMitgliedDialogEntity}
                isLoading={isLoading}
                data={mitgliederData}
                loadMoreDataFct={async () => {
                  await loadMoreMitglieder();
                }}
                columns={[
                  { title: "ID", flex: 2 },
                  { title: "Name", flex: 2 },
                  { title: "Vorname", flex: 2 },
                  { title: "Geburtsdatum", flex: 2 },
                  { title: "E-Mail", flex: 3 },
                  { title: "Hinzugefügt", flex: 2 },
                  { title: "Mitgliedschaftsbeginn", flex: 2 },
                  { title: "", flex: 1 },
                ]}
                listItemOnClick={(ID: number) => {
                  setMitgliedDialogEntity(
                    mitgliederData.find((member) => member.ID === ID)
                  );
                }}
                listItemColumns={[
                  { attrName: "ID", flex: 2 },
                  { attrName: "Nachname", flex: 2 },
                  { attrName: "Vorname", flex: 2 },
                  {
                    attrName: "Geburtsdatum",
                    processFnct: (_: Date) => {
                      return dateToString(_);
                    },
                    flex: 2,
                  },
                  { attrName: "Emailadresse", flex: 3 },
                  {
                    attrName: "Hinzugefuegt",
                    processFnct: (_: Date) => {
                      return dateToString(_);
                    },
                    flex: 2,
                  },
                  {
                    attrName: "Mitgliedschaftsbeginn",
                    processFnct: (_: Date) => {
                      return dateToString(_);
                    },
                    flex: 2,
                  },
                ]}
                noDataText={"Keine Mitglieder gefunden."}
                deleteDialogTextContent={
                  <>
                    {"Bist du sicher, dass du das Mitglied "}
                    <br />
                    <b>
                      {`${mitgliedDialogEntity && mitgliedDialogEntity.Vorname} 
                      ${mitgliedDialogEntity && mitgliedDialogEntity.Nachname}`}
                    </b>
                    <br />
                    {"endgültig löschen möchtest?"}
                  </>
                }
                currentItem={mitgliedDialogEntity}
                deleteFnct={async () => {
                  await deleteKunde(mitgliedDialogEntity.ID)
                    .then((response) => {
                      if (response.status === HttpStatusCode.Ok) {
                        loadMitgliederData(0);
                        snackBarCtx.openSnackbar(
                          "Mitglied wurde erfolgreich gelöscht!",
                          "success"
                        );
                      }
                    })
                    .catch((response) => {
                      snackBarCtx.openSnackbar(
                        "Mitglied kann nicht gelöscht werden: " +
                          response.message,
                        "error"
                      );
                    });
                }}
                setIsEntityDialogOpen={setIsMitgliedDialogOpen}
              />
            </Box>
          )}
          {activeTab === WARTELISTE_TAB && (
            <Box sx={{ width: "90%", margin: "auto" }}>
              <AdminManagmentList
                dialogKey={AdminManagementDialogKey.WARTELISTE}
                isLoading={isLoading}
                data={wartelisteData}
                loadMoreDataFct={async () => {}}
                columns={[
                  { title: "Kursname" },
                  { title: "Kursbezeichnung" },
                  { title: "Kapazität" },
                  { title: "Wartelisteneinträge" },
                ]}
                listItemOnClick={(ID: number) => {
                  setWartelisteDialogEntity(
                    wartelisteData.find((warteliste) => warteliste.ID === ID)
                  );
                }}
                listItemColumns={[
                  { attrName: "Kursname" },
                  {
                    attrName: "Kurzbezeichnung",
                  },
                  { attrName: "MaxTeilnehmer" },
                  {
                    attrName: "WartelistenKundenData",
                    processFnct: (_: any) => {
                      return _.length;
                    },
                  },
                ]}
                noDataText={"Keine Wartelisten gefunden."}
              />
            </Box>
          )}
        </>
      )}
      <AdminManagementMitgliedDialog
        mitglied={mitgliedDialogEntity}
        setMitglied={setMitgliedDialogEntity}
        belegbareKurse={belegbareKurse}
        isOpen={isMitgliedDialogOpen}
        closeDialog={() => {
          setMitgliedDialogEntity(undefined);
          setIsMitgliedDialogOpen(false);
          setIsAddingNewItem(false);
        }}
        isAddingNewItem={isAddingNewItem}
        setIsAddingNewItem={setIsAddingNewItem}
        reloadData={async () => {
          await loadMitgliederData(0);
        }}
        reloadMitglied={async (idKunde: number) => {
          setMitgliedDialogEntity(undefined);
          startLoading();
          const updatedMitglied = await getMitgliedByID(idKunde);
          setMitgliedDialogEntity(updatedMitglied);
          stopLoading();
        }}
      ></AdminManagementMitgliedDialog>
      <AdminManagementStandortDialog
        allStandortNamen={allStandortNamen}
        standort={standortDialogEntity}
        setStandort={setStandortDialogEntity}
        isOpen={isStandortDialogOpen}
        closeDialog={() => {
          setStandortDialogEntity(undefined);
          setIsStandortDialogOpen(false);
          setIsAddingNewItem(false);
        }}
        isAddingNewItem={isAddingNewItem}
        setIsAddingNewItem={setIsAddingNewItem}
        reloadData={async () => {
          await loadStandortData(0);
        }}
      ></AdminManagementStandortDialog>
      <AdminManagmentWartelisteDialog
        warteliste={wartelisteDialogEntity}
        isOpen={wartelisteDialogEntity !== undefined}
        closeDialog={() => {
          setWartelisteDialogEntity(undefined);
          setIsAddingNewItem(false);
        }}
      ></AdminManagmentWartelisteDialog>
      <AdminManagementKursDialog
        allStandortNamen={allStandortNamen}
        kurs={kursDialogEntity}
        setKurs={setKursDialogEntity}
        isOpen={kursDialogEntity !== undefined}
        closeDialog={() => {
          setKursDialogEntity(undefined);
          setIsAddingNewItem(false);
        }}
        isAddingNewItem={isAddingNewItem}
        setIsAddingNewItem={setIsAddingNewItem}
        reloadData={async () => {
          await loadStandortData(0);
        }}
      ></AdminManagementKursDialog>
      <SnackbarMessage
        open={snackBarCtx.isOpen}
        text={snackBarCtx.text}
        messageType={snackBarCtx.messageType}
        onCloseFct={snackBarCtx.closeSnackbar}
      ></SnackbarMessage>
    </ThemeProvider>
  );
};
export default AdminManagementPage;
