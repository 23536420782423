import { Box, Typography } from "@mui/material";
import { Course } from "../../models/course";
import "../../css/globalVariables.css";
import AdminArchiveItemCourseMsg from "./AdminArchiveItemCourseMsg";
import ArchiveConditionItem from "./ListItem/ArchiveConditionItem";
import ArchiveAttendeeList from "./List/ArchiveAttendeeList";
import ArchiveIncidentItem from "./ListItem/ArchiveIncidentItem";
import { CourseStates } from "../../models/course";

const AdminArchiveItemBoxContent: React.FC<{
  course: Course;
}> = ({ course }) => {
  const isCourseCanceledOrUndocu =
    course.state === CourseStates.CANCELED ||
    course.state === CourseStates.UNDOCUMENTED;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: " 0 30px 15px 30px ",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {course.message && <AdminArchiveItemCourseMsg msg={course.message} />}

          {!isCourseCanceledOrUndocu && (
            <>
              <Typography
                sx={{
                  fontSize: "var(--h3)",
                  color: "var(--grey)",
                  fontWeight: "bold",
                  margin: "15px 15px 0 15px",
                }}
              >
                {"Vorbereitung"}
              </Typography>
              <ArchiveConditionItem
                title={"Hallenzustand"}
                description={course.hallConditionDescr}
                value={course.hallConditionValue}
                attachments={course.hallConditionAttachments}
              />
              <ArchiveConditionItem
                title={"Materialzustand"}
                description={course.materialConditionDescr}
                value={course.materialConditionValue}
                attachments={course.materialConditionAttachments}
              />
              <ArchiveConditionItem
                title={"Alle Trainer anwesend?"}
                description={course.trainerConditionDescr}
                value={course.trainerConditionValue}
              />
            </>
          )}
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          {!isCourseCanceledOrUndocu && (
            <ArchiveAttendeeList
              participants={course.participants}
              course={course}
            />
          )}
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {!isCourseCanceledOrUndocu && (
            <>
              <Typography
                sx={{
                  fontSize: "var(--h3)",
                  color: "var(--grey)",
                  fontWeight: "bold",
                  margin: "15px",
                }}
              >
                {"Vorkommnisse"}
              </Typography>

              {course.incidents && course.incidents?.length !== 0 ? (
                course.incidents.map((incident) => (
                  <ArchiveIncidentItem
                    title={incident.incidentType}
                    description={incident.description}
                    attachments={incident.attachments}
                    key={incident.incidentUUID}
                  ></ArchiveIncidentItem>
                ))
              ) : (
                <Box
                  sx={{
                    margin: "5px",
                    border: "var(--border)",
                    borderRadius: "var(--borderRadiusLarge)",
                  }}
                >
                  <Typography
                    sx={{
                      padding: "15px",
                      color: "var(--grey)",
                      fontSize: "var(--h2)",
                    }}
                  >
                    {"No Incidents"}
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default AdminArchiveItemBoxContent;
