import { Box, Typography } from "@mui/material";
import React from "react";

export const ChartsLegend: React.FC<{
  coloredValueTitle: string;
  grayValueTitle: string;
  coloredValueBgColor?: string;
  grayValueBgColor?: string;
  additionalValueTitle?: string;
  additionalValueBgColor?: string;
}> = ({
  coloredValueTitle,
  grayValueTitle,
  coloredValueBgColor = "#44AEB0",
  grayValueBgColor = "#C1C9CD",
  additionalValueTitle,
  additionalValueBgColor,
}) => {
  const SmallCircle: React.FC<{
    backgroundColor: string;
  }> = ({ backgroundColor }) => {
    return (
      <div
        style={{
          width: "12px",
          height: "12px",
          backgroundColor: backgroundColor,
          borderRadius: "50%",
          marginRight: "5px",
        }}
      />
    );
  };

  const legendStyle = {
    fontSize: "10.5px",
    color: "#607783",
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        paddingTop: "5px",
        flexFlow: "wrap",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <SmallCircle backgroundColor={coloredValueBgColor} />
        <Typography variant="body1" sx={legendStyle}>
          {coloredValueTitle}
        </Typography>
      </Box>
      {additionalValueTitle && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <SmallCircle backgroundColor={additionalValueBgColor} />
          <Typography variant="body1" sx={legendStyle}>
            {additionalValueTitle}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <SmallCircle backgroundColor={grayValueBgColor} />
        <Typography variant="body1" sx={legendStyle}>
          {grayValueTitle}
        </Typography>
      </Box>
    </Box>
  );
};
