import {
  Box,
  ThemeProvider,
  Typography,
  TextField,
  Dialog,
} from "@mui/material";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import "../../../css/globalVariables.css";
import { customTheme } from "../../customTheme";
import { Attachment } from "../../../models/attachment";
import ImageRenderer from "../Image/ImageRenderer";
import { useState } from "react";

const ArchiveConditionItem: React.FC<{
  title: string;
  description: string;
  value: boolean;
  attachments?: Attachment[];
}> = ({ title, description, attachments, value }) => {
  const conditionImgStyle = {
    color: "white",
    padding: "10px",
  };

  const isEmpty = value === false && description === "";

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image: any) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ThemeProvider theme={customTheme}>
        {!isEmpty && (
          <Box
            sx={{
              border: value
                ? "1px solid var(--green)"
                : "1px solid var(--orange)",
              borderRadius: "var(--borderRadiusLarge)",
              marginTop: "20px",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "var(--h4)",
                margin: "10px",
                textTransform: "uppercase",
                color: "var(--grey)",
              }}
            >
              {title}
            </Typography>

            {value ? (
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    width: "100%",
                    backgroundColor: "var(--green)",
                    borderRadius:
                      "0 0 var(--borderRadiusLarge) var(--borderRadiusLarge)",
                    textAlign: "center",
                  }}
                >
                  <CheckRoundedIcon style={conditionImgStyle} />
                </Box>
              </Box>
            ) : (
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    width: "100%",
                    backgroundColor: "var(--orange)",
                    textAlign: "center",
                  }}
                >
                  <PriorityHighRoundedIcon style={conditionImgStyle} />
                </Box>

                <TextField
                  value={description}
                  multiline
                  sx={{
                    m: 2,
                    width: "90%",
                    border: "1px solid var(--orange)",
                    borderRadius: "var(--borderRadiusLarge)",
                  }}
                ></TextField>

                <Box
                  sx={{
                    margin: "0 10px 5px 10px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {attachments &&
                    attachments.map((attachment, index) => {
                      return (
                        <ImageRenderer
                          key={index}
                          attachment={attachment}
                          index={index}
                          onClickFct={() => handleImageClick(attachment)}
                        ></ImageRenderer>
                      );
                    })}
                  <Dialog open={open} onClose={handleClose}>
                    <img
                      src={selectedImage ? selectedImage.link : ""}
                      alt="large"
                      style={{ maxWidth: "100%" }}
                    />
                  </Dialog>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </ThemeProvider>
    </>
  );
};

export default ArchiveConditionItem;
