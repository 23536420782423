import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { ChartsLegend } from "./ChartsLegend";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Anwesenheit,
  Auslastung,
  StandortAuslastungItem,
} from "../../models/admin-dashboard-entities";

const DashboardBarChart: React.FC<{
  auslastungData?: Record<string, Auslastung>;
  anwesenheitData?: Record<string, Anwesenheit>;
  standortData?: Record<string, StandortAuslastungItem>;
  coloredValueTitle: string;
  grayValueTitle: string;
}> = ({
  auslastungData,
  anwesenheitData,
  standortData,
  coloredValueTitle,
  grayValueTitle,
}) => {
  let inputData: Record<string, any>;
  if (auslastungData) {
    inputData = auslastungData;
  }
  if (anwesenheitData) {
    inputData = anwesenheitData;
  }
  if (standortData) {
    inputData = standortData;
  }

  const generateChartData = () => {
    const LABEL_MAX_LENGTH = 20;

    const grayValueData: number[] = [];
    const coloredValueData: number[] = [];
    const percentageLabels: string[] = [];
    let maxGrayValue: number;

    const parseData = (
      coloredValue: number,
      grayValue: number,
      percentageValue: number
    ) => {
      coloredValueData.push(coloredValue);
      percentageLabels.push(`${percentageValue}%`);
      grayValueData.push(grayValue);
    };

    const getMaxValue = (maxAttr: string) => {
      return Math.max(...Object.values(inputData).map((d) => d[maxAttr]));
    };

    if (auslastungData) {
      Object.values(auslastungData).forEach((data: Auslastung) => {
        parseData(data.anmeldungen, data.kapa, data.auslastung);
      });
      maxGrayValue = getMaxValue("kapa");
    }
    if (anwesenheitData) {
      Object.values(anwesenheitData).forEach((data: Anwesenheit) => {
        parseData(data.teilnehmer, data.anmeldungen, data.anwesenheit);
      });
      maxGrayValue = getMaxValue("anmeldungen");
    }
    if (standortData) {
      Object.values(standortData).forEach((data: StandortAuslastungItem) => {
        parseData(data.abrechenbareMitglieder, data.kapa, data.auslastung);
      });
      maxGrayValue = getMaxValue("kapa");
    }

    const displayLabels: string[] = Object.keys(inputData).map(
      (label: string) => {
        return label.length > LABEL_MAX_LENGTH
          ? `${label.substring(0, LABEL_MAX_LENGTH)}...`
          : label;
      }
    );

    return {
      labels: displayLabels,
      datasets: [
        {
          label: coloredValueTitle,
          data: coloredValueData,
          backgroundColor: "#44AEB0",
          borderColor: "#44AEB0",
          barThickness: 12,
        },
        {
          label: grayValueTitle,
          data: grayValueData,
          backgroundColor: "#C1C9CD",
          borderColor: "#C1C9CD",
          barThickness: 12,
        },
      ],
      percentageLabels,
      maxGrayValue,
      displayedLongLabels: Object.keys(inputData),
    };
  };

  const chartData = generateChartData();

  return (
    <div
      style={{
        height: 20 * Object.keys(inputData).length + 150,
      }}
    >
      <Bar
        data={chartData}
        options={{
          interaction: {
            mode: "index",
          },
          indexAxis: "y",
          maintainAspectRatio: false,
          elements: {
            bar: {
              borderWidth: 2,
            },
          },
          scales: {
            x: {
              display: false,
              max: chartData.maxGrayValue + chartData.maxGrayValue * 0.3,
              grid: {
                display: false,
              },
            },
            y: {
              stacked: true,
              grid: {
                display: false,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                title: function (context: any) {
                  const displayLabel =
                    chartData.displayedLongLabels[context[1].dataIndex];
                  return displayLabel;
                },
              },
            },
            datalabels: {
              color: "#444",
              font: {
                size: 12,
              },
              padding: 4,
              anchor: "end",
              align: function (context) {
                const greenWidth =
                  context.chart.data.datasets[0].data[context.dataIndex];
                const grayWidth =
                  context.chart.data.datasets[1].data[context.dataIndex];
                return grayWidth < greenWidth ? "right" : "end";
              },
              offset: function (context) {
                const greenWidth =
                  context.chart.data.datasets[0].data[context.dataIndex];
                const grayWidth =
                  context.chart.data.datasets[1].data[context.dataIndex];
                if (grayWidth < greenWidth) {
                  return (
                    (Number(greenWidth) - Number(grayWidth)) *
                    (400 / chartData.maxGrayValue)
                  );
                }
                return 0;
              },
              formatter: function (_, context) {
                if (context.datasetIndex === 1) {
                  return chartData.percentageLabels[context.dataIndex];
                } else {
                  return null;
                }
              },
            },
          },
        }}
        plugins={[ChartDataLabels]}
      />
      <ChartsLegend
        coloredValueTitle={coloredValueTitle}
        grayValueTitle={grayValueTitle}
      />
    </div>
  );
};

export default DashboardBarChart;
