import { Attachment } from "./attachment";

export enum IncidentTypes {
  INJURY = "VERLETZUNG",
  DAMAGE = "SCHADEN",
  OTHER = "SONSTIGES",
}

export class Incident {
  incidentUUID: string;
  incidentType: IncidentTypes;
  courseID: string;
  description: string;
  attachments: Attachment[];
  isSaved: boolean;

  constructor(
    incidentUUID: string,
    incidentType: IncidentTypes,
    courseID: string,
    description: string,
    attachments: Attachment[],
    isSaved: boolean
  ) {
    this.incidentUUID = incidentUUID;
    this.incidentType = incidentType;
    this.courseID = courseID;
    this.description = description;
    this.attachments = attachments;
    this.isSaved = isSaved;
  }
}
