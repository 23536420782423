import { Box, Typography } from "@mui/material";
import classes from "../../css/Switcher.module.css";
import calendar from "../../img/calendar.png";
import calenderActive from "../../img/calendarWhite.png";
import archiv from "../../img/archiv.png";
import archivActive from "../../img/archivWhite.png";

const Switcher: React.FC<{
  isCourseListActive: boolean;
  onChange: (active: boolean) => void;
}> = ({ isCourseListActive, onChange }) => {
  
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <button
        style={{ cursor: "pointer" }}
        className={`${classes.listBtn} ${
          isCourseListActive ? classes.btnActive : classes.btnNotActive
        } `}
        onClick={() => onChange(true)}
      >
        <img
          alt="Calender Img"
          src={isCourseListActive ? calenderActive : calendar}
          className={classes.img}
        />
        <Typography
          sx={{
            color: isCourseListActive ? "white" : "var(--grey)",
            fontWeight: "bold",
            fontSize: "var(--h2)",
          }}
        >
          {"Kursliste"}
        </Typography>
      </button>
      <button
        style={{ cursor: "pointer" }}
        className={`${classes.archiveBtn} ${
          isCourseListActive ? classes.btnNotActive : classes.btnActive
        } `}
        onClick={() => onChange(false)}
      >
        <img
          alt="Calender Img"
          src={isCourseListActive ? archiv : archivActive}
          className={classes.img}
        />
        <Typography
          sx={{
            color: isCourseListActive ? "var(--grey)" : "white",
            fontWeight: "bold",
            fontSize: "var(--h2)",
          }}
        >
          {"Kursarchiv"}
        </Typography>
      </button>
    </Box>
  );
};

export default Switcher;
