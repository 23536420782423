import { MenuItem, Menu, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import arrowDown from "../../img/arrowDownGrey.png";

const AdminPortalSelectionFilter: React.FC<{
  label: string;
  allValues: string[];
  selectedValues: string[];
  onSelectionChange: (newSelection: string[]) => void;
}> = ({ label, allValues, selectedValues, onSelectionChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const getSelectionString = (selectedValues: string[]) => {
    const MAX_CHARACTERS = 55;
    const sortedFilters = selectedValues.sort();
    const joinedFilters = sortedFilters.join(" | ");

    if (joinedFilters.length <= MAX_CHARACTERS) {
      return joinedFilters;
    } else {
      return `${joinedFilters.slice(0, MAX_CHARACTERS - 3)}...`;
    }
  };

  const handleSelectionClick = (item: string) => {
    if (selectedValues.includes(item)) {
      const newSelectedItems = selectedValues.filter((_) => _ !== item);
      onSelectionChange(newSelectedItems);
    } else {
      const newSelectedItems = [...selectedValues, item];
      onSelectionChange(newSelectedItems);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        sx={{
          margin: "5px",
          border: "1px solid var(--lightGrey)",
          height: "30px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            color: "var(--grey)",
            fontSize: "var(--h4)",
            marginLeft: "10px",
            whiteSpace: "nowrap",
          }}
        >
          {selectedValues.length === 0
            ? label.toLocaleUpperCase()
            : getSelectionString(selectedValues)}
        </Typography>
        <img
          src={arrowDown}
          alt="arrowDown"
          style={{
            height: "10px",
            transform: anchorEl ? "rotate(180deg)" : "",
          }}
        />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        PaperProps={{
          style: {
            width: anchorEl ? anchorEl.clientWidth : "auto",
            color: "var(--grey)",
          },
        }}
      >
        {allValues.length > 0 &&
          allValues
            .slice()
            .sort((a, b) => a.localeCompare(b))
            .map((item) => (
              <MenuItem
                sx={{ height: "30px", fontSize: "var(--h4)" }}
                key={item}
                selected={selectedValues.includes(item)}
                onClick={() => handleSelectionClick(item)}
              >
                {item}
              </MenuItem>
            ))}
      </Menu>
    </>
  );
};

export default AdminPortalSelectionFilter;
