import React, { useEffect, useRef } from "react";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import { Button, Typography } from "@mui/material";
import calendar from "../../img/calendar.png";

const AdminPortalDateFilter: React.FC<{
  resetDates: boolean;
  onChange: (startDate: Date, endDate: Date) => void;
}> = ({ resetDates, onChange }) => {
  const dateRangeInputRef = useRef(null);

  useEffect(() => resetDatePicker, [resetDates]);

  useEffect(() => {
    if (dateRangeInputRef.current) {
      const dateRangeInput = dateRangeInputRef.current;

      const picker = flatpickr(dateRangeInput, {
        mode: "range",
        dateFormat: "d.m.Y",
        weekNumbers: true,
        locale: {
          rangeSeparator: " - ",
        },
        onChange: function (selectedDates) {
          if (selectedDates.length === 2) {
            const startDate: Date = selectedDates[0];
            const endDate: Date = selectedDates[1];
            onChange(startDate, endDate);
          }
        },
      });

      return () => {
        picker.destroy();
      };
    }
  }, [onChange]);

  const resetDatePicker = () => {
    if (dateRangeInputRef.current) {
      dateRangeInputRef.current.value = "";
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        sx={{
          margin: "5px",
          border: "1px solid var(--lightGrey)",
          height: "30px",
          display: "flex",
          justifyContent: "space-between",
        }}
        ref={dateRangeInputRef}
      >
        <Typography
          sx={{
            color: "var(--grey)",
            fontSize: "var(--h4)",
            marginLeft: "10px",
            whiteSpace: "nowrap",
          }}
        >
          {"DATUM"}
        </Typography>
        <img
          src={calendar}
          alt="calendarIcon"
          style={{
            height: "20px",
          }}
        />
      </Button>
    </>
  );
};

export default AdminPortalDateFilter;
