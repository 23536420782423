import { useContext, useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";
import { getTrainerEntity } from "../../api/trainer-api";
import { localStorageKeys } from "../../constants/storage-keys";
import { TRAINER_APP_URL } from "../../config/config";
import { redirectToAuth } from "../../util/NavigationUtil";

const AdminPrivateRoute: React.FC<{
  component: React.FC;
  path: string;
  exact?: boolean;
}> = ({ component, path, exact }) => {
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const trainerUUID = sessionStorage.getItem(localStorageKeys.trainerUUID);

  /* eslint-disable */
  useEffect(() => {
    authContext.areTokensAndTrainerUuidValid().then((value: boolean) => {
      if (!value) {
        redirectToAuth();
        return;
      }
    });
    getTrainerEntity(trainerUUID)
      .then((returnVal) => {
        const trainer = returnVal.trainer;
        if (trainer) {
          if (!trainer.isAdmin) {
            window.location.href = TRAINER_APP_URL;
          }
        }
      })
      .catch(() => {});
  }, [history.location.pathname, history, authContext.isAdmin, trainerUUID]);
  /* eslint-enable */

  return <Route exact={exact} component={component} path={path} />;
};

export default AdminPrivateRoute;
