import { Box, Typography } from "@mui/material";
import "../../../css/globalVariables.css";
import { Course } from "../../../models/course";
import AdminCourseDay from "./AdminCourseDay";
import InfiniteScroll from "react-infinite-scroll-component";

const AdminCourseList: React.FC<{
  courses: Record<string, Course[]>;
  reloadCourses: () => Promise<void>;
  loadMoreCourses: () => Promise<void>;
  handleDeleteCourse: (idOfCourse: string) => void;
}> = ({ courses, reloadCourses, loadMoreCourses, handleDeleteCourse }) => {
  const columns = [
    { index: 1, name: "Kursname", flex: 1 },
    { index: 2, name: "Standort", flex: 1 },
    { index: 3, name: "Trainer", flex: 1 },
    { index: 4, name: "Start-/Endzeit", flex: 1 },
    { index: 5, name: "", flex: 0 },
  ];

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#607783",
          display: "flex",
          flexDirection: "row",
          marginTop: "20px",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          paddingTop: "15px",
          paddingBottom: "15px",
        }}
      >
        {columns.map((col) =>
          col.index !== 5 ? (
            <Typography
              noWrap
              key={col.index}
              sx={{
                flex: col.flex,
                color: "white",
                fontWeight: "bold",
                fontSize: "var(--h4)",
                marginLeft: col.index === 1 ? "50px" : "0",
                overflow: "hidden",
              }}
            >
              {col.name}
            </Typography>
          ) : (
            <Box key={col.index} width={"50px"}></Box>
          )
        )}
      </Box>
      {courses ? (
        <InfiniteScroll
          dataLength={Object.keys(courses).length}
          next={loadMoreCourses}
          hasMore={true}
          loader={<></>}
        >
          {Object.keys(courses).map((key) => (
            <AdminCourseDay
              key={key}
              date={key}
              courses={courses[key]}
              isCourseList={true}
              reloadCourses={reloadCourses}
              handleDeleteCourse={handleDeleteCourse}
            ></AdminCourseDay>
          ))}
        </InfiniteScroll>
      ) : (
        <Typography
          sx={{
            marginTop: "50px",
            textAlign: "center",
            fontSize: "var(--h1)",
            color: "var(--grey)",
          }}
        >
          {"Keine Kurse gefunden."}
        </Typography>
      )}
    </>
  );
};

export default AdminCourseList;
