import { Box, Button, InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import AdminPortalSelectionFilter from "./AdminPortalSelectionFilter";
import "../../css/globalVariables.css";
import { Trainer } from "../../models/trainer";
import {
  CourseFilters,
  getAdminFilterOptions,
} from "../../api/admin-trainer-app-api";
import AdminPortalBooleanFilter from "./AdminPortalBooleanFilter";
import AdminPortalDateFilter from "./AdminPortalDateFilter";
import AdminPortalDataSyncBtn from "./AdminPortalDataSyncBtn";
import searchIcon from "../../img/searchIcon.svg";
import { CourseStates } from "../../models/course";

interface CourseFilterProps {
  onChangeFilters: (filters: CourseFilters, areFiltersEmpty: boolean) => void;
  onClickDataSync: () => void;
  onExecuteFilters: () => Promise<void>;
  areFiltersEmpty: boolean;
  stopLoading: () => void;
  loadAllCourses: () => Promise<void>;
  counter: number;
  isCourseListActive: boolean;
  isSyncLoading: boolean;
}

const AdminPortalFilter: React.FC<CourseFilterProps> = ({
  onChangeFilters,
  onClickDataSync,
  onExecuteFilters,
  areFiltersEmpty,
  stopLoading,
  loadAllCourses,
  counter,
  isCourseListActive,
  isSyncLoading,
}) => {
  /** States */
  const [resetDates, setResetDates] = useState<boolean>(false);

  // Filter Options
  const [filterCourseNames, setFilterCourseNames] = useState<string[]>([]);
  const [filterLocations, setFilterLocations] = useState<string[]>([]);
  const [filterTrainers, setFilterTrainers] = useState<Trainer[]>([]);

  // Selection
  const [selectedNames, setSelectedNames] = useState<string[]>([]);
  const [selectedStartDate, setSelectedStartDate] = useState<string>(undefined);
  const [selectedEndDate, setSelectedEndDate] = useState<string>(undefined);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [selectedStates, setSelectedStates] = useState<string[]>([]);
  const [selectedStatesForDisplay, setSelectedStatesForDisplay] = useState<
    string[]
  >([]);
  const [selectedTrainers, setSelectedTrainers] = useState<string[]>([]);
  const [selectedHallCondition, setSelectedHallCondition] =
    useState<boolean>(undefined);
  const [selectedMaterialCondition, setSelectedMaterialCondition] =
    useState<boolean>(undefined);
  const [selectedIncidentCondition, setSelectedIncidentCondition] =
    useState<boolean>(undefined);
  const [selectedContainsMessage, setSelectedContainsMessage] =
    useState<boolean>(undefined);
  //Search
  const [currentQuery, setCurrentQuery] = useState<string>("");

  /** Functions */

  const isFilterAttrEmpty = (attr: string[] | string | boolean) => {
    if (attr === undefined) {
      return true;
    }

    if (Array.isArray(attr)) {
      return attr.length === 0;
    }

    if (typeof attr === "string") {
      return attr.trim().length === 0;
    }

    if (typeof attr === "boolean") {
      return false;
    }

    return true;
  };

  const checkIfFiltersEmpty = (filters: CourseFilters) => {
    if (
      isFilterAttrEmpty(filters.courseNames) &&
      isFilterAttrEmpty(filters.startDate) &&
      isFilterAttrEmpty(filters.endDate) &&
      isFilterAttrEmpty(filters.locations) &&
      isFilterAttrEmpty(filters.states) &&
      isFilterAttrEmpty(filters.trainerUUIDs) &&
      isFilterAttrEmpty(filters.hallConditionValue) &&
      isFilterAttrEmpty(filters.materialConditionValue) &&
      isFilterAttrEmpty(filters.incidentsExist) &&
      isFilterAttrEmpty(filters.messageExists) &&
      isFilterAttrEmpty(filters.searchQuery)
    ) {
      return true;
    }
    return false;
  };

  const handleDateRangeChange = (startDate: Date, endDate: Date) => {
    setSelectedStartDate(startDate.toISOString());
    setSelectedEndDate(endDate.toISOString());
  };

  const deleteAllFilters = () => {
    setSelectedNames([]);
    setSelectedLocations([]);
    setSelectedStartDate(undefined);
    setSelectedEndDate(undefined);
    setSelectedTrainers([]);
    setSelectedHallCondition(undefined);
    setSelectedMaterialCondition(undefined);
    setSelectedStates([]);
    setSelectedStatesForDisplay([]);
    setSelectedIncidentCondition(undefined);
    setResetDates(!resetDates);
    setSelectedContainsMessage(undefined);
    setCurrentQuery("");
    loadAllCourses().then(stopLoading);
  };

  const deleteArchivRelatedFilters = () => {
    setSelectedHallCondition(undefined);
    setSelectedMaterialCondition(undefined);
    setSelectedStates([]);
    setSelectedStatesForDisplay([]);
    setSelectedIncidentCondition(undefined);
    setSelectedContainsMessage(undefined);
    if (!areFiltersEmpty) {
      loadAllCourses().then(stopLoading);
    }
  };

  const getTrainerUUIDsWithFullName = (fullName: string[]) => {
    const matchingUUIDs: string[] = [];

    for (const input of fullName) {
      const fullName = input.trim();
      if (fullName === "") {
        continue;
      }

      const [searchFirstName, searchLastName] = fullName.split(" ");

      for (const trainer of filterTrainers) {
        if (
          trainer.firstName.toLowerCase() === searchFirstName.toLowerCase() &&
          trainer.lastName.toLowerCase() === searchLastName.toLowerCase()
        ) {
          matchingUUIDs.push(trainer.uuid);
          break;
        }
      }
    }
    return matchingUUIDs;
  };

  /* Search logic */

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentQuery(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onExecuteFilters();
    }
  };

  const stateFilterOptions = [
    {
      title: "Abgesagt",
      value: [CourseStates.CANCELED],
    },
    {
      title: "Dokumentiert",
      value: [CourseStates.CHECK_OUT_COMPLETE],
    },
    {
      title: "Undokumentiert",
      value: [CourseStates.UNDOCUMENTED],
    },
  ];
  /** Effects */

  /* eslint-disable */
  useEffect(() => {
    getAdminFilterOptions().then((response) => {
      setFilterCourseNames(response.courseNames);
      setFilterLocations(response.courseLocations);
      setFilterTrainers(response.trainers);
      stopLoading();
    });
  }, []);

  useEffect(() => {
    deleteArchivRelatedFilters();
  }, [isCourseListActive]);

  useEffect(() => {
    const selectedFilters: CourseFilters = {
      courseNames: selectedNames,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      locations: selectedLocations,
      states: selectedStates,
      trainerUUIDs: getTrainerUUIDsWithFullName(selectedTrainers),
      hallConditionValue: selectedHallCondition,
      materialConditionValue: selectedMaterialCondition,
      incidentsExist:
        selectedIncidentCondition === undefined
          ? undefined
          : !selectedIncidentCondition,
      messageExists: selectedContainsMessage,
      searchQuery: currentQuery,
    };
    const areSelectedFiltersEmpty = checkIfFiltersEmpty(selectedFilters);
    onChangeFilters(selectedFilters, areSelectedFiltersEmpty);
  }, [
    selectedNames,
    selectedStartDate,
    selectedEndDate,
    selectedLocations,
    selectedStates,
    selectedTrainers,
    selectedHallCondition,
    selectedMaterialCondition,
    selectedIncidentCondition,
    selectedContainsMessage,
    currentQuery,
  ]);
  /* eslint-enable */

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          border: "var(--border)",
          borderRadius: "var(--borderRadiusLarge)",
          margin: "10px 0 10px 0",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            margin: "10px",
          }}
        >
          <AdminPortalSelectionFilter
            label={"Kursname"}
            allValues={filterCourseNames}
            selectedValues={selectedNames}
            onSelectionChange={(newSelectedNames: string[]) => {
              setSelectedNames(newSelectedNames);
            }}
          />
          <AdminPortalDateFilter
            onChange={handleDateRangeChange}
            resetDates={resetDates}
          />
          {!isCourseListActive && (
            <AdminPortalBooleanFilter
              label={"Halle"}
              selectedValue={selectedHallCondition}
              onChange={setSelectedHallCondition}
            />
          )}
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            margin: "10px",
          }}
        >
          <AdminPortalSelectionFilter
            label={"Standort"}
            allValues={filterLocations}
            selectedValues={selectedLocations}
            onSelectionChange={(newSelectedLocations: string[]) => {
              setSelectedLocations(newSelectedLocations);
            }}
          />
          {!isCourseListActive && (
            <AdminPortalSelectionFilter
              label={"Status"}
              allValues={stateFilterOptions.map((state) => state.title)}
              selectedValues={selectedStatesForDisplay}
              onSelectionChange={(selectedItems: any) => {
                let filteredStates = stateFilterOptions.filter((state) =>
                  selectedItems.includes(state.title)
                );
                setSelectedStates(
                  filteredStates.reduce((accumulator, state) => {
                    return accumulator.concat(state.value);
                  }, [])
                );
                setSelectedStatesForDisplay(
                  filteredStates.map((state): string => state.title)
                );
              }}
            />
          )}
          {!isCourseListActive && (
            <AdminPortalBooleanFilter
              label={"Material"}
              selectedValue={selectedMaterialCondition}
              onChange={setSelectedMaterialCondition}
            />
          )}
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            margin: "10px",
          }}
        >
          <AdminPortalSelectionFilter
            label={"Trainer"}
            allValues={filterTrainers.map(
              (trainer) => `${trainer.firstName} ${trainer.lastName}`
            )}
            selectedValues={selectedTrainers}
            onSelectionChange={(newSelectedTrainers: string[]) => {
              setSelectedTrainers(newSelectedTrainers);
            }}
          />
          {!isCourseListActive && (
            <AdminPortalBooleanFilter
              label="Nachricht"
              selectedValue={selectedContainsMessage}
              onChange={setSelectedContainsMessage}
            ></AdminPortalBooleanFilter>
          )}
          {!isCourseListActive && (
            <AdminPortalBooleanFilter
              label={"Vorkommnisse"}
              selectedValue={selectedIncidentCondition}
              onChange={setSelectedIncidentCondition}
            />
          )}
          <Button
            sx={{
              backgroundColor: "var(--orange)",
              height: "30px",
              margin: "5px",
              color: "white",
              fontWeight: "bold",
              fontSize: "var(--h4)",
              padding: "0",
              opacity: areFiltersEmpty ? "0.5" : "",
              "&:hover": {
                backgroundColor: "var(--orange)",
                opacity: 0.25,
                transition: "opacity 0.3s",
              },
            }}
            onClick={areFiltersEmpty ? null : deleteAllFilters}
          >
            {"Filter löschen"}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <TextField
          variant="outlined"
          placeholder="SUCHE"
          sx={{
            flex: 1,
            margin: "15px",
            ".MuiOutlinedInput-root": {
              height: "30px",
            },
            ".MuiOutlinedInput-input": {
              padding: "0 14px",
              height: "30px",
            },
          }}
          size="small"
          value={currentQuery}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
          InputProps={{
            startAdornment: (
              <InputAdornment onClick={onExecuteFilters} position="start">
                <button style={{ border: "0px", background: "none" }}>
                  <img
                    src={searchIcon}
                    alt="searchIcon"
                    style={{ width: "15px", height: "15px" }}
                  ></img>
                </button>
              </InputAdornment>
            ),
          }}
        />
        <Button
          onClick={() => {
            onExecuteFilters();
          }}
          sx={{
            flex: 1,
            backgroundColor: "var(--orange)",
            height: "30px",
            margin: "15px",
            color: "white",
            fontWeight: "bold",
            fontSize: "var(--h4)",
            padding: "0",
            "&:hover": {
              backgroundColor: "var(--orange)",
              opacity: 0.25,
              transition: "opacity 0.3s",
            },
          }}
        >
          {"Filter ausführen"}
        </Button>
        <AdminPortalDataSyncBtn
          counter={counter}
          onClick={onClickDataSync}
          isSyncLoading={isSyncLoading}
        />
      </Box>
    </>
  );
};

export default AdminPortalFilter;
