import { Box, Menu, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import arrowDown from "../../../../img/arrowDown.svg";
import { IdAndDataType } from "../../../../models/admin-management-entities";

export const AdminManagementSelector: React.FC<{
  label: string;
  allValues: IdAndDataType[];
  defaultValue: string;
  selectedValue: string;
  onSelectionChange: (newSelection: string) => void;
  initialData?: any;
  onSubmitCheck?: boolean;
  isRequired?: boolean;
  attribute?: string;
  isBoolean?: boolean;
}> = ({
  label,
  allValues,
  defaultValue,
  selectedValue,
  onSelectionChange,
  initialData,
  onSubmitCheck,
  isRequired,
  attribute,
  isBoolean = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [borderColor, setBorderColor] = useState("2px solid var(--green)");
  const valueToCompare = isBoolean ? initialData : initialData[attribute];

  /* eslint-disable */
  useEffect(() => {
    compareValues();
  }, [selectedValue]);

  const compareValues = () => {
    if (valueToCompare === selectedValue) {
      setBorderColor("2px solid var(--green)");
      return;
    }
    setBorderColor("2px solid var(--orange)");
  };

  useEffect(() => {
    isRequiredAndEmpty && setBorderColor("2px solid var(--red)");
  }, [onSubmitCheck]);

  useEffect(() => {
    setBorderColor("2px solid var(--green)");
  }, [initialData]);

  const isRequiredAndEmpty =
    isRequired && (selectedValue === "" || selectedValue === undefined);

  return (
    <>
      <Box
        sx={{
          border: borderColor,
          borderRadius: "var(--borderRadiusLarge)",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          cursor: "pointer",
          height: "72px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px 10px 0 10px",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "var(--h3)",
              color: "var(--grey)",
            }}
          >
            {label}
          </Typography>
        </Box>
        <Box
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            height: "48px",
          }}
        >
          <Typography
            sx={{
              fontSize: "var(--h1)",
              padding: "12px 0 12px 10px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "100%",
            }}
          >
            {selectedValue ? selectedValue : defaultValue}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src={arrowDown}
              alt="arrowDown"
              style={{
                width: "18px",
                transform: anchorEl ? "rotate(180deg)" : "",
                marginRight: "10px",
              }}
            />
          </Box>
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        PaperProps={{
          style: {
            width: anchorEl ? anchorEl.clientWidth : "auto",
            color: "var(--grey)",
          },
        }}
        onFocus={() => setBorderColor("2px solid var(--grey)")}
        onBlur={() => {
          if (isRequiredAndEmpty) {
            setBorderColor("2px solid var(--red)");
          } else {
            compareValues();
          }
        }}
      >
        {allValues &&
          allValues.length > 0 &&
          allValues
            .slice()
            .sort((a, b) => {
              return label === "Wochentag" ? 0 : a.data.localeCompare(b.data);
            })
            .map((item) => (
              <MenuItem
                sx={{ height: "30px", fontSize: "var(--h4)" }}
                key={item.ID}
                selected={selectedValue !== undefined}
                onClick={() => {
                  onSelectionChange(item.data);
                  setAnchorEl(null);
                }}
              >
                {item.data}
              </MenuItem>
            ))}
      </Menu>
    </>
  );
};

export default AdminManagementSelector;
