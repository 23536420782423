import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

const AdminHamburgerMenuItem: React.FC<{
  iconSrc: string;
  iconAlt: string;
  text: string;
  onClick: () => void;
}> = ({ iconSrc, iconAlt, text, onClick }) => {
  const itemTypographyStyles = {
    color: "#607783",
    fontFamily: "Tahoma",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "normal",
  };

  return (
    <ListItem
      sx={{
        padding: "5px",
        "&:hover": {
          cursor: "pointer",
        },
      }}
      onClick={onClick}
    >
      <ListItemIcon sx={{ minWidth: "30px", margin: "0px" }}>
        <img
          src={iconSrc}
          alt={iconAlt}
          style={{ width: "18px", filter: "brightness(1.7) saturate(0.5)" }}
        />
      </ListItemIcon>
      <ListItemText primaryTypographyProps={{ ...itemTypographyStyles }}>
        {text}
      </ListItemText>
    </ListItem>
  );
};

export default AdminHamburgerMenuItem;
