import { List, Typography } from "@mui/material";
import React from "react";
import "../../../css/globalVariables.css";
import { Course } from "../../../models/course";
import {
  dateStringToDate,
  localStringToDate,
} from "../../../util/DateFormatHelper";
import AdminCourseListItem from "../ListItem/AdminCourseListItem";
import AdminArchiveListItem from "../ListItem/AdminArchiveListItem";

const AdminCourseDay: React.FC<{
  date: string;
  courses: Course[];
  navigationCourses?: Course[];
  isCourseList: boolean;
  handleDeleteCourse: (idOfCourse: string) => void;
  reloadCourses?: () => Promise<void>;
  loadMoreCourses?: () => Promise<void>;
}> = ({
  date,
  courses,
  navigationCourses,
  isCourseList,
  handleDeleteCourse,
  reloadCourses,
  loadMoreCourses,
}) => {
  const isDateToday = (date: string) => {
    let today = new Date();
    today.setHours(0, 0, 0, 0);

    const dateObj: Date = dateStringToDate(date);
    let dateWithoutTime = dateObj;
    dateWithoutTime.setHours(0, 0, 0, 0);
    return dateWithoutTime.getTime() === today.getTime();
  };

  const today = new Date();
  const isToday: boolean = isDateToday(date);
  const getCorrectDateString = (dateString: string) => {
    const dateFormatter = new Intl.DateTimeFormat("de-DE", {
      weekday: "short",
      day: "numeric",
      month: "long",
    });

    const areDatesOnSameDay = (date1: Date, date2: Date) => {
      return (
        date1.toISOString().slice(0, 10) === date2.toISOString().slice(0, 10)
      );
    };

    const formatDate = (inputDate: Date) => {
      return dateFormatter.format(inputDate).replace(",", "");
    };

    const inputDate: Date = dateStringToDate(dateString);
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    if (areDatesOnSameDay(inputDate, today)) {
      return `Heute, ${formatDate(inputDate)}`;
    }
    if (areDatesOnSameDay(inputDate, tomorrow)) {
      return `Morgen, ${formatDate(inputDate)}`;
    }
    if (areDatesOnSameDay(inputDate, yesterday)) {
      return `Gestern, ${formatDate(inputDate)}`;
    }
    return formatDate(inputDate);
  };

  const getListItem = (course: Course) => {
    return (
      <React.Fragment key={course.id}>
        {isCourseList ? (
          <AdminCourseListItem
            key={course.id}
            course={course}
            reloadCourses={reloadCourses}
            handleDeleteCourse={handleDeleteCourse}
          ></AdminCourseListItem>
        ) : (
          <AdminArchiveListItem
            key={course.id}
            course={course}
            navigationCourses={navigationCourses}
            loadMoreCourses={loadMoreCourses}
            handleDeleteCourse={handleDeleteCourse}
          ></AdminArchiveListItem>
        )}
      </React.Fragment>
    );
  };

  return (
    <>
      {!(courses.length === 0) && (
        <Typography
          sx={{
            fontSize: "var(--p2)",
            fontWeight: "bold",
            padding: "15px",
            paddingLeft: "45px",
            color: "var(--grey)",
          }}
        >
          {getCorrectDateString(date)}
        </Typography>
      )}
      {!isToday && (
        <List
          sx={{
            width: "100%",
            margin: "auto",
            padding: "0",
          }}
        >
          {courses && courses.map((course: Course) => getListItem(course))}
        </List>
      )}
      {isToday && (
        <div>
          <List
            sx={{
              width: "100%",
              margin: "auto",
              padding: "0",
            }}
          >
            {courses &&
              courses
                .filter(
                  (filter) =>
                    localStringToDate(filter.startDate).getTime() <
                    today.getTime()
                )
                .map((course: Course) => getListItem(course))}
          </List>
          {isCourseList && (
            <>
              <div
                style={{ display: "flex", flexDirection: "row", content: " " }}
              >
                <div
                  style={{
                    background: "#FA6969",
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    position: "absolute",
                    left: "-14px",
                  }}
                ></div>
                <div
                  style={{
                    background: "#FA6969",
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    position: "absolute",
                    right: "-14px",
                  }}
                ></div>
              </div>
              <hr
                style={{
                  backgroundColor: "#FA6969",
                  width: "100%",
                  border: "none",
                  height: "3px",
                }}
              />
            </>
          )}
          <List
            sx={{
              width: "100%",
              margin: "auto",
              padding: "0",
            }}
          >
            {courses &&
              courses
                .filter(
                  (filter) =>
                    localStringToDate(filter.startDate).getTime() >=
                    today.getTime()
                )
                .map((course: Course) => getListItem(course))}
          </List>
        </div>
      )}
    </>
  );
};

export default AdminCourseDay;
