import React, { useContext, useEffect, useState } from "react";
import {
  AdminManagementDialogKey,
  IdAndDataType,
  InputData,
  Kurs,
  Standort,
} from "../../../models/admin-management-entities";
import {
  AdminManagementDialogTypes,
  AdminManagementDialog,
} from "./AdminManagementDialog";
import { AdminManagmentList } from "../AdminManagementList";
import { AdminManagementKursDialog } from "./AdminManagementKursDialog";
import { Box, Button, Typography } from "@mui/material";

import {
  addStandort,
  deleteKurs,
  getStandortByID,
  updateStandort,
} from "../../../api/admin-management-api";
import { isNotEmpty, isValidPLZ } from "../../../util/RegexFunctions";
import { SnackBarContext } from "../../../context/snackbar-context";
import { UpdateStandortDTO } from "../../../api/dtos/admin-management-dtos";
import { HttpStatusCode } from "axios";
import AdminManagementListHeader from "../AdminManagementListHeader";

export const AdminManagementStandortDialog: React.FC<{
  allStandortNamen: IdAndDataType[];
  standort: Standort;
  isOpen: boolean;
  closeDialog: () => void;
  isAddingNewItem?: boolean;
  setIsAddingNewItem?: (_: boolean) => void;
  setStandort: (_: Standort) => void;
  reloadData?: () => Promise<void>;
}> = ({
  allStandortNamen,
  standort,
  isOpen,
  closeDialog,
  isAddingNewItem = false,
  setIsAddingNewItem,
  setStandort,
  reloadData,
}) => {
  const snackBarCtx = useContext(SnackBarContext);

  const [kursDialogEntity, setKursDialogEntity] = useState<Kurs>(undefined);
  const [isKursDialogOpen, setIsKursDialogOpen] = useState<boolean>(false);
  const [currentStandort, setCurrentStandort] = useState<Standort>(standort);

  useEffect(() => {
    setCurrentStandort(standort);
  }, [standort]);

  if (!standort) {
    return null;
  }

  const standortInputData: InputData = {
    Standortdaten: {
      "Standortname*": {
        attribute: "Standortname",
        data: standort.Standortname,
        type: AdminManagementDialogTypes.TEXT_INPUT,
        validatorFct: isNotEmpty,
        isRequired: true,
      },
      "Strasse*": {
        attribute: "Strasse",
        data: standort.Strasse,
        type: AdminManagementDialogTypes.TEXT_INPUT,
        validatorFct: isNotEmpty,
        isRequired: true,
      },
      "PLZ*": {
        attribute: "PLZ",
        data: standort.PLZ,
        type: AdminManagementDialogTypes.TEXT_INPUT,
        validatorFct: isValidPLZ,
        isRequired: true,
      },
      "Ort*": {
        attribute: "Ort",
        data: standort.Ort,
        type: AdminManagementDialogTypes.TEXT_INPUT,
        validatorFct: isNotEmpty,
        isRequired: true,
      },
    },
    Kontaktdaten: {
      Ansprechpartner: {
        attribute: "Ansprechpartner",
        data: standort.Ansprechpartner,
        type: AdminManagementDialogTypes.TEXT_INPUT,
      },
      Kontakt: {
        attribute: "Kontakt",
        data: standort.Kontakt,
        type: AdminManagementDialogTypes.TEXT_INPUT,
      },
    },
  };

  function excludeIDAndKurse(standort: Standort) {
    const { ID, Kurse, ...otherProps } = standort;
    return otherProps;
  }

  function formatToRequestStandort(standort: Standort): UpdateStandortDTO {
    const { ID, Kurse, ...otherProps } = standort;
    return {
      ID_Standort: Number(ID),
      ...otherProps,
    };
  }

  return (
    <>
      <AdminManagementDialog
        dialogKey={AdminManagementDialogKey.STANDORT}
        title={currentStandort ? `${currentStandort.Standortname}` : ""}
        isOpen={isOpen}
        closeDialog={closeDialog}
        entity={currentStandort}
        entityId={standort.ID}
        inputData={standortInputData}
        addFnct={
          isAddingNewItem
            ? async () => {
                addStandort(excludeIDAndKurse(currentStandort))
                  .then((response) => {
                    if (response.status === HttpStatusCode.Created) {
                      setStandort(response);
                      setCurrentStandort(response);
                      setIsAddingNewItem(false);
                      reloadData();
                      snackBarCtx.openSnackbar(
                        "Standort wurde erfolgreich hinzugefügt!",
                        "success"
                      );
                    }
                  })
                  .catch((response) => {
                    snackBarCtx.openSnackbar(
                      "Standort kann nicht hinzugefügt werden: " +
                        response.message,
                      "error"
                    );
                  });
              }
            : undefined
        }
        updateFnct={async () => {
          await updateStandort(formatToRequestStandort(currentStandort))
            .then((response) => {
              if (response.status === HttpStatusCode.Ok) {
                reloadData();
                snackBarCtx.openSnackbar(
                  "Standort wurde erfolgreich geändert!",
                  "success"
                );
              }
            })
            .catch((response) => {
              snackBarCtx.openSnackbar(
                "Standort kann nicht geändert werden: " + response.message,
                "error"
              );
            });
        }}
        setEntity={setCurrentStandort}
        initialData={standort}
        children={
          !isAddingNewItem && (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "end",
                }}
              >
                <Typography
                  sx={{
                    color: "#607783",
                    fontSize: "12px",
                    padding: "16px 0 0 16px",
                    fontWeight: "900",
                  }}
                >
                  Kurse
                </Typography>
                <Button
                  onClick={() => {
                    setKursDialogEntity({} as Kurs);
                    setIsKursDialogOpen(true);
                    setIsAddingNewItem(true);
                  }}
                  sx={{
                    minWidth: "300px",
                    backgroundColor: "var(--orange)",
                    height: "30px",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "var(--h4)",
                    padding: "0",
                    "&:hover": {
                      backgroundColor: "var(--orange)",
                      opacity: 0.75,
                      transition: "opacity 0.3s",
                    },
                  }}
                >
                  Kurs hinzufügen
                </Button>
              </Box>
              <AdminManagementListHeader
                isWithDistance={true}
                columns={[
                  { title: "Kursname" },
                  { title: "Kurzbezeichnung" },
                  { title: "Bereich" },
                  { title: "Displayname" },
                  { title: "Wochentag" },
                  { title: "Start" },
                  { title: "Ende" },
                  { title: "Alter" },
                  { title: "Kapazität" },
                  { title: "", flex: 1 },
                ]}
              ></AdminManagementListHeader>
              <AdminManagmentList
                dialogKey={AdminManagementDialogKey.STANDORT}
                isLoading={false}
                data={currentStandort && currentStandort.Kurse}
                setDialogEntityData={setKursDialogEntity}
                listItemColumns={[
                  { attrName: "Kursname" },
                  { attrName: "Kurzbezeichnung" },
                  { attrName: "Bereich" },
                  { attrName: "Displayname" },
                  { attrName: "Wochentag" },
                  {
                    attrName: "Anfangsuhrzeit",
                    processFnct: (_: string) => {
                      if (!_) {
                        return "";
                      }
                      return _.slice(0, 5);
                    },
                  },
                  {
                    attrName: "Enduhrzeit",
                    processFnct: (_: string) => {
                      if (!_) {
                        return "";
                      }
                      return _.slice(0, 5);
                    },
                  },
                  { attrName: "Alter" },
                  { attrName: "max_Teilnehmer" },
                ]}
                listItemOnClick={(ID: number) => {
                  setKursDialogEntity(
                    currentStandort &&
                      currentStandort.Kurse.find((kurs) => kurs.ID === ID)
                  );
                }}
                noDataText={
                  "Es konnten keine Kurse zu diesem Standort gefunden werden."
                }
                deleteDialogTextContent={
                  <>
                    {"Bist du sicher, dass du den Kurs "}
                    <br />
                    <b>
                      {`${kursDialogEntity && kursDialogEntity.ID} 
                      ${kursDialogEntity && kursDialogEntity.Displayname}`}
                    </b>
                    <br />
                    {"endgültig löschen möchtest?"}
                  </>
                }
                currentItem={kursDialogEntity}
                deleteFnct={async (kursId: number) => {
                  await deleteKurs(kursId)
                    .then((response) => {
                      if (response.status === HttpStatusCode.Ok) {
                        reloadData();
                        snackBarCtx.openSnackbar(
                          "Kurs wurde erfolgreich gelöscht!",
                          "success"
                        );
                        closeDialog();
                      }
                    })
                    .catch((response) => {
                      snackBarCtx.openSnackbar(
                        "Kurs kann nicht gelöscht werden: " + response.message,
                        "error"
                      );
                    });
                }}
                setIsEntityDialogOpen={setIsKursDialogOpen}
              />
            </>
          )
        }
      ></AdminManagementDialog>
      <AdminManagementKursDialog
        allStandortNamen={allStandortNamen}
        kurs={kursDialogEntity}
        setKurs={setKursDialogEntity}
        isOpen={isKursDialogOpen}
        closeDialog={() => {
          setIsKursDialogOpen(false);
          setKursDialogEntity(undefined);
          setIsAddingNewItem(false);
        }}
        parentEntityId={currentStandort && currentStandort.ID}
        isAddingNewItem={isAddingNewItem}
        setIsAddingNewItem={setIsAddingNewItem}
        reloadData={async () => {
          const standortByID = await getStandortByID(currentStandort.ID);
          setStandort(standortByID);
          setCurrentStandort(standortByID);
          reloadData();
        }}
      ></AdminManagementKursDialog>
    </>
  );
};
