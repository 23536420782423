import { Box, Button, Typography } from "@mui/material";
import "../../css/globalVariables.css";
import logo from "../../img/kindersportlogo.jpg";
import classes from "../../css/AdminPortal.module.css";
import burgermenu from "../../img/burgermenu.png";
import arrow from "../../img/arrowLeftActive.png";
import { useState } from "react";
import AdminHamburgerMenuDialog from "./AdminHamburgerMenuDialog";
import { AdminManagementTabTypes } from "../../constants/admin-management-constants";

const AdminPortalHeader: React.FC<{
  backBtnOnClick?: () => void;
  headline?: string;
  setIsCourseListActive?: (isCourseListActive: boolean) => void;
  setAdminManagementActiveTab?: (activeTab: AdminManagementTabTypes) => void;
}> = ({
  backBtnOnClick,
  headline = "Admin Portal",
  setIsCourseListActive,
  setAdminManagementActiveTab,
}) => {
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);

  return (
    <Box
      sx={{
        margin: "20px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
        {backBtnOnClick && (
          <Button
            onClick={() => {
              if (backBtnOnClick) {
                backBtnOnClick();
              }
            }}
          >
            <img alt="arrow" src={arrow} style={{ height: "30px" }}></img>
          </Button>
        )}
        <img src={logo} alt="logo" className={classes.logo} />
      </div>

      <div style={{ flex: 1, textAlign: "center" }}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "var(--h0)",
            color: "var(--grey)",
            margin: "auto",
          }}
        >
          {headline}
        </Typography>
      </div>
      <div style={{ flex: 1, textAlign: "right" }}>
        <button
          onClick={() => {
            setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
          }}
          style={{ cursor: "pointer" }}
          className={classes.burgermenuBtn}
        >
          <img src={burgermenu} alt="menu" className={classes.burgermenuImg} />
        </button>
      </div>
      <AdminHamburgerMenuDialog
        isOpen={isHamburgerMenuOpen}
        onCloseDialog={() => setIsHamburgerMenuOpen(false)}
        setIsCourseListActive={(isActive: boolean) => {
          if (setIsCourseListActive) {
            setIsCourseListActive(isActive);
          }
        }}
        setAdminManagementActiveTab={(activeTab: AdminManagementTabTypes) => {
          if (setAdminManagementActiveTab) {
            setAdminManagementActiveTab(activeTab);
          }
        }}
      />
    </Box>
  );
};

export default AdminPortalHeader;
