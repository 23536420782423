import React, { useState } from "react";
import { Box, Dialog, Typography } from "@mui/material";
import { Course } from "../../../models/course";
import "../../../css/globalVariables.css";
import grayX from "../../../img/grayX.svg";
import exlamationMark from "../../../img/exlamation_mark.png";
import { cancelCourse } from "../../../api/admin-trainer-app-api";
import TextFieldWithChars from "../../TextFieldWithChars";

const AdminCancelCourseDialog: React.FC<{
  showDialog: boolean;
  onCloseDialog: () => void;
  course: Course;
}> = ({ course, showDialog, onCloseDialog }) => {
  const MIN_CHAR_MSG = 5;
  const MAX_CHAR_MSG = 250;
  const [msg, setMsg] = useState("");

  const isMsgValid = () => {
    return msg.length > MIN_CHAR_MSG;
  };

  const buttonTypographyStyle = {
    color: "var(--white)",
    textAlign: "center",
    fontFamily: "Tahoma",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "24px",
    letterSpacing: "0.5px",
  };

  return (
    <Dialog
      open={showDialog}
      onClose={onCloseDialog}
      keepMounted
      sx={{
        "& .MuiDialog-paper": {
          width: "750px",
          height: "750px",
          borderRadius: "8px",
          border: "1px solid rgba(96, 119, 131, 0.50)",
          boxShadow: "0px 20px 20px 0px rgba(0, 0, 0, 0.25)",
        },
      }}
    >
      <Box
        sx={{
          height: "100%",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "20px",
        }}
      >
        <Box
          onClick={() => {
            onCloseDialog();
          }}
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
          }}
        >
          <img alt="close" src={grayX} />
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: "55px",
            width: "30px",
            height: "30px",
            border: "2px solid #607783",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <img
            alt="exclamation_mark"
            src={exlamationMark}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: "140px",
          }}
        >
          <Typography
            sx={{
              color: "#607783",
              fontFamily: "Tahoma",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              paddingBottom: "50px",
            }}
          >
            Möchtest du den Kurs <b>{course.courseName}</b> wirklich absagen ?
          </Typography>

          <TextFieldWithChars
            placeholder="Gebe hier den Grund für die Kursabsage an..."
            minChars={MIN_CHAR_MSG}
            maxChars={MAX_CHAR_MSG}
            rows={10}
            onChange={(value: string) => {
              setMsg(value);
            }}
          ></TextFieldWithChars>
        </Box>

        {/* Buttons */}
        <Box
          sx={{
            position: "absolute",
            bottom: "20px",
            right: "20px",
            display: "flex",
            gap: "10px",
          }}
        >
          <button
            style={{
              width: "150px",
              height: "50px",
              backgroundColor: "var(--orange)",
              color: "white",
              fontWeight: "bold",
              border: "none",
              borderRadius: "8px",
              cursor: "pointer",
            }}
            onClick={() => {
              onCloseDialog();
            }}
          >
            <Typography sx={buttonTypographyStyle}>Abbrechen</Typography>
          </button>
          <button
            disabled={!isMsgValid()}
            style={{
              width: "150px",
              height: "50px",
              backgroundColor: "var(--orange)",
              color: "white",
              fontWeight: "bold",
              border: "none",
              borderRadius: "8px",
              cursor: "pointer",
              opacity: isMsgValid() ? 1 : 0.5,
              transition: "opacity 0.3s",
            }}
            onClick={async () => {
              await cancelCourse(course.courseID, msg);
              onCloseDialog();
            }}
          >
            <Typography sx={buttonTypographyStyle}>Bestätigen</Typography>
          </button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AdminCancelCourseDialog;
