import * as React from "react";
import { Box, Input, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";

export const AdminManagementTimeInputField: React.FC<{
  label: string;
  value: string;
  setInputValue: (timeString: string) => void;
  initialData?: any;
}> = ({ label, value, setInputValue, initialData }) => {
  const timeInputRef = useRef(null);
  const initialTextRef = useRef(value);
  const [borderColor, setBorderColor] = useState("2px solid var(--green)");
  const isModifiedRef = useRef(false);

  useEffect(() => {
    if (timeInputRef.current) {
      flatpickr(timeInputRef.current, {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
        defaultDate: value,
        closeOnSelect: false,
        onChange: (selectedTime) => {
          if (selectedTime.length > 0) {
            const newTime = selectedTime[0].toTimeString().slice(0, 5);

            if (newTime !== initialTextRef.current) {
              isModifiedRef.current = true;
              setBorderColor("2px solid var(--orange)");
            } else {
              isModifiedRef.current = false;
              setBorderColor("2px solid var(--green)");
            }
          }
        },
        onClose: (selectedTime) =>
          setInputValue(selectedTime[0].toTimeString().slice(0, 8)),
      });
    }
  }, [timeInputRef, value, setInputValue]);

  useEffect(() => {
    setBorderColor("2px solid var(--green)");
  }, [initialData]);

  return (
    <Box
      sx={{
        border: borderColor,
        borderRadius: "var(--borderRadiusLarge)",
      }}
      ref={timeInputRef}
    >
      <Typography
        sx={{
          fontSize: "var(--h3)",
          padding: "10px 0 0 10px",
          color: "var(--grey)",
        }}
      >
        {label}
      </Typography>
      <style>
        {`
          .flatpickr-time {
            background: var(--white) !important; 
            color: white !important;
            border-color: var(--orange) !important;
          }
        `}
      </style>
      <Input
        sx={{
          width: "100%",
          padding: "10px 0 0 10px",
          border: "none",
          "&.Mui-disabled": {
            "&::before": {
              borderBottom: "none !important",
            },
            "&::after": {
              borderBottom: "none !important",
            },
          },
          "&::before": {
            borderBottom: "none",
          },
          "&::after": {
            borderBottom: "none",
          },
          "&:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
          },
        }}
        type="text"
        value={(value && value.slice(0, 5)) || ""}
        onFocus={() => setBorderColor("2px solid var(--grey)")}
        onBlur={() =>
          setBorderColor(
            isModifiedRef.current
              ? "2px solid var(--orange)"
              : "2px solid var(--green)"
          )
        }
        readOnly
      />
    </Box>
  );
};
