import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const DashboardChart: React.FC<{
  datasets: {
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
  }[];
  max: number;
  isPercentage?: boolean;
  min?: number;
}> = ({ datasets, max, isPercentage, min }) => {
  const getPast12MonthNames = () => {
    const monthNames = [];
    const date = new Date();

    for (let i = 0; i < 12; i++) {
      date.setMonth(date.getMonth() - 1);
      const monthName = date.toLocaleString("de-DE", { month: "long" });
      monthNames.unshift(monthName);
    }

    return monthNames;
  };

  const monthNames = getPast12MonthNames();

  return (
    <div style={{ width: "100%", height: "auto" }}>
      <Line
        data={{
          labels: monthNames,
          datasets: datasets,
        }}
        options={{
          interaction: { mode: "index", intersect: false },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,

              min: min,
              max: max,
              ticks: isPercentage && {
                callback: function (value: any) {
                  return `${value}%`;
                },
              },
            },
            x: {
              type: "category" as const,
              labels: monthNames,
            },
          },
          plugins: {
            legend: {
              position: "top" as const,
            },
            tooltip: isPercentage && {
              callbacks: {
                label: function (tooltipItem: any) {
                  return `${tooltipItem.dataset.label}: ${tooltipItem.raw}%`;
                },
              },
            },
          },
        }}
      />
    </div>
  );
};
