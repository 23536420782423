import React, { useContext, useEffect, useState } from "react";
import {
  AdminManagementDialogKey,
  IdAndDataType,
  InputData,
  Kurs,
} from "../../../models/admin-management-entities";
import {
  AdminManagementDialogTypes,
  AdminManagementDialog,
} from "./AdminManagementDialog";
import {
  addStandortKurs,
  getBereichAndKurzbezeichnungValues,
  updateKurs,
} from "../../../api/admin-management-api";
import { UpdateKursstammDTO } from "../../../api/dtos/admin-management-dtos";
import { isDigitsOnly, isNotEmpty } from "../../../util/RegexFunctions";
import { HttpStatusCode } from "axios";
import { SnackBarContext } from "../../../context/snackbar-context";

export const AdminManagementKursDialog: React.FC<{
  kurs: Kurs;
  setKurs: (_: Kurs) => void;
  allStandortNamen: IdAndDataType[];
  isOpen: boolean;
  closeDialog: () => void;
  isAddingNewItem?: boolean;
  setIsAddingNewItem?: (_: boolean) => void;
  parentEntityId?: number;
  reloadData?: () => Promise<void>;
}> = ({
  kurs,
  setKurs,
  allStandortNamen,
  isOpen,
  closeDialog,
  isAddingNewItem = false,
  setIsAddingNewItem,
  parentEntityId,
  reloadData,
}) => {
  const [currentKurs, setCurrentKurs] = useState<Kurs>(kurs);
  const [bereichValues, setBereichValues] = useState<string[]>();
  const [kurzBezeichnungValues, setKurzBezeichnungValues] =
    useState<string[]>();

  const snackBarCtx = useContext(SnackBarContext);

  useEffect(() => {
    setCurrentKurs(kurs);
    getBereichAndKurzbezeichnungValues().then((valuesObject) => {
      setBereichValues(valuesObject.bereichValues);
      setKurzBezeichnungValues(valuesObject.kurzBezeichnungValues);
    });
  }, [kurs]);

  if (!kurs) {
    return null;
  }
  const kursInputData: InputData = {
    Kursbezeichnung: {
      "Kursname*": {
        attribute: "Kursname",
        data: kurs.Kursname,
        type: AdminManagementDialogTypes.TEXT_INPUT,
        validatorFct: isNotEmpty,
      },
      "Displayname*": {
        attribute: "Displayname",
        data: kurs.Displayname,
        type: AdminManagementDialogTypes.TEXT_INPUT,
        validatorFct: isNotEmpty,
      },
      "Kurzbezeichnung*": {
        attribute: "Kurzbezeichnung",
        data: kurs.Kurzbezeichnung,
        type: AdminManagementDialogTypes.KURZBEZEICHNUNG_SELECTOR,
        validatorFct: isNotEmpty,
      },
      "Bereich*": {
        attribute: "Bereich",
        data: kurs.Bereich,
        type: AdminManagementDialogTypes.BEREICH_SELECTOR,
        validatorFct: isNotEmpty,
      },
    },
    Kursinformationen: {
      Wochentag: {
        attribute: "Wochentag",
        data: kurs.Wochentag,
        type: AdminManagementDialogTypes.WEEKDAY_SELECTOR,
      },
      Anfangsuhrzeit: {
        attribute: "Anfangsuhrzeit",
        data: kurs.Anfangsuhrzeit?.slice(0, 5),
        type: AdminManagementDialogTypes.TIME_INPUT,
      },
      Enduhrzeit: {
        attribute: "Enduhrzeit",
        data: kurs.Enduhrzeit?.slice(0, 5),
        type: AdminManagementDialogTypes.TIME_INPUT,
      },
      Eltern: {
        attribute: "Eltern",
        data: kurs.Eltern?.toString() ?? "",
        type: AdminManagementDialogTypes.BOOLEAN_INPUT,
      },
      Alter: {
        attribute: "Alter",
        data: kurs.Alter,
        type: AdminManagementDialogTypes.TEXT_INPUT,
      },
      Bemerkung: {
        attribute: "Bemerkung",
        data: kurs.Bemerkung,
        type: AdminManagementDialogTypes.TEXT_INPUT,
      },
      Standort: {
        attribute: "ID_Ort",
        data: kurs.ID_Ort?.toString(),
        type: AdminManagementDialogTypes.STANDORT_SELECTOR,
      },
      Kapazität: {
        attribute: "max_Teilnehmer",
        data: kurs.max_Teilnehmer?.toString(),
        type: AdminManagementDialogTypes.TEXT_INPUT,
        validatorFct: isDigitsOnly,
      },
      "Status*": {
        attribute: "Status",
        data: kurs.Status?.toString(),
        type: AdminManagementDialogTypes.BOOLEAN_INPUT,
        validatorFct: isNotEmpty,
        isRequired: true,
      },
    },
    Beitrag: {
      "Mitgliedsbeitrag*": {
        attribute: "Mitgliedsbeitrag",
        data: kurs.Mitgliedsbeitrag?.toString(),
        type: AdminManagementDialogTypes.TEXT_INPUT,
        validatorFct: isDigitsOnly,
        isRequired: true,
      },
      "Aufnahmegebuehr*": {
        attribute: "Aufnahmegebuehr",
        data: kurs.Aufnahmegebuehr?.toString(),
        type: AdminManagementDialogTypes.TEXT_INPUT,
        validatorFct: isDigitsOnly,
        isRequired: true,
      },
      "Kontingentierbar*": {
        attribute: "Kontingentierbar",
        data:
          kurs.Kontingentierbar !== undefined &&
          kurs.Kontingentierbar.toString(),
        type: AdminManagementDialogTypes.BOOLEAN_INPUT,
        validatorFct: isNotEmpty,
        isRequired: true,
      },
    },
  };

  function formatDataForRequest(kurs: Kurs) {
    const {
      ID_Ort,
      ID_Trainer,
      max_Teilnehmer,
      Eltern,
      Status,
      Mitgliedsbeitrag,
      Aufnahmegebuehr,
      Kontingentierbar,
      ...otherProps
    } = kurs;

    return {
      ID_Ort: parentEntityId ? Number(parentEntityId) : Number(ID_Ort),
      ID_Trainer: Number(ID_Trainer),
      max_Teilnehmer: Number(max_Teilnehmer),
      Status: Status,
      Eltern: Eltern,
      Mitgliedsbeitrag: Number(Mitgliedsbeitrag),
      Aufnahmegebuehr: Number(Aufnahmegebuehr),
      Kontingentierbar: Kontingentierbar,
      ...otherProps,
    };
  }

  function formatKursForRequest(kurs: Kurs): UpdateKursstammDTO {
    const {
      ID,
      max_Teilnehmer,
      Mitgliedsbeitrag,
      Aufnahmegebuehr,
      ...otherProps
    } = kurs;
    return {
      ID_Kurs: Number(ID),
      max_Teilnehmer: Number(max_Teilnehmer),
      Mitgliedsbeitrag: Number(Mitgliedsbeitrag),
      Aufnahmegebuehr: Number(Aufnahmegebuehr),
      ...otherProps,
    };
  }

  return (
    <>
      <AdminManagementDialog
        dialogKey={AdminManagementDialogKey.KURS}
        title={kurs ? `${kurs.Displayname}` : ""}
        isOpen={isOpen}
        closeDialog={closeDialog}
        entity={currentKurs}
        setEntity={setCurrentKurs}
        inputData={kursInputData}
        initialData={kurs}
        entityId={kurs.ID}
        parentEntityId={parentEntityId}
        allStandortNamen={allStandortNamen}
        bereichValues={bereichValues}
        kurzBezeichnungValues={kurzBezeichnungValues}
        addFnct={
          isAddingNewItem
            ? async () => {
                await addStandortKurs(formatDataForRequest(currentKurs))
                  .then((response) => {
                    if (response.status === HttpStatusCode.Created) {
                      setIsAddingNewItem(false);
                      reloadData();
                      closeDialog();
                      snackBarCtx.openSnackbar(
                        "Kurs wurde erfolgreich hinzugefügt!",
                        "success"
                      );
                    }
                  })
                  .catch((response) => {
                    snackBarCtx.openSnackbar(
                      "Kurs kann nicht hinzugefügt werden: " + response.message,
                      "error"
                    );
                  });
              }
            : undefined
        }
        updateFnct={async () => {
          await updateKurs(formatKursForRequest(currentKurs))
            .then((response) => {
              if (response.status === HttpStatusCode.Ok) {
                reloadData();
                closeDialog();
                snackBarCtx.openSnackbar(
                  "Kurs wurde erfolgreich geändert!",
                  "success"
                );
              }
            })
            .catch((response) => {
              snackBarCtx.openSnackbar(
                "Kurs kann nicht geändert werden: " + response.message,
                "error"
              );
            });
        }}
      />
    </>
  );
};
