import { Box } from "@mui/material";
import React, { useState } from "react";
import AdminManagementSelector from "./AdminManagementSelector";

export const AdminManagementBooleanInputField: React.FC<{
  label?: string;
  text?: string;
  onChange: (e: string) => void;
  initialData: any;
  onSubmitCheck: boolean;
  isRequired?: boolean;
  attribute?: string;
}> = ({
  label,
  text,
  onChange,
  initialData,
  onSubmitCheck,
  isRequired,
  attribute,
}) => {
  const trueTextValue = "Ja";
  const falseTextValue = "Nein";
  const [selectedValue, setSelectedValue] = useState<string>(
    text ? (text === "true" ? trueTextValue : falseTextValue) : ""
  );

  const handleSelectionChange = (value: string) => {
    const valueToBoolean = value === trueTextValue ? "true" : "false";
    setSelectedValue(value);
    onChange(valueToBoolean);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <AdminManagementSelector
        label={label}
        allValues={[
          { ID: 0, data: falseTextValue },
          { ID: 1, data: trueTextValue },
        ]}
        defaultValue={
          initialData[attribute]
            ? initialData[attribute] === true
              ? trueTextValue
              : falseTextValue
            : ""
        }
        selectedValue={selectedValue}
        onSelectionChange={handleSelectionChange}
        initialData={
          initialData[attribute] === true ? trueTextValue : falseTextValue
        }
        onSubmitCheck={onSubmitCheck}
        isRequired={isRequired}
        attribute={attribute}
        isBoolean={true}
      />
    </Box>
  );
};
