import { Box, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { customTheme } from "../../components/customTheme";
import "../../css/globalVariables.css";
import { Course } from "../../models/course";
import {
  CourseFilters,
  syncSmartplanData,
  filterCourses,
  getAdminArchiveCourses,
  getAdminCourses,
  getCounter,
} from "../../api/admin-trainer-app-api";
import AdminHeader from "../../components/AdminHeader/AdminPortalHeader";
import Switcher from "../../components/AdminTrainerApp/Switcher";
import AdminCourseList from "../../components/AdminTrainerApp/List/AdminCourseList";
import { TIMEOUT_MILLIS } from "../../constants/timeout-duration";
import AdminArchiveList from "../../components/AdminTrainerApp/List/AdminArchiveList";
import AdminPortalFilter from "../../components/AdminFilter/AdminPortalFilter";
import GeneralDocuPage from "../GeneralDocuPage";
import AdminInfoDialog from "../../components/AdminTrainerApp/Dialogs/AdminInfoDialog";
import searchIcon from "../../img/searchIcon.svg";
import { mergeRecords } from "../../util/CourseUtilFunctions";
import { localStorageKeys } from "../../constants/storage-keys";
import { setLocalStorageCourseListActive } from "../../util/StorageUtil";

const AdminTrainerAppPage: React.FC = () => {
  const checkLocalStorageCourseListActive = () => {
    const isCourseListActive = localStorage.getItem(
      localStorageKeys.adminPortalIsCourseListActive
    );
    return isCourseListActive === "true" || isCourseListActive === null;
  };

  const [isSyncLoading, setIsSyncLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const [isCourseListActive, setIsCourseListActive] = useState(
    checkLocalStorageCourseListActive()
  );

  const [filters, setFilters] = useState<CourseFilters>({});
  const [areFiltersEmpty, setAreFiltersEmpty] = useState(true);
  const [isFilterResultEmpty, setIsFilterResultEmpty] = useState(false);

  const [counter, setCounter] = useState(0);

  const [adminCourses, setAdminCourses] = useState<Record<string, Course[]>>(
    {}
  );

  const [adminArchiveCourses, setAdminArchiveCourses] = useState<
    Record<string, Course[]>
  >({});

  const [courseOffset, setCourseOffset] = useState(0);

  const mergeAndSetCourses = (
    prevCourses: Record<string, Course[]>,
    responseCourses: Record<string, Course[]>
  ) => {
    const mergedCourses: Record<string, Course[]> = {
      ...prevCourses,
    };

    // Iterate through keys in adminCoursesResponse
    Object.keys(responseCourses).forEach((key) => {
      if (mergedCourses.hasOwnProperty(key)) {
        // Check for duplicates by courseID
        mergedCourses[key] = mergedCourses[key].concat(
          responseCourses[key].filter((newCourse: Course) => {
            // Only add new courses with unique courseIDs
            return !mergedCourses[key].some(
              (existingCourse: Course) =>
                existingCourse.courseID === newCourse.courseID
            );
          })
        );
      } else {
        // If the key doesn't exist, create it
        mergedCourses[key] = responseCourses[key];
      }
    });

    return mergedCourses;
  };

  async function getAllCoursesForAdmin(offset: number) {
    try {
      setIsLoading(true);
      const [adminCoursesResponse, archiveCoursesResponse] = await Promise.all([
        getAdminCourses(offset),
        getAdminArchiveCourses(offset),
      ]);

      setAdminCourses((prevAdminCourses) => {
        return mergeAndSetCourses(prevAdminCourses, adminCoursesResponse);
      });

      setAdminArchiveCourses((prevAdminArchiveCourse) => {
        return mergeAndSetCourses(
          prevAdminArchiveCourse,
          archiveCoursesResponse.courses
        );
      });
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching courses:", error);
      setTimeout(() => {
        setIsLoading(false);
        setShowContent(true);
      }, TIMEOUT_MILLIS);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
        setShowContent(true);
      }, TIMEOUT_MILLIS);
    }
  }

  const handleExecuteFilters = async (offset: number, showDialog: boolean) => {
    if (areFiltersEmpty) {
      return;
    }

    setIsLoading(true);
    const filterCoursesResponse: Record<string, Course[]> = await filterCourses(
      filters,
      !isCourseListActive,
      offset
    );
    if (Object.keys(filterCoursesResponse).length === 0) {
      setIsLoading(false);
      if (showDialog) {
        setIsFilterResultEmpty(true);
      }
      return;
    }
    if (isCourseListActive) {
      if (offset !== 0) {
        setAdminCourses(mergeRecords(adminCourses, filterCoursesResponse));
      } else {
        setAdminCourses(filterCoursesResponse);
      }
    } else {
      if (offset !== 0) {
        setAdminArchiveCourses((prevFilteredCourses) => {
          return { ...prevFilteredCourses, ...filterCoursesResponse };
        });
      } else {
        setAdminArchiveCourses(filterCoursesResponse);
      }
    }
    setIsLoading(false);
    setShowContent(true);
  };

  const deleteCourseFromState = (
    prevState: Record<string, Course[]>,
    idOfCourse: string
  ) => {
    const nextState = { ...prevState };

    const index = Object.keys(nextState).find((key) =>
      nextState[key].some((course: Course) => course.id === idOfCourse)
    );

    if (index !== undefined) {
      nextState[index] = nextState[index].filter(
        (course: Course) => course.id !== idOfCourse
      );
    }

    return nextState;
  };

  const handleDeleteCourse = (idOfCourse: string, isCourseList: boolean) => {
    if (isCourseList) {
      setAdminCourses((prevState: Record<string, Course[]>) => {
        return deleteCourseFromState(prevState, idOfCourse);
      });
    } else {
      setAdminArchiveCourses((prevState: Record<string, Course[]>) => {
        return deleteCourseFromState(prevState, idOfCourse);
      });
    }
  };

  const loadMoreCourses = async () => {
    const newOffset = courseOffset + 50;
    setCourseOffset(newOffset);
    if (areFiltersEmpty) {
      await getAllCoursesForAdmin(newOffset);
    } else {
      await handleExecuteFilters(newOffset, false);
    }
  };

  const loadAllCourses = async () => {
    const offsetZero = 0;
    setAdminCourses({});
    setAdminArchiveCourses({});
    setCourseOffset(offsetZero);
    setIsLoading(true);
    setShowContent(false);
    await getAllCoursesForAdmin(offsetZero);
  };

  /* eslint-disable */
  useEffect(() => {
    loadAllCourses();
    getCounter().then((data) => setCounter(data));
  }, []);
  /* eslint-enable */

  return (
    <>
      <GeneralDocuPage
        isLoading={isLoading}
        children={
          <>
            <ThemeProvider theme={customTheme}>
              <AdminHeader
                setIsCourseListActive={(isCourseListActive: boolean) => {
                  setIsCourseListActive(isCourseListActive);
                }}
              />
              <Box
                sx={{
                  width: "90%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "50px",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <Switcher
                  isCourseListActive={isCourseListActive}
                  onChange={(isCourseListActive: boolean) => {
                    setIsCourseListActive(isCourseListActive);
                    setLocalStorageCourseListActive(isCourseListActive);
                    setCourseOffset(0);
                  }}
                />
                <AdminPortalFilter
                  onExecuteFilters={async () => {
                    const zeroOffset = 0;
                    setCourseOffset(zeroOffset);
                    if (areFiltersEmpty) {
                      await loadAllCourses();
                    } else {
                      await handleExecuteFilters(zeroOffset, true);
                    }
                  }}
                  onChangeFilters={(
                    filters: CourseFilters,
                    areFiltersEmpty: boolean
                  ) => {
                    setFilters(filters);
                    setAreFiltersEmpty(areFiltersEmpty);
                  }}
                  onClickDataSync={async () => {
                    setIsSyncLoading(true);
                    await syncSmartplanData().then(() => {
                      setIsSyncLoading(false);
                    });
                    setCounter(await getCounter());
                  }}
                  stopLoading={() => {
                    setIsLoading(false);
                    setShowContent(true);
                  }}
                  areFiltersEmpty={areFiltersEmpty}
                  loadAllCourses={loadAllCourses}
                  counter={counter}
                  isCourseListActive={isCourseListActive}
                  isSyncLoading={isSyncLoading}
                />
                {showContent && (
                  <>
                    {isCourseListActive ? (
                      <AdminCourseList
                        courses={adminCourses}
                        reloadCourses={async () => {
                          await loadAllCourses();
                        }}
                        loadMoreCourses={async () => {
                          await loadMoreCourses();
                        }}
                        handleDeleteCourse={(idOfCourse: string) => {
                          handleDeleteCourse(idOfCourse, true);
                        }}
                      />
                    ) : (
                      <AdminArchiveList
                        courses={adminArchiveCourses}
                        loadMoreCourses={async () => {
                          await loadMoreCourses();
                        }}
                        handleDeleteCourse={(idOfCourse: string) => {
                          handleDeleteCourse(idOfCourse, false);
                        }}
                      />
                    )}
                    <Box height={"40px"}></Box>
                  </>
                )}
              </Box>
              <AdminInfoDialog
                isOpen={isFilterResultEmpty}
                onCloseDialog={() => {
                  setIsFilterResultEmpty(false);
                }}
                iconURL={searchIcon}
                textChildren={<>Keine Ergebnisse gefunden.</>}
                rightBtnLabel="Zurück"
                rightBtnOnClick={() => {
                  setIsFilterResultEmpty(false);
                }}
              ></AdminInfoDialog>
            </ThemeProvider>
          </>
        }
      ></GeneralDocuPage>
    </>
  );
};

export default AdminTrainerAppPage;
