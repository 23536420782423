import { Box, Input, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ValidatorFunction } from "../../../../models/admin-management-entities";

const AdminManagementTextInputField: React.FC<{
  label?: string;
  text?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
  initialData?: string;
  validatorFct?: ValidatorFunction;
  onSubmitCheck: boolean;
  isRequired?: boolean;
}> = ({
  label,
  text,
  onChange,
  isDisabled = false,
  initialData,
  validatorFct,
  onSubmitCheck,
  isRequired,
}) => {
  const [borderColor, setBorderColor] = useState("2px solid var(--green)");

  const handleValidation = async (value: string) => {
    if (validatorFct) {
      try {
        const valid = await Promise.resolve(validatorFct(value, isRequired));
        if (valid) {
          setBorderColor(
            !(initialData === "undefined" && value === "") &&
              initialData !== value
              ? "2px solid var(--orange)"
              : "2px solid var(--green)"
          );
        } else {
          setBorderColor("2px solid var(--red)");
        }
      } catch (error) {
        setBorderColor("2px solid var(--red)");
      }
    }
  };

  /* eslint-disable */
  useEffect(() => {
    setBorderColor("2px solid var(--green)");
  }, [initialData]);

  useEffect(() => {
    onSubmitCheck && handleValidation(text || "");
  }, [onSubmitCheck]);
  /* eslint-enable */

  return (
    <Box
      sx={{
        border: borderColor,
        borderRadius: "var(--borderRadiusLarge)",
      }}
    >
      <Typography
        sx={{
          fontSize: "var(--h3)",
          padding: "10px 0 0 10px",
          color: "var(--grey)",
        }}
      >
        {label}
      </Typography>
      <Input
        sx={{
          width: "100%",
          padding: "10px 0 0 10px",
          border: "none",
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "var(--black)",
          },
          "&.Mui-disabled": {
            "&::before": {
              borderBottom: "none !important",
            },
            "&::after": {
              borderBottom: "none !important",
            },
          },
          "&::before": {
            borderBottom: "none",
          },
          "&::after": {
            borderBottom: "none",
          },
          "&:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
          },
        }}
        type="text"
        value={text || ""}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e);
          if (label !== "Kontonummer*") {
            handleValidation(e.target.value);
          }
        }}
        onFocus={() => setBorderColor("2px solid var(--grey)")}
        onBlur={() => {
          const color =
            !(initialData === "undefined" && text === "") &&
            initialData !== text
              ? "2px solid var(--orange)"
              : "2px solid var(--green)";
          setBorderColor(color);
          handleValidation(text || "");
        }}
        disabled={isDisabled}
      />
    </Box>
  );
};

export default AdminManagementTextInputField;
