import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";
import info from "../../img/info.svg";

export const DashboardCard: React.FC<{
  title: string;
  children?: ReactNode;
  gridRow: string;
  gridColumn: string;
  alignItems?: "center" | "left";
  isLoading?: boolean;
  infoDialog?: string;
  onClickOnCard?: (e: any) => void;
}> = ({
  title,
  children,
  gridRow,
  gridColumn,
  alignItems = "center",
  isLoading,
  infoDialog,
  onClickOnCard,
}) => {
  return (
    <Grid
      item
      xs
      sx={{
        gridRow: gridRow,
        gridColumn: gridColumn,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Card
        variant="outlined"
        sx={{
          width: "100%",
          height: "100%",
          boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
          transition: "0.3s",
          minHeight: "205px",
          position: "relative",
        }}
        onClick={onClickOnCard}
      >
        <Tooltip title={infoDialog} placement="bottom">
          <img
            src={info}
            alt="info"
            style={{
              width: "22px",
              height: "22px",
              position: "absolute",
              right: 8,
              top: 8,
            }}
          />
        </Tooltip>
        <CardContent sx={{ height: "80%" }} style={{ paddingBottom: "8px" }}>
          <Typography
            variant="body2"
            sx={{
              color: "#607783",
              textTransform: "uppercase",
              fontWeight: "bold",
              textAlign: "left",
              fontSize: "0.7rem",
              marginBottom: "10px",
              width: "100%",
            }}
          >
            {title}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: alignItems,
              height: "100%",
              textAlign: "center",
            }}
          >
            {isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <CircularProgress sx={{ color: "#C1C9CD" }} />
              </Box>
            ) : (
              children
            )}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};
