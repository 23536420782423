import { Box } from "@mui/material";
import { Course } from "../../models/course";
import "../../css/globalVariables.css";
import AdminArchiveItemBoxContent from "./AdminArchiveItemBoxContent";
import AdminArchiveItemBoxHeader from "./AdminArchiveItemBoxHeader";

const AdminArchiveItemBox: React.FC<{
  course: Course;
  isFirstCourse: boolean;
  isLastCourse: boolean;
  navigate: (left: boolean) => Promise<void>;
}> = ({ course, isFirstCourse, isLastCourse, navigate }) => {
  return (
    <>
      <Box
        sx={{
          width: "90%",
          border: "var(--border)",
          borderRadius: "var(--borderRadiusLarge)",
          display: "flex",
          flexDirection: "column",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <AdminArchiveItemBoxHeader
          course={course}
          navigate={navigate}
          isFirstCourse={isFirstCourse}
          isLastCourse={isLastCourse}
        />
        <AdminArchiveItemBoxContent course={course} />
      </Box>
    </>
  );
};

export default AdminArchiveItemBox;
