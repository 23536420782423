import React, { useState } from "react";
import {
  AdminManagementDialogKey,
  InputData,
  Warteliste,
  WartelistenKundenData,
} from "../../../models/admin-management-entities";
import {
  AdminManagementDialogTypes,
  AdminManagementDialog,
} from "./AdminManagementDialog";
import { Box, Typography } from "@mui/material";
import { AdminManagmentList } from "../AdminManagementList";
import { dateToString } from "../../../util/DateFormatHelper";
import { AdminManagementWartelisteKundeDialog } from "./AdminManagementWartelisteKundeDialog";

export const AdminManagmentWartelisteDialog: React.FC<{
  warteliste: Warteliste;
  isOpen: boolean;
  closeDialog: () => void;
}> = ({ warteliste, isOpen, closeDialog }) => {
  const [wartelisteKunde, setWartelisteKunde] =
    useState<WartelistenKundenData>();

  if (!warteliste) {
    return null;
  }

  const sortedWarteliste = warteliste.WartelistenKundenData.sort((a, b) => {
    return new Date(a.created).getTime() - new Date(b.created).getTime();
  });

  const wartelistenInputData: InputData = {
    Kursinformationen: {
      Kursname: {
        attribute: "Kursname",
        data: warteliste.Kursname,
        type: AdminManagementDialogTypes.DISABLED,
      },
      Kursbezeichnung: {
        attribute: "Kurzbezeichnung",
        data: warteliste.Kurzbezeichnung,
        type: AdminManagementDialogTypes.DISABLED,
      },
      Kapazität: {
        attribute: "MaxTeilnehmer",
        data: warteliste.MaxTeilnehmer,
        type: AdminManagementDialogTypes.DISABLED,
      },
      Wartelisteneinträge: {
        attribute: "",
        data: warteliste.WartelistenKundenData.length.toString(),
        type: AdminManagementDialogTypes.DISABLED,
      },
    },
  };

  return (
    <>
      <AdminManagementDialog
        dialogKey={AdminManagementDialogKey.WARTELISTE}
        title={warteliste ? `${warteliste.Kursname}` : ""}
        isOpen={isOpen}
        closeDialog={closeDialog}
        entity={warteliste}
        entityId={warteliste.ID}
        inputData={wartelistenInputData}
        initialData={warteliste}
        children={
          <>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{
                  color: "#607783",
                  fontSize: "12px",
                  padding: "16px 0 0 16px",
                  fontWeight: "900",
                }}
              >
                Warteliste
              </Typography>
            </Box>
            <AdminManagmentList
              dialogKey={AdminManagementDialogKey.WARTELISTE}
              isLoading={false}
              data={sortedWarteliste}
              columns={[
                { title: "Vorname" },
                { title: "Nachname" },
                { title: "Geburtsdatum" },
                { title: "E-Mail" },
                { title: "Datum Eintrag" },
                { title: "Datum Angebot" },
                { title: "Version" },
              ]}
              listItemColumns={[
                { attrName: "Vorname" },
                { attrName: "Nachname" },
                {
                  attrName: "Geburtsdatum",
                  processFnct: (_: Date) => {
                    return dateToString(_);
                  },
                },
                { attrName: "Emailadresse" },
                {
                  attrName: "created",
                  processFnct: (_: Date) => {
                    return dateToString(_);
                  },
                },
                {
                  attrName: "issued",
                  processFnct: (_: Date) => {
                    return dateToString(_);
                  },
                },
                { attrName: "version" },
              ]}
              listItemOnClick={(ID: number) => {
                setWartelisteKunde(
                  warteliste.WartelistenKundenData &&
                    warteliste.WartelistenKundenData.find(
                      (kunde) => kunde.ID === ID
                    )
                );
              }}
              noDataText={
                "Es konnten keine Kunden zu dieser Warteliste gefunden werden."
              }
            />
          </>
        }
      ></AdminManagementDialog>
      <AdminManagementWartelisteKundeDialog
        wartelisteKunde={wartelisteKunde}
        isOpen={wartelisteKunde !== undefined}
        closeDialog={() => {
          setWartelisteKunde(undefined);
        }}
      />
    </>
  );
};
