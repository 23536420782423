import { Box, Dialog, Typography } from "@mui/material";
import xGrey from "../../../img/xGrey.png";
import { AdminManagementButton } from "../../AdminManagement/AdminManagementButton";

const AdminInfoDialog: React.FC<{
  isOpen: boolean;
  onCloseDialog: () => void;
  iconURL: string;
  textChildren: React.ReactNode;
  rightBtnLabel: string;
  rightBtnOnClick: () => void;
  children?: React.ReactNode;
  leftBtnLabel?: string;
  leftBtnOnClick?: () => void;
}> = ({
  isOpen,
  onCloseDialog,
  iconURL,
  textChildren,
  children,
  rightBtnLabel,
  rightBtnOnClick,
  leftBtnLabel,
  leftBtnOnClick,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onCloseDialog}
      keepMounted
      sx={{
        "& .MuiDialog-paper": {
          width: "750px",
          height: children ? "750px" : "500px",
          borderRadius: "8px",
          border: "1px solid rgba(96, 119, 131, 0.50)",
          boxShadow: "0px 20px 20px 0px rgba(0, 0, 0, 0.25)",
        },
      }}
    >
      <Box
        sx={{
          height: "100%",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "20px",
        }}
      >
        {/* X Btn */}
        <Box
          onClick={() => {
            onCloseDialog();
          }}
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
          }}
        >
          <img
            alt="close"
            src={xGrey}
            style={{ width: "15px", height: "15px" }}
          />
        </Box>

        {/* ICON */}
        <Box
          sx={{
            position: "absolute",
            top: "55px",
            width: "30px",
            height: "30px",
            border: "2px solid #607783",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <img alt="infoDialogIcon" src={iconURL} style={{ width: "20px" }} />
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: "140px",
          }}
        >
          <Typography
            sx={{
              color: "#607783",
              fontFamily: "Tahoma",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              paddingBottom: "50px",
              textAlign: "center",
            }}
          >
            {textChildren}
          </Typography>

          {children}
        </Box>

        {/* Buttons */}
        <Box
          sx={{
            position: "absolute",
            bottom: "20px",
            right: "20px",
            display: "flex",
            gap: "10px",
          }}
        >
          {leftBtnLabel && (
            <AdminManagementButton
              variant="text"
              text={leftBtnLabel}
              color="var(--green)"
              onClick={leftBtnOnClick}
            ></AdminManagementButton>
          )}
          <AdminManagementButton
            variant="contained"
            text={rightBtnLabel}
            color="var(--green)"
            onClick={rightBtnOnClick}
          ></AdminManagementButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AdminInfoDialog;
