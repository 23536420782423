import { Box, Button, Tooltip, Typography } from "@mui/material";
import "../../css/globalVariables.css";
import { styled } from "@mui/material/styles";
import loadingIcon from "../../img/loadingIcon.svg";

const AdminPortalDataSyncBtn: React.FC<{
  counter: number;
  onClick: () => void;
  isSyncLoading: boolean;
}> = ({ counter, onClick, isSyncLoading }) => {
  const MAX_COUNTER = 10;

  const getTimeoutMessageForDatasync = () => {
    const minutes = 60 - new Date().getMinutes();
    const hours = 24 - new Date().getHours();
    if (hours === 0) {
      return (
        "Die Sync-Funktion ist in " + minutes + " Minuten wieder verfügbar"
      );
    } else {
      return "Die Sync-Funktion ist in " + hours + " Stunden wieder verfügbar";
    }
  };

  interface RotatingIconProps {
    isSyncLoading: boolean;
  }

  const RotatingIcon = styled("img")<RotatingIconProps>(
    ({ isSyncLoading }) => ({
      marginRight: "19px",
      animation: isSyncLoading ? "spin 2s linear infinite" : "none",
      "@keyframes spin": {
        from: { transform: "rotate(0deg)" },
        to: { transform: "rotate(360deg)" },
      },
    })
  );

  return (
    <Box
      sx={{ flex: 1, display: "flex", flexDirection: "row", margin: " 15px" }}
    >
      {}
      <Tooltip
        title={counter >= MAX_COUNTER ? getTimeoutMessageForDatasync() : null}
        arrow
      >
        <Button
          sx={{
            flex: 2,
            backgroundColor: "var(--orange)",
            height: "30px",
            marginRight: "15px",
            color: "white",
            fontWeight: "bold",
            fontSize: "var(--h4)",
            padding: "0",
            opacity: counter >= MAX_COUNTER ? "0.5" : "",
            "&:hover": {
              backgroundColor: "var(--orange)",
              opacity: 0.25,
              transition: "opacity 0.3s",
            },
          }}
          onClick={counter >= MAX_COUNTER ? null : onClick}
        >
          <RotatingIcon
            alt="loading"
            src={loadingIcon}
            isSyncLoading={isSyncLoading}
          />
          {"Daten-Sync"}
        </Button>
      </Tooltip>
      <Box sx={{ flex: 1, display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            flex: 7,
            height: "30px",
            backgroundColor: "var(--orange)",
            borderRadius:
              "var(--borderRadiusLarge) 0 0 var(--borderRadiusLarge)",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: "var(--h4)",
              fontWeight: "bold",
              margin: "5px",
            }}
          >
            {counter}
          </Typography>
        </Box>
        <Box
          sx={{
            flex: 1,
            height: "30px",
            backgroundColor: "var(--grey)",
          }}
        />
        <Box
          sx={{
            flex: 7,
            height: "30px",
            backgroundColor: "var(--green)",
            borderRadius:
              "0 var(--borderRadiusLarge) var(--borderRadiusLarge) 0 ",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: "var(--h4)",
              fontWeight: "bold",
              margin: "5px",
            }}
          >
            {MAX_COUNTER - counter}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AdminPortalDataSyncBtn;
