import { Course } from "../models/course";

export const getDeleteDialogTextContent = (course: Course) => {
  return (
    <>
      Möchtest du den Kurs <b>{course.courseName}</b> am{" "}
      <b>{course.startDate}</b> <br /> wirklich unwiderruflich löschen ?
    </>
  );
};
