import {
  AbrechenbareMitglieder,
  AktiveAnmeldungen,
  Anwesenheit,
  AnwesenheitEffektiv,
  Auslastung,
  AuslastungEffektiv,
  Kuendigung,
  StandortAuslastung,
} from "../models/admin-dashboard-entities";
import { getAdminAxiosInstance } from "./api-config";

export async function getAbrechenbareMitgliederForCurrentMonth(): Promise<AbrechenbareMitglieder> {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.get("/verein/abrechenbare-mitglieder");
  return response.data;
}

export async function getAktiveAnmeldungenForCurrentMonth(): Promise<AktiveAnmeldungen> {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.get("/verein/aktive-anmeldungen");
  return response.data;
}

export async function getKuendigungenForTwoMonths(): Promise<Kuendigung> {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.get("/verein/kundigung");
  return response.data;
}

export async function getBottomTenAnwesenheit(): Promise<{
  bottomTen: Record<string, Anwesenheit>;
  all: Record<string, Anwesenheit>;
}> {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.get("/verein/bottom-anwesenheit");
  return { bottomTen: response.data.bottomTen, all: response.data.all };
}

export async function getBottomTenAuslastung(): Promise<{
  bottomTen: Record<string, Auslastung>;
  all: Record<string, Auslastung>;
}> {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.get("/verein/bottom-auslastung");
  return { bottomTen: response.data.bottomTen, all: response.data.all };
}

export async function getAnwesenheitEffektiv(): Promise<AnwesenheitEffektiv> {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.get("/verein/anwesenheit-effektiv");
  return response.data;
}

export async function getAuslastungEffektiv(): Promise<AuslastungEffektiv> {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.get("/verein/buchungs-auslastung");
  return response.data;
}

export async function getHistorischeWerte(): Promise<{
  abrechenbareMitglieder: number[];
  anwesenheiten: number[];
  buchungen: number[];
  kundigungen: number[];
  neueintritte: number[];
}> {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.get("/verein/hw");
  return response.data;
}

export async function getStandortAuslastung(): Promise<StandortAuslastung> {
  const axiosInstance = getAdminAxiosInstance();
  const response = await axiosInstance.get("/verein/standort-auslastung");
  return response.data;
}
