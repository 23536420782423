import { Box, Typography } from "@mui/material";

const AdminManagementListHeader: React.FC<{
  columns: { title: string; flex?: number; index?: number }[];
  isWithDistance?: boolean;
}> = ({ columns, isWithDistance = false }) => {
  const columnsWithIndex = columns.map(({ title, flex }, index) => {
    if (flex === undefined) {
      flex = 1;
    }
    return { index, title, flex };
  });
  return (
    <>
      <Box
        sx={{
          backgroundColor: "var(--white)",
          paddingTop: "5px",
          width: "100%",
          position: "sticky",
          top: isWithDistance ? "80px" : "0",
          zIndex: "40",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#90A0A8",
            display: "flex",
            flexDirection: "row",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            paddingTop: "15px",
            paddingBottom: "15px",
          }}
        >
          {columnsWithIndex.map(({ index, title, flex }) => (
            <Typography
              noWrap
              key={index}
              sx={{
                flex: flex,
                color: "var(--white)",
                fontWeight: "bold",
                fontSize: "var(--h4)",
                marginLeft: index === 0 ? "50px" : "0",
                overflow: "hidden",
              }}
            >
              {title}
            </Typography>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default AdminManagementListHeader;
