import { Button, Typography } from "@mui/material";
import { ReactNode } from "react";

export const AdminManagementButton: React.FC<{
  color: string;
  text?: string;
  children?: ReactNode;
  onClick: () => void;
  width?: string;
  variant?: "text" | "outlined" | "contained";
}> = ({
  color,
  text,
  children,
  onClick,
  width = "150px",
  variant = "contained",
}) => {
  const isVariantTextOrOutlined: boolean =
    variant === "text" || variant === "outlined";

  return (
    <Button
      color="secondary"
      sx={{
        backgroundColor: isVariantTextOrOutlined ? "var(--white)" : color,
        border: variant === "text" ? "none" : `2px solid ${color}`,
        borderRadius: "5px",
        minWidth: width,
        height: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: isVariantTextOrOutlined ? "var(--white)" : color,
          filter: "brightness(90%)",
          transition: "background-color 0.3s, filter 0.3s",
        },
      }}
      onClick={onClick}
    >
      {children}
      {text && (
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "var(--h2)",
            color: isVariantTextOrOutlined ? color : "var(--white)",
            marginX: "10px",
            textTransform: "uppercase",
          }}
        >
          {text}
        </Typography>
      )}
    </Button>
  );
};
