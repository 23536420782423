import { Box, Typography } from "@mui/material";
import "../../../css/globalVariables.css";
import { Course, CourseStates } from "../../../models/course";
import AdminCourseDay from "./AdminCourseDay";
import InfiniteScroll from "react-infinite-scroll-component";

const AdminArchiveList: React.FC<{
  courses: Record<string, Course[]>;
  loadMoreCourses: () => Promise<void>;
  handleDeleteCourse: (idOfCourse: string) => void;
}> = ({ courses, loadMoreCourses, handleDeleteCourse }) => {
  const columns = [
    { index: 1, name: "Kursname", flex: 3 },
    { index: 2, name: "Standort", flex: 3 },
    { index: 3, name: "Trainer", flex: 3 },
    { index: 4, name: "Start-/Endzeit", flex: 3 },
    { index: 5, name: "Teilnehmende", flex: 3 },
    { index: 6, name: "Kapazität", flex: 2 },
    { index: 7, name: "Nachricht", flex: 2 },
    { index: 8, name: "Halle", flex: 2 },
    { index: 9, name: "Material", flex: 2 },
    { index: 10, name: "Vorkommnisse", flex: 2 },
    { index: 11, name: "", flex: 1 },
  ];

  function parseDate(dateString: string) {
    const [day, month, year] = dateString.split(".").map(Number);
    return new Date(year, month - 1, day);
  }

  const courseDates = Object.keys(courses);

  courseDates.sort((a, b) => parseDate(b).getTime() - parseDate(a).getTime());

  const getNavigationCourses = () => {
    let foundCourses: Course[] = [];
    courseDates.forEach((key) => {
      foundCourses = foundCourses.concat(
        courses[key].filter(
          (course: Course) =>
            course.state === CourseStates.CHECK_OUT_COMPLETE ||
            course.state === CourseStates.CANCELED ||
            (course.state === CourseStates.UNDOCUMENTED && course.message)
        )
      );
    });
    return foundCourses;
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#607783",
          display: "flex",
          flexDirection: "row",
          marginTop: "20px",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          paddingTop: "15px",
          paddingBottom: "15px",
        }}
      >
        {columns.map((col) => (
          <Typography
            noWrap
            key={col.index}
            sx={{
              flex: col.flex,
              color: "white",
              fontWeight: "bold",
              fontSize: "var(--h4)",
              textAlign: "center",
              overflow: "hidden",
            }}
          >
            {col.name}
          </Typography>
        ))}
      </Box>
      {courses ? (
        <InfiniteScroll
          dataLength={Object.keys(courses).length}
          next={loadMoreCourses}
          hasMore={true}
          loader={<></>}
        >
          {courseDates.map((key) => (
            <AdminCourseDay
              key={key}
              date={key}
              navigationCourses={getNavigationCourses()}
              courses={courses[key]}
              isCourseList={false}
              handleDeleteCourse={handleDeleteCourse}
              loadMoreCourses={loadMoreCourses}
            ></AdminCourseDay>
          ))}
        </InfiniteScroll>
      ) : (
        <Typography
          sx={{
            marginTop: "50px",
            textAlign: "center",
            fontSize: "var(--h1)",
            color: "var(--grey)",
          }}
        >
          {"Keine Kurse gefunden."}
        </Typography>
      )}
    </>
  );
};

export default AdminArchiveList;
