import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import React from "react";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";

const AdminPortalBooleanFilter: React.FC<{
  label: string;
  selectedValue: boolean | undefined;
  onChange: (value: boolean | undefined) => void;
}> = ({ label, selectedValue, onChange }) => {
  const trueBtnColor = "#44AEB0";

  const handleClick = (value: boolean) => {
    if (selectedValue === value) {
      onChange(undefined);
    } else {
      onChange(value);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        margin: "5px",
      }}
    >
      <Typography
        sx={{
          color: "var(--grey)",
          fontSize: "var(--h4)",
          margin: "auto 5px auto 0",
          width: "30%",
        }}
      >
        {label}
      </Typography>
      <ButtonGroup
        sx={{ width: "70%" }}
        variant="outlined"
        aria-label={`${label}-buttons`}
      >
        <Button
          onClick={() => handleClick(true)}
          sx={{
            height: "30px",
            width: "50%",
            backgroundColor: selectedValue ? trueBtnColor : "white",
            borderColor: selectedValue ? trueBtnColor : "var(--lightGrey)",
            "&:hover": {
              backgroundColor: selectedValue ? trueBtnColor : "white",
              borderColor: trueBtnColor,
              opacity: 0.5,
              transition: "opacity 0.3s",
            },
          }}
        >
          <CheckRoundedIcon
            sx={{
              color: selectedValue ? "white" : "var(--lightGrey)",
            }}
          />
        </Button>
        <Button
          onClick={() => handleClick(false)}
          sx={{
            height: "30px",
            width: "50%",
            backgroundColor:
              selectedValue === false ? "var(--orange)" : "white",
            borderColor:
              selectedValue === false ? "var(--orange)" : "var(--lightGrey)",
            "&:hover": {
              backgroundColor:
                selectedValue === false ? "var(--orange)" : "white",
              opacity: 0.5,
              transition: "opacity 0.3s",
            },
          }}
        >
          <PriorityHighRoundedIcon
            sx={{
              color: selectedValue === false ? "white" : "var(--lightGrey)",
            }}
          />
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default AdminPortalBooleanFilter;
