import { Typography } from "@mui/material";
import "../../css/globalVariables.css";

const AdminArchiveItemCourseMsg: React.FC<{
  msg: string;
}> = ({ msg }) => {
  return (
    <>
      <Typography
        sx={{
          fontSize: "var(--h3)",
          color: "var(--grey)",
          fontWeight: "bold",
          margin: "15px",
        }}
      >
        {"Benachrichtigung"}
      </Typography>
      <Typography
        sx={{
          padding: "15px",
          border: "var(--border)",
          borderRadius: "var(--borderRadiusLarge)",
          color: "var(--grey)",
        }}
      >
        {msg}
      </Typography>
    </>
  );
};

export default AdminArchiveItemCourseMsg;
