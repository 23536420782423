import React, { useState } from "react";
import { AdminManagementDialogTypes } from "./AdminManagementDialog";
import AdminManagementTextInputField from "./InputFields/AdminManagementTextInputField";
import { AdminManagementDateInputField } from "./InputFields/AdminManagementDateInputField";
import { AdminManagementBooleanInputField } from "./InputFields/AdminManagementBooleanInputField";
import AdminManagementSelector from "./InputFields/AdminManagementSelector";
import {
  BelegbareKurseResponse,
  IdAndDataType,
  ValidatorFunction,
} from "../../../models/admin-management-entities";
import { AdminManagementKursSelector } from "./InputFields/AdminManagementKursSelector";
import { AdminManagementTimeInputField } from "./InputFields/AdminManagementTimeInputField";
import { convertDateString } from "../../../util/DateFormatHelper";
import { checkIfDateValid } from "../../../util/RegexFunctions";

const weekdays: IdAndDataType[] = [
  { ID: 1, data: "Montag" },
  { ID: 2, data: "Dienstag" },
  { ID: 3, data: "Mittwoch" },
  { ID: 4, data: "Donnerstag" },
  { ID: 5, data: "Freitag" },
  { ID: 6, data: "Samstag" },
  { ID: 7, data: "Sonntag" },
];

const AdminManagmentDialogTypeContent: React.FC<{
  dialogType: AdminManagementDialogTypes;
  categoryKey: string;
  label: string;
  text: string;
  attribute?: string;
  validatorFct?: ValidatorFunction;
  isRequired?: boolean;
  handleChange: Function;
  initialData?: any;
  onSubmitCheck?: boolean;
  parentEntityId?: number;
  allStandortNamen?: Array<{ ID: number; data: string }>;
  setSelectedStandort: Function;
  selectedStandort?: string;
  allBelegbareKurse?: BelegbareKurseResponse;
  setChangeBelegungKursSelection?: (_: number) => void;
  setSelectedMitgliedschaftsbeginn?: (_: Date) => void;
  selectedMitgliedschaftsbeginn?: Date;
  changeBelegungKursIdSelection?: number;
  bereichValues?: string[];
  kurzBezeichnungValues?: string[];
}> = ({
  dialogType,
  categoryKey,
  label,
  text,
  attribute,
  validatorFct,
  isRequired,
  handleChange,
  initialData,
  onSubmitCheck,
  parentEntityId,
  allStandortNamen,
  setSelectedStandort,
  selectedStandort,
  allBelegbareKurse,
  setChangeBelegungKursSelection,
  setSelectedMitgliedschaftsbeginn,
  selectedMitgliedschaftsbeginn,
  changeBelegungKursIdSelection,
  bereichValues,
  kurzBezeichnungValues,
}) => {
  function stringToBoolean(str: string) {
    if (str.toLowerCase() === "true") {
      return true;
    }
    return false;
  }
  const [selectedWeekday, setSelectedWeekday] = useState<string>();
  const [selectedBereich, setSelectedBereich] = useState<string>();
  const [selectedKurzBezeichnung, setSelectedKurzBezeichnung] =
    useState<string>();
  switch (dialogType) {
    case AdminManagementDialogTypes.TEXT_INPUT:
    case AdminManagementDialogTypes.DISABLED:
      return (
        <AdminManagementTextInputField
          key={label}
          label={label}
          text={text}
          onChange={(e) =>
            handleChange(categoryKey, label, e.target.value, attribute)
          }
          initialData={initialData && String(initialData[attribute])}
          isDisabled={dialogType === AdminManagementDialogTypes.DISABLED}
          validatorFct={validatorFct}
          onSubmitCheck={onSubmitCheck}
          isRequired={isRequired}
        />
      );
    case AdminManagementDialogTypes.DATE_INPUT:
      return (
        <AdminManagementDateInputField
          key={label}
          label={label}
          value={text}
          onChange={(inputDate: string) => {
            if (checkIfDateValid(inputDate)) {
              handleChange(
                categoryKey,
                label,
                inputDate,
                attribute,
                convertDateString
              );
            }
          }}
          initialData={initialData}
        />
      );
    case AdminManagementDialogTypes.BOOLEAN_INPUT:
      return (
        <AdminManagementBooleanInputField
          key={label}
          label={label}
          text={text}
          onChange={(e) =>
            handleChange(categoryKey, label, e, attribute, stringToBoolean)
          }
          initialData={initialData}
          onSubmitCheck={onSubmitCheck}
          isRequired={isRequired}
          attribute={attribute}
        />
      );
    case AdminManagementDialogTypes.STANDORT_SELECTOR:
      const standortName = allStandortNamen?.find(
        (item) => item.ID === Number(parentEntityId || text)
      );
      return (
        <AdminManagementSelector
          key={label}
          label={label}
          allValues={allStandortNamen}
          defaultValue={standortName ? standortName.data : ""}
          selectedValue={selectedStandort}
          onSelectionChange={(newSelectedName: string) => {
            setSelectedStandort(newSelectedName);
            const selectedItem = allStandortNamen?.find(
              (item) => item.data === newSelectedName
            );
            handleChange(
              categoryKey,
              label,
              selectedItem?.ID,
              attribute,
              function (itemID: number) {
                return itemID;
              }
            );
          }}
          initialData={initialData}
        />
      );
    case AdminManagementDialogTypes.KURS_SELECTOR:
      return (
        <AdminManagementKursSelector
          selectedMitgliedschaftsbeginn={selectedMitgliedschaftsbeginn}
          belegbareKurse={allBelegbareKurse}
          setChangeBelegungKursSelection={setChangeBelegungKursSelection}
          onSubmitCheck={onSubmitCheck}
          isRequired={isRequired}
          setSelectedMitgliedschaftsbeginn={setSelectedMitgliedschaftsbeginn}
          changeBelegungKursIdSelection={changeBelegungKursIdSelection}
        ></AdminManagementKursSelector>
      );
    case AdminManagementDialogTypes.TIME_INPUT:
      return (
        <AdminManagementTimeInputField
          key={label}
          label={label}
          value={text}
          setInputValue={(e) => handleChange(categoryKey, label, e, attribute)}
          initialData={initialData}
        ></AdminManagementTimeInputField>
      );
    case AdminManagementDialogTypes.WEEKDAY_SELECTOR:
      return (
        <AdminManagementSelector
          key={label}
          label={label}
          allValues={weekdays}
          defaultValue={text ? text : ""}
          selectedValue={selectedWeekday}
          onSelectionChange={(newSelectedDay: string) => {
            setSelectedWeekday(newSelectedDay);
            handleChange(categoryKey, label, newSelectedDay, attribute);
          }}
          initialData={initialData}
          attribute={attribute}
          onSubmitCheck={onSubmitCheck}
        />
      );
    case AdminManagementDialogTypes.BEREICH_SELECTOR:
      return (
        <AdminManagementSelector
          key={label}
          label={label}
          allValues={bereichValues.map((item, index) => ({
            ID: index,
            data: item,
          }))}
          defaultValue={text ? text : ""}
          selectedValue={selectedBereich}
          onSelectionChange={(newSelectedBereich: string) => {
            setSelectedBereich(newSelectedBereich);
            handleChange(categoryKey, label, newSelectedBereich, attribute);
          }}
          initialData={initialData}
          attribute={attribute}
          isRequired={true}
          onSubmitCheck={onSubmitCheck}
        />
      );
    case AdminManagementDialogTypes.KURZBEZEICHNUNG_SELECTOR:
      return (
        <AdminManagementSelector
          key={label}
          label={label}
          allValues={kurzBezeichnungValues.map((item, index) => ({
            ID: index,
            data: item,
          }))}
          defaultValue={text ? text : ""}
          selectedValue={selectedKurzBezeichnung}
          onSelectionChange={(newKurzBezeichnung: string) => {
            setSelectedKurzBezeichnung(newKurzBezeichnung);
            handleChange(categoryKey, label, newKurzBezeichnung, attribute);
          }}
          initialData={initialData}
          attribute={attribute}
          isRequired={true}
          onSubmitCheck={onSubmitCheck}
        />
      );
    default:
      return null;
  }
};

export default AdminManagmentDialogTypeContent;
