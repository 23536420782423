export enum allRoutes {
  default = "/",
  auth = "/auth",
  profileDetails = "/profilDetail",
  notification = "/benachrichtigung",
  forgotPassword = "/passwort-vergessen",
  changePassword = "/passwort-aendern",
  notifyAttendees = "/notifyAttendees",
  cancelDocu = "/cancel-docu",
  adminTrainerApp = "/admin",
  adminDashboard = "/dashboard",
  adminManagement = "/verwaltung",
}
