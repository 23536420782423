import React from "react";
import { Box, Typography } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { ChartsLegend } from "./ChartsLegend";

ChartJS.register(ArcElement, Tooltip, Legend);

const DashboardCircleChart: React.FC<{
  total: number | string;
  coloredValue: number;
  grayValue: number;
  coloredValueTitle?: string;
  grayValueTitle?: string;
  coloredValueBgColor?: string;
  grayValueBgColor?: string;
  isPercentage?: boolean;
}> = ({
  total,
  coloredValue,
  grayValue,
  coloredValueTitle,
  grayValueTitle,
  coloredValueBgColor = "#44AEB0",
  grayValueBgColor = "#C1C9CD",
  isPercentage = false,
}) => {
  const data = {
    datasets: [
      {
        data: [
          coloredValue,
          isPercentage ? grayValue - coloredValue : grayValue,
        ],
        backgroundColor: [coloredValueBgColor, grayValueBgColor],
        borderColor: [coloredValueBgColor, grayValueBgColor],
        borderWidth: 0.25,
      },
    ],
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          width: 120,
          height: 120,
          display: "inline-flex",
        }}
      >
        <Doughnut
          data={data}
          options={{
            plugins: {
              legend: {
                display: false,
                labels: {
                  color: "rgb(255, 99, 132)",
                },
              },
              tooltip: {
                callbacks: {
                  label: function (tooltipItem: any) {
                    const value =
                      tooltipItem.parsed === grayValue - coloredValue
                        ? grayValue
                        : tooltipItem.parsed;
                    return `${value}`;
                  },
                },
              },
            },
            cutout: "83%",
          }}
        />
        <Typography
          variant="h4"
          component="div"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -35%)",
            color: "#607783",
            fontSize: "24px",
          }}
        >
          {total}
        </Typography>
      </Box>
      {coloredValueTitle && grayValueTitle && (
        <ChartsLegend
          coloredValueTitle={`${coloredValue} ${coloredValueTitle}`}
          grayValueTitle={`${grayValue} ${grayValueTitle}`}
          coloredValueBgColor={coloredValueBgColor}
          grayValueBgColor={grayValueBgColor}
        />
      )}
    </>
  );
};

export default DashboardCircleChart;
