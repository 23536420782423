import { Box, Dialog, Typography } from "@mui/material";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import backhIcon from "../../../img/arrowLeftActive.png";
import save from "../../../img/save.svg";
import editPan from "../../../img/editPenSVG.svg";
import plusSquared from "../../../img/plus-squared.svg";
import AdminInfoDialog from "../../AdminTrainerApp/Dialogs/AdminInfoDialog";
import {
  AdminManagementDialogKey,
  BelegbareKurseResponse,
  IdAndDataType,
  InputData,
  Kurs,
  Mitglied,
  Standort,
  Warteliste,
  WartelistenKundenData,
} from "../../../models/admin-management-entities";
import { SnackBarContext } from "../../../context/snackbar-context";
import { AdminManagementButton } from "../AdminManagementButton";
import AdminManagmentDialogTypeContent from "./AdminManagementDialogTypeContent";
import AdminPortalHeader from "../../AdminHeader/AdminPortalHeader";

export enum AdminManagementDialogTypes {
  TEXT_INPUT,
  BOOLEAN_INPUT,
  DATE_INPUT,
  DISABLED,
  INVISIBLE,
  STANDORT_SELECTOR,
  KURS_SELECTOR,
  TIME_INPUT,
  WEEKDAY_SELECTOR,
  BEREICH_SELECTOR,
  KURZBEZEICHNUNG_SELECTOR,
}

export const AdminManagementDialog: React.FC<{
  dialogKey: AdminManagementDialogKey;
  title: string;
  isOpen: boolean;
  closeDialog: () => void;
  entity?: Mitglied | Standort | Warteliste | Kurs | WartelistenKundenData;
  setEntity?: (newEntity: any) => void;
  inputData: InputData;
  addFnct?: (data: any) => Promise<void>;
  updateFnct?: (data: any) => Promise<void>;
  children?: ReactNode;
  initialData?: any;
  entityId?: number;
  parentEntityId?: number;
  allStandortNamen?: IdAndDataType[];
  allBelegbareKurse?: BelegbareKurseResponse;
  onClickBelegungen?: Function;
  setSelectedMitgliedschaftsbeginn?: (_: Date) => void;
  selectedMitgliedschaftsbeginn?: Date;
  bereichValues?: string[];
  kurzBezeichnungValues?: string[];
}> = ({
  dialogKey,
  title,
  isOpen,
  closeDialog,
  entity,
  setEntity,
  inputData,
  addFnct,
  updateFnct,
  children,
  initialData,
  entityId,
  parentEntityId,
  allStandortNamen,
  allBelegbareKurse,
  onClickBelegungen,
  setSelectedMitgliedschaftsbeginn,
  selectedMitgliedschaftsbeginn,
  bereichValues,
  kurzBezeichnungValues,
}) => {
  const [formData, setFormData] = useState(inputData);
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1024);
  const [selectedStandort, setSelectedStandort] = useState<string>();
  const [selectedBelegung, setSelectedBelegung] = useState<number>(undefined);
  const [onSubmitCheck, setOnSubmitCheck] = useState<boolean>(false);
  const snackBarCtx = useContext(SnackBarContext);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClose = () => {
    if (JSON.stringify(initialData) !== JSON.stringify(entity) && updateFnct) {
      setIsUpdateDialogOpen(true);
    } else {
      closeDialog();
    }
  };

  const handleChange = (
    categoryKey: string,
    fieldKey: string,
    value: string,
    conversionAttr?: string,
    conversionFnct?: (_: string | number) => any
  ) => {
    setFormData((prev) => ({
      ...prev,
      [categoryKey]: {
        ...prev[categoryKey],
        [fieldKey]: {
          ...prev[categoryKey][fieldKey],
          data: value,
        },
      },
    }));
    if (conversionAttr && conversionFnct) {
      setEntity((prev: any) => ({
        ...prev,
        [conversionAttr]: conversionFnct(value),
      }));
    } else {
      setEntity((prev: any) => ({
        ...prev,
        [conversionAttr]: value,
      }));
    }
  };

  const validateAllFields = async () => {
    let allValid = true;
    const validations: any[] = [];

    Object.keys(formData).forEach((category) => {
      Object.keys(formData[category]).forEach((field) => {
        const fieldData = formData[category][field];
        if (fieldData.validatorFct) {
          const validationPromise = Promise.resolve(
            fieldData.validatorFct(fieldData.data, fieldData.isRequired)
          )
            .then((isValid) => {
              if (!isValid) allValid = false;
            })
            .catch(() => {
              snackBarCtx.openSnackbar(
                "IBAN kann nicht verifiziert werden",
                "error"
              );
              allValid = false;
            });
          validations.push(validationPromise);
        }
      });
    });

    await Promise.all(validations);

    return allValid;
  };

  return (
    <Dialog fullScreen open={isOpen} onClose={handleClose} keepMounted>
      <AdminPortalHeader headline="Verwaltung" />
      <Box
        sx={{
          width: "90%",
          height: "90vh",
          display: "flex",
          flexDirection: "column",
          marginX: "5%",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            backgroundColor: "#607783",
            borderRadius:
              "var(--borderRadiusLarge) var(--borderRadiusLarge) 0 0",
            borderBottom: "var(--border)",
            paddingY: " 15px",
            justifyContent: "center",
            width: "100%",
            position: "sticky",
            top: "0",
            zIndex: "50",
          }}
        >
          <Box
            sx={{
              flex: "1",
              display: "flex",
              marginLeft: "20px",
              justifyContent: "flex-start",
            }}
          >
            <AdminManagementButton
              text="Zurück"
              color="var(--orange)"
              onClick={handleClose}
            >
              <img
                src={backhIcon}
                alt="back"
                style={{ height: "15px", filter: "brightness(0) invert(1)" }}
              />
            </AdminManagementButton>
          </Box>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "var(--h1)",
              color: "var(--white)",
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            {entity && entity.ID !== undefined
              ? `ID: ${entity.ID} - ${title}`
              : ""}
          </Typography>

          <Box
            sx={{
              flex: "1",
              display: "flex",
              gap: "10px",
              marginX: "20px",
              justifyContent: "flex-end",
            }}
          ></Box>
        </Box>

        {Object.entries(formData).map(([categoryKey, categoryData]) => (
          <div key={categoryKey} style={{ width: "100%" }}>
            <Typography
              sx={{
                color: "#607783",
                fontSize: "12px",
                padding: "10px 0 10px 46px",
                fontWeight: "900",
              }}
            >
              {categoryKey}
            </Typography>
            <div
              style={{
                paddingLeft: "30px",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "16px",
              }}
            >
              {Object.entries(categoryData).map(
                ([
                  fieldKey,
                  { data, type, validatorFct, attribute, isRequired },
                ]) => {
                  if (type !== AdminManagementDialogTypes.INVISIBLE)
                    return (
                      <div
                        key={fieldKey}
                        style={{
                          width: "100%",
                          flex: isSmallScreen
                            ? "1 1 calc(33% - 20px)"
                            : "1 1 calc(25% - 20px)",
                          maxWidth: isSmallScreen
                            ? "calc(33% - 20px)"
                            : "calc(25% - 20px)",
                          minWidth: "165px",
                        }}
                      >
                        <AdminManagmentDialogTypeContent
                          bereichValues={bereichValues}
                          kurzBezeichnungValues={kurzBezeichnungValues}
                          dialogType={type}
                          categoryKey={categoryKey}
                          key={fieldKey}
                          label={fieldKey}
                          text={data}
                          attribute={attribute}
                          isRequired={isRequired}
                          handleChange={handleChange}
                          initialData={initialData}
                          onSubmitCheck={onSubmitCheck}
                          parentEntityId={parentEntityId}
                          allStandortNamen={allStandortNamen}
                          setSelectedStandort={setSelectedStandort}
                          selectedStandort={selectedStandort}
                          validatorFct={validatorFct}
                          allBelegbareKurse={allBelegbareKurse}
                          selectedMitgliedschaftsbeginn={
                            selectedMitgliedschaftsbeginn
                          }
                          setSelectedMitgliedschaftsbeginn={
                            setSelectedMitgliedschaftsbeginn
                          }
                          changeBelegungKursIdSelection={selectedBelegung}
                          setChangeBelegungKursSelection={(id: number) => {
                            setSelectedBelegung(id);
                            formData["Belegungen"][
                              "Belegung hinzufügen*"
                            ].data = String(id);
                          }}
                        />
                      </div>
                    );
                  return null;
                }
              )}
            </div>
          </div>
        ))}

        <Box
          sx={{
            padding: "0 30px 30px 30px",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
          }}
        >
          {children}
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          position: "sticky",
          bottom: "0",
          background: "var(--white)",
          zIndex: "70",
          borderTop: "2px solid rgba(96, 119, 131, 0.5)",
        }}
      >
        <Box
          sx={{
            height: "75px",
            display: "flex",
            alignItems: "center",
            marginX: "5%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
            }}
          >
            {initialData && dialogKey === AdminManagementDialogKey.MITGLIED && (
              <AdminManagementButton
                variant="outlined"
                text="Belegung"
                color="var(--green)"
                onClick={async () => {
                  onClickBelegungen(true);
                }}
              >
                <img src={plusSquared} alt="plus" />
              </AdminManagementButton>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <AdminManagementButton
              variant="text"
              text="Abbrechen"
              color="var(--green)"
              onClick={handleClose}
            ></AdminManagementButton>
            {/* Save Button */}
            {updateFnct && (
              <AdminManagementButton
                text="Speichern"
                color="var(--green)"
                onClick={() => {
                  validateAllFields().then((response) => {
                    if (!response) {
                      setOnSubmitCheck(!onSubmitCheck);
                      snackBarCtx.openSnackbar(
                        "Einige Eingaben sind ungültig. Bitte korrigieren Sie sie, bevor Sie fortfahren.",
                        "error"
                      );
                      return;
                    }
                    if (addFnct && selectedBelegung) {
                      addFnct(selectedBelegung);
                      return;
                    }

                    if (addFnct) {
                      addFnct(entity);
                      return;
                    }
                    entity.ID = entityId;
                    updateFnct(entity);
                    closeDialog();
                  });
                }}
              >
                <img src={save} alt="edit" style={{ width: "20px" }} />
              </AdminManagementButton>
            )}
          </Box>
        </Box>
      </Box>

      {/* Update Dialog */}
      {isUpdateDialogOpen && (
        <AdminInfoDialog
          isOpen={isUpdateDialogOpen}
          onCloseDialog={() => {
            setIsUpdateDialogOpen(false);
          }}
          iconURL={editPan}
          textChildren={
            <>
              Möchtest du die Seite verlassen, ohne die Änderungen zu speichern?
            </>
          }
          rightBtnLabel="Verlassen"
          rightBtnOnClick={async () => {
            setIsUpdateDialogOpen(false);
            closeDialog();
          }}
          leftBtnLabel="Abbrechen"
          leftBtnOnClick={() => {
            setIsUpdateDialogOpen(false);
          }}
        ></AdminInfoDialog>
      )}
    </Dialog>
  );
};
