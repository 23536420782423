import { BrowserRouter, Route, Switch } from "react-router-dom";

import { allRoutes } from "./enums/all-routes";

import AdminTrainerAppPage from "./pages/Admin/AdminTrainerApp.page";
import AdminPrivateRoute from "./pages/Routes/AdminPrivateRoute";
import AdminDashboardPage from "./pages/Admin/AdminDashboard.page";
import AdminManagementPage from "./pages/Admin/AdminManagement.page";
import AuthPage from "./pages/AuthPage";

function App() {
  return (
    <>
      <main>
        <BrowserRouter>
          <Switch>
            <Route component={AuthPage} path={allRoutes.auth}></Route>
            <AdminPrivateRoute
              exact={true}
              component={AdminDashboardPage}
              path={allRoutes.default}
            ></AdminPrivateRoute>
            <AdminPrivateRoute
              component={AdminDashboardPage}
              path={allRoutes.adminDashboard}
            ></AdminPrivateRoute>
            <AdminPrivateRoute
              component={AdminTrainerAppPage}
              path={allRoutes.adminTrainerApp}
            ></AdminPrivateRoute>
            <AdminPrivateRoute
              component={AdminManagementPage}
              path={allRoutes.adminManagement}
            ></AdminPrivateRoute>
          </Switch>
        </BrowserRouter>
      </main>
    </>
  );
}

export default App;
