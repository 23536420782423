import { Trainer } from "../models/trainer";
import { getTrainerAxiosInstance } from "./api-config";

export async function getTrainerEntity(trainerUUID: string): Promise<{
  status: number;
  trainer: Trainer;
}> {
  if (trainerUUID === "" || !trainerUUID) {
    return;
  }
  const axiosInstance = getTrainerAxiosInstance();
  const response = await axiosInstance.get(`/trainers/${trainerUUID}`);
  const trainerData = response.data;

  return {
    status: response.status,
    trainer: new Trainer(
      trainerData.uuid,
      trainerData.firstName,
      trainerData.lastName,
      trainerData.email,
      trainerData.phone,
      trainerData.isAdmin,
      trainerData.isActivated
    ),
  };
}
