import React, { ReactNode } from "react";
import { Box, Dialog, ThemeProvider, Typography } from "@mui/material";
import { customTheme } from "../customTheme";
import xGrey from "../../img/xGrey.png";

export const DashboardDialog: React.FC<{
  title?: string;
  isOpen: boolean;
  onCloseDialog: () => void;
  children: ReactNode;
}> = ({ isOpen, onCloseDialog, children, title }) => {
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            width: "750px",
            borderRadius: "8px",
            border: "1px solid rgba(96, 119, 131, 0.50)",
            boxShadow: "0px 20px 20px 0px rgba(0, 0, 0, 0.25)",
          },
        }}
        open={isOpen}
        onClose={onCloseDialog}
        keepMounted
      >
        <ThemeProvider theme={customTheme}></ThemeProvider>
        <Box sx={{ padding: "30px" }}>
          <Box
            onClick={() => {
              onCloseDialog();
            }}
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
            }}
          >
            <img
              alt="close"
              src={xGrey}
              style={{ width: "15px", height: "15px" }}
            />
          </Box>
          <Typography
            variant="body2"
            sx={{
              color: "#607783",
              textTransform: "uppercase",
              fontWeight: "bold",
              textAlign: "left",
              fontSize: "0.9rem",
              marginBottom: "10px",
              width: "100%",
            }}
          >
            {title}
          </Typography>
          {children}
        </Box>
      </Dialog>
    </>
  );
};
