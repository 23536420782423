import { AdminManagementTabTypes } from "../constants/admin-management-constants";
import { localStorageKeys } from "../constants/storage-keys";

export const setLocalStorageCourseListActive = (isActive: boolean) => {
  localStorage.setItem(
    localStorageKeys.adminPortalIsCourseListActive,
    isActive.toString()
  );
};

export const setLocalStorageManagementActiveTab = (
  newTab: AdminManagementTabTypes
) => {
  localStorage.setItem(
    localStorageKeys.adminManagementActiveTab,
    newTab.toString()
  );
};
