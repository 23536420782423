import { Box, ThemeProvider, Typography } from "@mui/material";
import "../../../css/globalVariables.css";
import { customTheme } from "../../customTheme";
import { Participant } from "../../../models/participant";
import { Course } from "../../../models/course";
import AttendeeDetailDialog from "../Dialogs/AttendeeDetailDialog";
import { useState } from "react";

const ArchiveAttendeeItem: React.FC<{
  course: Course;
  participant: Participant;
}> = ({ course, participant }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  return (
    <>
      <ThemeProvider theme={customTheme}>
        <Box
          onClick={() => {
            setIsDialogOpen(true);
          }}
          sx={{
            border: participant.isPresent
              ? "1px solid var(--green)"
              : "1px solid var(--orange)",
            borderRadius: "var(--borderRadiusLarge)",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "var(--h1)",
              margin: "10px",
              marginBottom: "5px",
              color: "var(--grey)",
            }}
          >
            {participant.firstName}
          </Typography>
          <Typography
            sx={{
              fontSize: "var(--h3)",
              marginBottom: "10px",
              marginLeft: "10px",
              color: "var(--grey)",
            }}
          >
            {participant.lastName}
          </Typography>
        </Box>
      </ThemeProvider>
      <AttendeeDetailDialog
        courseObj={course}
        participantObj={participant}
        isOpen={isDialogOpen}
        closeDialog={() => {
          setIsDialogOpen(false);
        }}
        backBtnText={course.courseName}
      ></AttendeeDetailDialog>
    </>
  );
};

export default ArchiveAttendeeItem;
