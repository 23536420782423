import { ListItem, ListItemButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import "../../../css/globalVariables.css";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Course, CourseStates } from "../../../models/course";
import { getTimeFromDates } from "../../../util/DateFormatHelper";
import { Participant } from "../../../models/participant";
import AdminPortalArchiveItemDialog from "../Dialogs/AdminPortalArchiveItemDialog.page";
import { useContext, useState } from "react";
import { Trainer } from "../../../models/trainer";
import { SnackBarContext } from "../../../context/snackbar-context";
import AdminInfoDialog from "../Dialogs/AdminInfoDialog";
import { getDeleteDialogTextContent } from "../../../util/CourseComponentUtilFunctions";
import trashcanSVG from "../../../img/trashcanSVG.svg";
import { adminDeleteCourse } from "../../../util/CourseUtilFunctions";

const AdminArchiveListItem: React.FC<{
  course: Course;
  navigationCourses: Course[];
  loadMoreCourses: () => Promise<void>;
  handleDeleteCourse: (idOfCourse: string) => void;
}> = ({ course, navigationCourses, loadMoreCourses, handleDeleteCourse }) => {
  const [isAdminArchiveItemDialogOpen, setIsAdminArchiveItemDialogOpen] =
    useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const snackBarCtx = useContext(SnackBarContext);

  const [currentCourse, setCurrentCourse] = useState(course);

  const isCourseCanceled = course.state === CourseStates.CANCELED;
  const isCourseCheckOutComplete =
    course.state === CourseStates.CHECK_OUT_COMPLETE;
  const isCourseUndocu = course.state === CourseStates.UNDOCUMENTED;

  const navigate = async (left: boolean): Promise<void> => {
    const reloadThreshold = 5;
    const currentIndex = navigationCourses.findIndex(
      (data: Course) => currentCourse.courseID === data.courseID
    );
    if (currentIndex !== -1) {
      setCurrentCourse(
        left
          ? navigationCourses[currentIndex - 1]
          : navigationCourses[currentIndex + 1]
      );
    }
    if (
      currentIndex >= navigationCourses.length - reloadThreshold ||
      navigationCourses.length < reloadThreshold
    ) {
      await loadMoreCourses();
    }
  };

  const isFirst = (): boolean => {
    if (currentCourse) {
      const index = navigationCourses.findIndex(
        (data: Course) => currentCourse.courseID === data.courseID
      );
      return index === 0;
    }
    return false;
  };

  const isLast = (): boolean => {
    if (currentCourse) {
      const index = navigationCourses.findIndex(
        (data: Course) => currentCourse.courseID === data.courseID
      );
      return index === navigationCourses.length - 1;
    }
    return false;
  };

  const getNotificationIcon = () => {
    return (
      <>
        <Box flex={2} display={"flex"} justifyContent={"center"}>
          <Box
            sx={{
              backgroundColor: "var(--orange)",
              height: "30px",
              width: "30px",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <MailOutlineIcon
              sx={{ color: "white", margin: "auto" }}
            ></MailOutlineIcon>
          </Box>
        </Box>
      </>
    );
  };

  const getConditionIcon = (value: boolean) => {
    return (
      <>
        <Box
          sx={{
            backgroundColor: value ? "var(--green)" : "var(--orange)",
            height: "30px",
            width: "30px",
            borderRadius: "4px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {value ? (
            <CheckRoundedIcon sx={{ color: "white", margin: "auto" }} />
          ) : (
            <PriorityHighRoundedIcon sx={{ color: "white", margin: "auto" }} />
          )}
        </Box>
      </>
    );
  };

  const getTrainerString = () => {
    const createTrainerString = (trainerList: Trainer[]) => {
      return trainerList.map((t) => `${t.firstName} ${t.lastName}`).join(" | ");
    };

    if (!course.trainers && course.trainers.length === 0) {
      return "Keine Trainer gefunden";
    }

    if (
      course.documentingTrainerUUID === "" ||
      course.state === CourseStates.UNDOCUMENTED
    ) {
      return createTrainerString(course.trainers);
    }

    const docuTrainer = course.trainers.find(
      (trainer) => trainer.uuid === course.documentingTrainerUUID
    );
    if (docuTrainer) {
      const docuTrainerString = `${docuTrainer.firstName} ${docuTrainer.lastName}`;
      if (course.trainers.length === 1) {
        return docuTrainerString;
      }
      return `${docuTrainerString} + ${course.trainers.length - 1}`;
    }
  };

  const typographyStyle = {
    fontSize: "var(--h4)",
    color: "var(--grey)",
    overflow: "hidden",
    textAlign: "center",
  };

  const getParticipantsString = () => {
    if (course.participants) {
      const presentParticipants: Participant[] = course.participants.filter(
        (p) => p.isPresent === true
      );

      return `${presentParticipants.length}/${course.participants.length}`;
    }
  };

  return (
    <>
      <ListItem
        onClick={() => {
          if (!isCourseUndocu || (isCourseUndocu && course.message)) {
            setIsAdminArchiveItemDialogOpen(true);
          }
        }}
        sx={{
          padding: "0",
          margin: "0",
        }}
      >
        <ListItemButton
          sx={{
            padding: "0",
            margin: "5px 0 5px 0",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
              height: "50px",
              border:
                isCourseCanceled || isCourseUndocu
                  ? "1px solid var(--red)"
                  : "1px solid var(--orange)",
            }}
          >
            <Typography
              flex={3}
              noWrap
              sx={{
                fontFamily: " Tahoma",
                fontSize: " 16px",
                fontStyle: " normal",
                fontWeight: " 700",
                lineHeight: " 18px",
                letterSpacing: " -0.01px",
                padding: "10px",
                width: "calc(100%/9)",
                textAlign: "center",
                overflow: "hidden",
                color: isCourseCheckOutComplete ? "#E1BB41" : "var(--red)",
              }}
            >
              {course.courseName}
            </Typography>
            <Typography noWrap sx={{ ...typographyStyle, flex: 3 }}>
              {course.location}
            </Typography>
            <Typography noWrap sx={{ ...typographyStyle, flex: 3 }}>
              {getTrainerString()}
            </Typography>
            <Typography noWrap sx={{ ...typographyStyle, flex: 3 }}>
              {getTimeFromDates(course.startDate, course.endDate)}
            </Typography>
            <Typography noWrap sx={{ ...typographyStyle, flex: 3 }}>
              {getParticipantsString()}
            </Typography>
            <Typography noWrap sx={{ ...typographyStyle, flex: 2 }}>
              {course.capacity}
            </Typography>
            <Box flex={2}>{course.message && getNotificationIcon()}</Box>
            <Box flex={2} display={"flex"} justifyContent={"center"}>
              {isCourseCheckOutComplete &&
                getConditionIcon(course.hallConditionValue)}
            </Box>
            <Box flex={2} display={"flex"} justifyContent={"center"}>
              {isCourseCheckOutComplete &&
                getConditionIcon(course.materialConditionValue)}
            </Box>
            <Box flex={2} display={"flex"} justifyContent={"center"}>
              {isCourseCheckOutComplete &&
                getConditionIcon(
                  course && course.incidents && course.incidents.length === 0
                )}
            </Box>
            <Box
              onClick={(e) => {
                e.stopPropagation();
                setIsDeleteDialogOpen(true);
              }}
              flex={1}
              display={"flex"}
              justifyContent={"center"}
            >
              <img src={trashcanSVG} alt="trashCanIcon"></img>
            </Box>
          </Box>
        </ListItemButton>
      </ListItem>
      {isAdminArchiveItemDialogOpen && (
        <AdminPortalArchiveItemDialog
          course={currentCourse}
          isFirstCourse={isFirst()}
          isLastCourse={isLast()}
          navigate={navigate}
          isOpen={isAdminArchiveItemDialogOpen}
          closeDialog={() => {
            setIsAdminArchiveItemDialogOpen(false);
          }}
        ></AdminPortalArchiveItemDialog>
      )}
      {isDeleteDialogOpen && (
        <AdminInfoDialog
          isOpen={isDeleteDialogOpen}
          onCloseDialog={() => {
            setIsDeleteDialogOpen(false);
          }}
          iconURL={trashcanSVG}
          textChildren={getDeleteDialogTextContent(course)}
          rightBtnLabel="Bestätigen"
          rightBtnOnClick={async () => {
            await adminDeleteCourse(
              course.id,
              setIsDeleteDialogOpen,
              handleDeleteCourse,
              snackBarCtx
            );
          }}
          leftBtnLabel="Abbrechen"
          leftBtnOnClick={() => {
            setIsDeleteDialogOpen(false);
          }}
        ></AdminInfoDialog>
      )}
    </>
  );
};
export default AdminArchiveListItem;
