import { AUTH_APP_URL, TRAINER_APP_URL } from "../config/config";
import { localStorageKeys } from "../constants/storage-keys";

export const redirectToAuth = () => {
  window.location.href = AUTH_APP_URL;
};

export const redirectToTrainerApp = () => {
  const adminToken = localStorage.getItem(localStorageKeys.adminAccessToken);
  const trainerToken = localStorage.getItem(
    localStorageKeys.trainerAccessToken
  );
  const isAdmin = localStorage.getItem(localStorageKeys.isAdmin);
  const trainerUUID = localStorage.getItem(localStorageKeys.trainerUUID);

  const params = new URLSearchParams({
    adminAccessToken: adminToken,
    trainerAccessToken: trainerToken,
    trainerUUID: trainerUUID,
    isAdmin: isAdmin,
  });
  window.location.href = `${TRAINER_APP_URL}/auth?${params.toString()}`;
};
