import { Box, Grid, ThemeProvider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { customTheme } from "../../components/customTheme";
import "../../css/globalVariables.css";
import upArrow from "../../img/upArrow.svg";
import AdminPortalHeader from "../../components/AdminHeader/AdminPortalHeader";
import { DashboardCard } from "../../components/AdminDashboard/DashboardCard";
import DashboardCircleChart from "../../components/AdminDashboard/DashboardCircleChart";
import { DashboardChart } from "../../components/AdminDashboard/DashboardChart";
import DashboardBarChart from "../../components/AdminDashboard/DashboardBarChart";
import {
  getAbrechenbareMitgliederForCurrentMonth,
  getAktiveAnmeldungenForCurrentMonth,
  getBottomTenAnwesenheit,
  getBottomTenAuslastung,
  getKuendigungenForTwoMonths,
  getAnwesenheitEffektiv,
  getStandortAuslastung,
  getAuslastungEffektiv,
  getHistorischeWerte,
} from "../../api/admin-dashboard-api";
import {
  AbrechenbareMitglieder,
  AktiveAnmeldungen,
  Anwesenheit,
  AnwesenheitEffektiv,
  Auslastung,
  AuslastungEffektiv,
  Kuendigung,
  NeueintritteKuendigungenHistorie,
  StandortAuslastung,
} from "../../models/admin-dashboard-entities";
import { ChartsLegend } from "../../components/AdminDashboard/ChartsLegend";
import { DashboardDialog } from "../../components/AdminDashboard/DashboardDialog";

function roundUpToNearest500(value: number): number {
  return Math.ceil(value / 500) * 500;
}

function roundUpToNearest50(value: number): number {
  return Math.ceil(value / 50) * 50;
}

const monthTitleStyle = {
  color: "#E1BB41",
  fontSize: "16px",
  textAlign: "left",
  fontWeight: "bold",
};

const bigNumberStyle = {
  color: "#607783",
  fontSize: "64px",
  textAlign: "left",
};

const AdminDashboardPage: React.FC = () => {
  const [aktiveAnmeldungen, setAktiveAnmeldungen] =
    useState<AktiveAnmeldungen>(undefined);
  const [abrechenbareMitglieder, setAbrechenbareMitglieder] =
    useState<AbrechenbareMitglieder>(undefined);
  const [bottomTenAuslastung, setBottomTenAuslastung] =
    useState<Record<string, Auslastung>>(undefined);
  const [allAuslastung, setAllAuslastung] =
    useState<Record<string, Auslastung>>(undefined);
  const [bottomTenAnwesenheit, setBottomTenAnwesenheit] =
    useState<Record<string, Anwesenheit>>(undefined);
  const [allAnwesenheit, setAllAnwesenheit] =
    useState<Record<string, Anwesenheit>>(undefined);

  const [kuendigungen, setKuendigungen] = useState<Kuendigung>(undefined);
  const [anwesenheitEffektiv, setAnwesenheitEffektiv] =
    useState<AnwesenheitEffektiv>(undefined);
  const [auslastungEffektiv, setAuslastungEffektiv] =
    useState<AuslastungEffektiv>(undefined);
  const [anwesenheitHistorie, setAnwesenheitHistorie] = useState<number[]>([]);
  const [auslastungHistorie, setAuslastungHistorie] = useState<number[]>([]);
  const [standortAuslastung, setStandortAuslastung] =
    useState<StandortAuslastung>(undefined);
  const [mitgliederHistorie, setMitgliederHistorie] = useState<number[]>([]);
  const [
    neueintritteKuendigungenHistorie,
    setNeueintritteKuendigungenHistorie,
  ] = useState<NeueintritteKuendigungenHistorie>(undefined);
  const [isAuslastungDiologOpen, setIsAuslastungDiologOpen] = useState(false);
  const [isAnwesenheitDiologOpen, setIsAnwesenheitDiologOpen] = useState(false);

  const [loadingStatus, setLoadingStatus] = useState({
    aktiveAnmeldungen: true,
    abrechenbareMitglieder: true,
    auslastung: true,
    anwesenheit: true,
    kuendigungen: true,
    anwesenheitEffektiv: true,
    auslastungEffektiv: true,
    anwesenheitHistorie: true,
    auslastungHistorie: true,
    standortAuslastung: true,
    mitgliederHistorie: true,
    neueintritteKuendigungenHistorie: true,
  });

  useEffect(() => {
    getAktiveAnmeldungenForCurrentMonth().then((response) => {
      setAktiveAnmeldungen(response);
      setLoadingStatus((prevState) => ({
        ...prevState,
        aktiveAnmeldungen: false,
      }));
    });
    getAbrechenbareMitgliederForCurrentMonth().then((response) => {
      setAbrechenbareMitglieder(response);
      setLoadingStatus((prevState) => ({
        ...prevState,
        abrechenbareMitglieder: false,
      }));
    });
    getBottomTenAuslastung().then((response) => {
      setBottomTenAuslastung(response.bottomTen);
      setAllAuslastung(response.all);
      setLoadingStatus((prevState) => ({
        ...prevState,
        auslastung: false,
      }));
    });
    getBottomTenAnwesenheit().then((response) => {
      setBottomTenAnwesenheit(response.bottomTen);
      setAllAnwesenheit(response.all);
      setLoadingStatus((prevState) => ({
        ...prevState,
        anwesenheit: false,
      }));
    });
    getKuendigungenForTwoMonths().then((response) => {
      setKuendigungen(response);
      setLoadingStatus((prevState) => ({
        ...prevState,
        kuendigungen: false,
      }));
    });
    getAnwesenheitEffektiv().then((response) => {
      setAnwesenheitEffektiv(response);
      setLoadingStatus((prevState) => ({
        ...prevState,
        anwesenheitEffektiv: false,
      }));
    });
    getAuslastungEffektiv().then((response) => {
      setAuslastungEffektiv(response);
      setLoadingStatus((prevState) => ({
        ...prevState,
        auslastungEffektiv: false,
      }));
    });
    getHistorischeWerte().then((response) => {
      setAnwesenheitHistorie(response.anwesenheiten);
      setAuslastungHistorie(response.buchungen);
      setLoadingStatus((prevState) => ({
        ...prevState,
        anwesenheitHistorie: false,
      }));
      setMitgliederHistorie(response.abrechenbareMitglieder);
      setLoadingStatus((prevState) => ({
        ...prevState,
        mitgliederHistorie: false,
      }));
      setNeueintritteKuendigungenHistorie({
        kuendigungen: response.kundigungen,
        neueMitglieder: response.neueintritte,
      });
      setLoadingStatus((prevState) => ({
        ...prevState,
        neueintritteKuendigungenHistorie: false,
      }));
    });
    getStandortAuslastung().then((response) => {
      setStandortAuslastung(response);
      setLoadingStatus((prevState) => ({
        ...prevState,
        standortAuslastung: false,
      }));
    });
  }, []);

  return (
    <ThemeProvider theme={customTheme}>
      <AdminPortalHeader />
      <Box
        sx={{
          width: "70%",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "30px",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            width: "100%",
            height: "100%",
            display: "grid",
            gridTemplateRows: "repeat(6, 1fr)",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 2,
          }}
        >
          {/*-------Aktive Anmeldungen------- */}
          <DashboardCard
            title="Aktive Anmeldungen"
            gridRow="1"
            gridColumn="1"
            isLoading={loadingStatus.aktiveAnmeldungen}
            infoDialog="Aktive Anmeldungen = Probemitglieder + Mitglieder"
          >
            {aktiveAnmeldungen && (
              <DashboardCircleChart
                total={aktiveAnmeldungen.totalMitglieder}
                coloredValue={aktiveAnmeldungen.mitglieder}
                grayValue={aktiveAnmeldungen.probemitglieder}
                coloredValueTitle="Anmeldungen"
                grayValueTitle="Probemitglieder"
              />
            )}
          </DashboardCard>

          {/*-------Probemitglieder------- */}
          <DashboardCard
            title="Probemitglieder"
            gridRow="1"
            gridColumn="2"
            alignItems="left"
            isLoading={loadingStatus.kuendigungen}
            infoDialog={
              "Probemitglieder = Belegungen mit Mitgliedsbeginn laufender Monat, Conversion Rate = (Probemitglieder - Widerrufe) / Probemitglieder"
            }
          >
            {kuendigungen && aktiveAnmeldungen && (
              <>
                <Typography variant="body1" sx={monthTitleStyle}>
                  {kuendigungen.currentMonthName}
                </Typography>
                <Typography variant="h4" sx={bigNumberStyle}>
                  {aktiveAnmeldungen.probemitglieder}
                </Typography>
                <Box
                  sx={{
                    paddingTop: "22px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={upArrow}
                    alt="info"
                    style={{
                      width: "12px",
                      height: "12px",
                    }}
                  />
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#607783",
                      fontSize: "11px",
                      textAlign: "left",
                      paddingLeft: "6px",
                    }}
                  >
                    {`Conversion Rate ${kuendigungen.currentMonthName}: ${
                      aktiveAnmeldungen.conversionRate * 100
                    }%`}
                  </Typography>
                </Box>
              </>
            )}
          </DashboardCard>

          {/*-------Auslastung | Standorten------- */}
          <DashboardCard
            title="Auslastung | Standorten"
            gridRow="1 / span 3"
            gridColumn="3"
            isLoading={loadingStatus.standortAuslastung}
            infoDialog="Auslastung = Abrechenbare Mitglieder / Kapazität (jeweils bezogen auf einen Standort)"
          >
            {standortAuslastung && (
              <DashboardBarChart
                standortData={standortAuslastung}
                coloredValueTitle={"Abrechenbare Mitglieder"}
                grayValueTitle={"Kapazität"}
              />
            )}
          </DashboardCard>

          {/*-------Abrechenbar------- */}
          <DashboardCard
            title="Mitgliedschaften"
            gridRow="2"
            gridColumn="1"
            isLoading={loadingStatus.abrechenbareMitglieder}
            infoDialog="Anzahl der Belegungen (inkl. Zahler= true + nach erfolgter Probezeit)"
          >
            {abrechenbareMitglieder && (
              <DashboardCircleChart
                total={abrechenbareMitglieder.totalAbrechenbareMitglieder}
                coloredValue={abrechenbareMitglieder.gsAbrechenbareMitglieder}
                grayValue={abrechenbareMitglieder.nonGsAbrechenbareMitglieder}
                coloredValueTitle="Gesundheitssport"
                grayValueTitle="Andere Abteilungen"
              />
            )}
          </DashboardCard>

          {/*-------Kündigungen------- */}
          <DashboardCard
            title="Kündigungen"
            gridRow="2"
            gridColumn="2"
            alignItems="left"
            isLoading={loadingStatus.kuendigungen}
            infoDialog="Die Anzahl der Kündigungen im aktuellen Monat, die Anzahl der Kündigungen für den nächsten Monat und die Anzahl der Widerrufe im aktuellen Monat werden erfasst."
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginRight: "10px",
              }}
            >
              {kuendigungen && (
                <>
                  <Box>
                    <Typography variant="body1" sx={monthTitleStyle}>
                      {kuendigungen.currentMonthName}
                    </Typography>
                    <Typography variant="h4" sx={bigNumberStyle}>
                      {kuendigungen.currentMonthCount}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#607783",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        textAlign: "left",
                        fontSize: "12px",
                        paddingTop: "22px",
                      }}
                    >
                      {`Widerrufe: ${kuendigungen.widerrufCount}`}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#E1BB41",
                        fontSize: "16px",
                        textAlign: "left",
                        fontWeight: "bold",
                      }}
                    >
                      {kuendigungen.nextMonthName}
                    </Typography>
                    <Typography variant="h4" sx={bigNumberStyle}>
                      {kuendigungen.nextMonthCount}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          </DashboardCard>

          {/*-------Bottom-10 | Auslastung------- */}
          <DashboardCard
            title="Bottom-10 | Auslastung"
            gridRow="3 / span 2"
            gridColumn="1"
            isLoading={loadingStatus.auslastung}
            onClickOnCard={() =>
              allAuslastung && setIsAuslastungDiologOpen(true)
            }
            infoDialog="Auslastung in %= Anmeldungen (Durschnitt der letzten 8 Wochen) / Kapazität"
          >
            {bottomTenAuslastung && (
              <DashboardBarChart
                auslastungData={bottomTenAuslastung}
                coloredValueTitle={"Anmeldungen"}
                grayValueTitle={"Kapazität"}
              />
            )}
          </DashboardCard>
          {isAuslastungDiologOpen && (
            <DashboardDialog
              title={"Auslastung"}
              isOpen={true}
              onCloseDialog={() => setIsAuslastungDiologOpen(false)}
            >
              {allAuslastung && (
                <DashboardBarChart
                  auslastungData={allAuslastung}
                  coloredValueTitle={"Anmeldungen"}
                  grayValueTitle={"Kapazität"}
                />
              )}
            </DashboardDialog>
          )}

          {/*-------Bottom-10 | Anwesenheit------- */}
          <DashboardCard
            title="Bottom-10 | Anwesenheit"
            gridRow="3 / span 2"
            gridColumn="2"
            isLoading={loadingStatus.anwesenheit}
            onClickOnCard={() =>
              allAnwesenheit && setIsAnwesenheitDiologOpen(true)
            }
            infoDialog="Anwesenheit in % = Teilnehmende (Durschnitt der letzten 8 Wochen) / Anmeldungen (Durschnitt der letzten 8 Wochen)"
          >
            {bottomTenAnwesenheit && (
              <DashboardBarChart
                anwesenheitData={bottomTenAnwesenheit}
                coloredValueTitle={"Teilnehmende"}
                grayValueTitle={"Anmeldungen"}
              />
            )}
          </DashboardCard>
          {isAnwesenheitDiologOpen && (
            <DashboardDialog
              title={"Anwesenheit"}
              isOpen={true}
              onCloseDialog={() => setIsAnwesenheitDiologOpen(false)}
            >
              {allAnwesenheit && (
                <DashboardBarChart
                  anwesenheitData={allAnwesenheit}
                  coloredValueTitle={"Teilnehmende"}
                  grayValueTitle={"Anmeldungen"}
                />
              )}
            </DashboardDialog>
          )}

          {/*-------Overall Anwesenheit------- */}
          <DashboardCard
            title="Anwesenheits- und Buchungsauslastung"
            gridRow="4"
            gridColumn="3"
            isLoading={loadingStatus.anwesenheitEffektiv}
            infoDialog="Anwesenheit in % = durchschnittliche Teilnehmeranzahl aller Kurse / Kapazitäten aller Kurse im entsprechenden Monat und Buchungsauslastung = aktive Anmeldungen / Kapazität."
          >
            {anwesenheitEffektiv && auslastungEffektiv && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "3px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    width: "100%",
                  }}
                >
                  <Box>
                    <DashboardCircleChart
                      total={`${anwesenheitEffektiv.auslastung}%`}
                      coloredValue={anwesenheitEffektiv.teilnehmende}
                      grayValue={anwesenheitEffektiv.kapa}
                      isPercentage={true}
                    />
                  </Box>
                  <Box>
                    <DashboardCircleChart
                      total={`${auslastungEffektiv.auslastung}%`}
                      coloredValue={auslastungEffektiv.aktiveAnmeldungen}
                      grayValue={auslastungEffektiv.kapa}
                      coloredValueBgColor={"rgb(225, 187, 65)"}
                      isPercentage={true}
                    />
                  </Box>
                </Box>
                <ChartsLegend
                  coloredValueTitle={`${anwesenheitEffektiv.teilnehmende} Teilnehmende`}
                  additionalValueTitle={`${auslastungEffektiv.aktiveAnmeldungen} Anmeldungen`}
                  additionalValueBgColor={"rgb(225, 187, 65)"}
                  grayValueTitle={`${anwesenheitEffektiv.kapa} Kapazität`}
                />
              </Box>
            )}
          </DashboardCard>

          {/*-------Anwesenheit Historie------- */}
          <DashboardCard
            title={"Anwesenheit und Auslastung gesamt"}
            gridRow="5"
            gridColumn="1 / span 3"
            isLoading={
              loadingStatus.anwesenheitHistorie &&
              loadingStatus.auslastungHistorie
            }
            infoDialog="Die historischen Monatswerte der letzten 12 Monate für die Anwesenheit werden angezeigt. Der Wert ergibt sich aus der durchschnittlichen Kapazität des Kurses geteilt durch die durchschnittliche Teilnehmerzahl."
          >
            <DashboardChart
              isPercentage={true}
              max={100}
              datasets={[
                {
                  label: "Anwesenheit",
                  data: anwesenheitHistorie,
                  borderColor: "rgba(68, 174, 176, 1)",
                  backgroundColor: "rgba(68, 174, 176, 0.5)",
                },
                {
                  label: "Auslastung",
                  data: auslastungHistorie,
                  borderColor: "rgb(225, 187, 65)",
                  backgroundColor: "rgba(225, 187, 65, 0.5)",
                },
              ]}
            />
          </DashboardCard>

          {/*-------Anmeldungen Historie------- */}
          <DashboardCard
            title={"abrechenbar Gesamt"}
            gridRow="6"
            gridColumn="1 / span 3"
            isLoading={loadingStatus.mitgliederHistorie}
            infoDialog="Die historischen Monatswerte der letzten 12 Monate für die Anmeldungen werden angezeigt. Anmeldungen stehen hierbei für die Menge an Anmeldungen von abrechenbaren Mtigliedern."
          >
            <DashboardChart
              max={roundUpToNearest500(Math.max(...mitgliederHistorie))}
              min={1000}
              datasets={[
                {
                  label: "Anmeldungen",
                  data: mitgliederHistorie,
                  borderColor: "rgb(193, 201, 205)",
                  backgroundColor: "rgba(193, 201, 205, 0.5)",
                },
              ]}
            />
          </DashboardCard>

          {/*-------Neueintritt | Kündigungen Historie------- */}
          <DashboardCard
            title={"Neueintritt | Kündigung gesamt"}
            gridRow="7"
            gridColumn="1 / span 3"
            isLoading={loadingStatus.neueintritteKuendigungenHistorie}
            infoDialog="Historische Monatswerte der letzten 12 Monate für Neueintritte und Kündigungen werden angezeigt."
          >
            {neueintritteKuendigungenHistorie && (
              <DashboardChart
                max={
                  neueintritteKuendigungenHistorie
                    ? roundUpToNearest50(
                        Math.max(
                          ...neueintritteKuendigungenHistorie.kuendigungen,
                          ...neueintritteKuendigungenHistorie.neueMitglieder
                        )
                      )
                    : 300
                }
                datasets={[
                  {
                    label: "Kündigungen",
                    data: neueintritteKuendigungenHistorie
                      ? neueintritteKuendigungenHistorie.kuendigungen
                      : [],
                    borderColor: "rgb(250, 105, 105)",
                    backgroundColor: "rgba(250, 105, 105, 0.5)",
                  },
                  {
                    label: "Neueintritte",
                    data: neueintritteKuendigungenHistorie
                      ? neueintritteKuendigungenHistorie.neueMitglieder
                      : [],
                    borderColor: "rgba(68, 174, 176, 1)",
                    backgroundColor: "rgba(68, 174, 176, 0.5)",
                  },
                ]}
              />
            )}
          </DashboardCard>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default AdminDashboardPage;
